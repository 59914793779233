<template>
  <!-- 风控系统--风控日志 -->
  <el-card
    shadow="hover"
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >

    <el-card>
      <div class="title" style="border-bottom:1px solid silver;padding-bottom:15px">风控日志</div>
      <!-- 查询部分 -->
      <!-- 搜索 -->
      <div class="Time">
        <el-form :model="searchForm">
          <el-row type="flex" :gutter="20">
              <el-col :span="8" style="width: 45%!important;">
                <el-form-item>
                  <el-select clearable placeholder="请选择周期(第1天-第30天)" v-model="searchForm.nday">
                    <el-option label="第1天" value="0"></el-option>
                    <el-option label="第2天" value="1"></el-option>
                    <el-option label="第3天" value="2"></el-option>
                    <el-option label="第4天" value="3"></el-option>
                    <el-option label="第5天" value="4"></el-option>
                    <el-option label="第6天" value="5"></el-option>
                    <el-option label="第7天" value="6"></el-option>
                    <el-option label="第8天" value="7"></el-option>
                    <el-option label="第9天" value="8"></el-option>
                    <el-option label="第10天" value="9"></el-option>
                    <el-option label="第11天" value="10"></el-option>
                    <el-option label="第12天" value="11"></el-option>
                    <el-option label="第13天" value="12"></el-option>
                    <el-option label="第14天" value="13"></el-option>
                    <el-option label="第15天" value="14"></el-option>
                    <el-option label="第16天" value="15"></el-option>
                    <el-option label="第17天" value="16"></el-option>
                    <el-option label="第18天" value="17"></el-option>
                    <el-option label="第19天" value="18"></el-option>
                    <el-option label="第20天" value="19"></el-option>
                    <el-option label="第21天" value="20"></el-option>
                    <el-option label="第22天" value="21"></el-option>
                    <el-option label="第23天" value="22"></el-option>
                    <el-option label="第24天" value="23"></el-option>
                    <el-option label="第25天" value="24"></el-option>
                    <el-option label="第26天" value="25"></el-option>
                    <el-option label="第27天" value="26"></el-option>
                    <el-option label="第28天" value="27"></el-option>
                    <el-option label="第29天" value="28"></el-option>
                    <el-option label="第30天" value="29"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>

            <el-col :span="8" style="width: 45%!important;">
              <el-form-item>
                <el-select clearable placeholder="请选择游戏"  filterable v-model="searchForm.areaid">
                  <el-option
                          :label="item.gamename +'('+ item.modename.substring(0,2)+')'"
                          v-for="(item,index) of gamelist"
                          :key="index"
                          :value="item.areaid"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-date-picker
                      v-model="searchForm.timearr"
                      type="daterange"
                      value-format="yyyy-MM-dd"
                      range-separator="-"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      :picker-options="pickerOptions"
              ></el-date-picker>
            </el-col>
            <el-col>
              <el-button type="primary" @click="onSubmit()">查询</el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- 表格 -->
      <el-row>
        <el-table :data="allData" border style="width: 100%" height="600px">
          <el-table-column label="周期内第几天" prop="nday" width="110"></el-table-column>
          <el-table-column label="游戏" prop="gamename" width="110">
            <template slot-scope="scope">
            <span>{{scope.row.gamename}}({{scope.row.modename.substring(0,2) }})</span>
            </template>
          </el-table-column>
          <el-table-column label="本次盈利" prop="profits" width="100"></el-table-column>
          <el-table-column label="本次流水" prop="bets" width="100"></el-table-column>
          <el-table-column label="本次税收" prop="tax" width="100"></el-table-column>
          <el-table-column label="本次有效局数" prop="round" width="110"></el-table-column>
          <el-table-column label="游戏周期内总盈利" prop="yx_period_profits" width="140"></el-table-column>
          <el-table-column label="游戏周期内总流水" prop="yx_period_bets" width="140"></el-table-column>
          <el-table-column label="游戏周期内总税收" prop="yx_period_tax" width="140"></el-table-column>
          <el-table-column label="游戏周期内有效局数" prop="yx_period_round" width="150"></el-table-column>
          <el-table-column label="平台周期内总盈利" prop="pt_period_profits" width="140"></el-table-column>
          <el-table-column label="平台周期内总流水" prop="pt_period_bets" width="140"></el-table-column>
          <el-table-column label="平台周期内总税收" prop="pt_period_tax" width="140"></el-table-column>
          <el-table-column label="平台周期内有效局数" prop="pt_period_round" width="150"></el-table-column>
<!--          <el-table-column label="平台周期内有效充值" prop="pt_period_recharge" width="150"></el-table-column>-->
<!--          <el-table-column label="平台周期内有效兑换" prop="pt_period_cashed" width="150"></el-table-column>-->
          <el-table-column label="记录时间" prop="created_time"></el-table-column>
        </el-table>
      </el-row>
      <!-- 分页器 -->
      <!-- 分页器 -->
      <div class="page">
        <!-- <span>每页10条,</span> -->
        <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryInfo.page"
                :page-size="queryInfo.pagenum"
                :page-sizes="[20, 50, 100, 200,500]"
                style="float:right"
                layout="sizes,total, prev, pager, next, jumper"
                :total="total"
        ></el-pagination>
        <el-button type="primary" @click="change(queryInfo.page)">确定</el-button>
      </div>
    </el-card>
  </el-card>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      tabledata: [],
      allData: [],
      gamelist:[],
      queryInfo: {
        page: 1,
        pagenum: 20
      },
      total: 0,
      // 时间查询
      pickerOptions: {
        disabledDate: time => {
          return this.dealDisabledDate(time);
        }
      },
      searchForm: {
        timearr: [],
        nday: "",
        areaid: ""
      }
    };
  },
  mounted() {
    this.gettab();
    this.getDay();
  },
  methods: {
    change(newPage) {
      this.loading = false;
      this.handleCurrentChange(newPage);
      this.loading = true;
    },
    // 监听pageName改变的事件
    handleSizeChange(pagenum) {
      this.loading = false;
      this.queryInfo.pagenum = pagenum;
      this.gettab();
      this.loading = true;
    },
    // 监听页码值改变的事件
    handleCurrentChange(newPage) {
      this.loading = false;
      this.queryInfo.page = newPage;
      this.gettab();
      this.loading = true;
    },

    // 表格列表
    gettab() {
      this.loading = true;
      let that = this;
      var params = this.searchForm;
      params.page = that.queryInfo.page;
      params.pagenum = that.queryInfo.pagenum;
      this.$Http({
        url: "api/GameConfig/riskJournal",
        params,
        successful(res, data) {
          if (data.status == false) {
            that.$message.error(data.message);
            that.loading = false;
          }
          if (data.status == true) {
            that.loading = false;
            that.allData = data.data.dataarr;
            that.gamelist = data.data.gamename;
            that.total = data.data.count;
          }
        }
      });
    },

    // 时间查询按钮
    onSubmit() {
      this.loading = true;
      this.gettab();
    },
    // 默认显示时间
    getDay() {
      var now = new Date();
      var year = now.getFullYear(); //得到当前年份
      var month = now.getMonth() + 1; //默认得到月份是上一个月，如果当前是3月，这个值为2月
      var d = now.getDate();
      var h = now.getHours();
      var m = now.getMinutes();
      var s = now.getSeconds();
      if (month == 12)
              //如果当前是1月，则获取到的数据为12月，年份减一
        year = year - 1;
      var nextMonth = month; //其实就是当前月份
      month = month.toString().padStart(2, "0"); //当小于10时，显示为01.02.03
      d = d.toString().padStart(2, "0"); //当小于10时，显示为01.02.03
      nextMonth = nextMonth.toString().padStart(2, "0");
      this.date = `${year}-${month}-${d}`; //拼接日期
      // this.enddate = `${year}-${nextMonth}-${d}`;
      this.queryInfo.Time = this.date;
      // this.queryInfo.Time[1] = this.enddate;
    },
    dealDisabledDate(time) {
      var times = Date.now();
      return time.getTime() > times;
    }
  },

};
</script>

<style>

  .el-select {
    display: inline-block;
    position: relative;
  }

  .el-row--flex {
    display: flex;
    margin-top: 20px;
  }
  .el-range-editor .el-range-input {
    line-height: 1;
    margin-left: 5px;
  }
  #ListBox .TitleBox, .page {
    display: flex;
    align-items: center;
  }
  .page {
    font-weight: 400;
    color: #606266;
    font-size: 13px;
    line-height: 28px;
    justify-content: flex-end;
  }
</style>