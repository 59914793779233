<template>
  <!-- 风控数据分析 -->
  <el-card
      shadow="hover"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      style="height: 100%;overflow-y: auto;"
  >

  <div id="anlysis" v-loading="loading" style="height: 100%;">
    <!-- 时间查询 -->
    <div class="block">
      <el-form :inline="true" :model="queryInfo">
        <el-date-picker
                v-model="queryInfo.Time"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期"
                :picker-options="pickerOptions"
        ></el-date-picker>
        <el-form-item>
          <el-button type="primary" style="margin-left:20px" @click="onSubmit">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 统计 -->
    <div class="statistics">
      <span>【</span>
      <span>平台输赢统计：{{statistics.profits}}</span>
      <span class="fontstyle">税收统计：{{statistics.tax}}</span>
      <span>流水统计：{{statistics.bets}}</span>
      <span>】</span>
    </div>

    <!-- 折线图部分 -->
    <el-card>
      <div id="chartLineBox" style="width:100%;height:400px;" ref="echartRef"></div>
    </el-card>

    <!-- 表格 -->
    <el-card>
      <el-table :data="tableData" stripe style="width: 100%" border>
        <el-table-column prop="game_name" label="场次">
          <template slot-scope="scope">
            <span
                    @click="jumpRoom(scope.row)"
                    class="game_name"
                    style="color:blueviolet; cursor: pointer;"
            >{{scope.row.game_name}}({{scope.row.modename.substring(0,2) }})</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="游戏名称"></el-table-column>
        <el-table-column prop="round" label="游戏局数" sortable></el-table-column>
        <el-table-column prop="bets" label="流水统计" sortable></el-table-column>
        <el-table-column prop="tax" label="税收统计" sortable></el-table-column>
        <el-table-column prop="profits" label="平台输赢统计" sortable></el-table-column>
        <el-table-column prop="profit_rate" label="盈利率"></el-table-column>
      </el-table>
    </el-card>
    <!-- 分页器 -->
    <div class="page">
      <!-- <span>每页10条,</span> -->
      <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="queryInfo.page"
              :page-size="queryInfo.pagenum"
              :page-sizes="[20, 50, 100, 200,500]"
              style="float:right"
              layout="sizes,total, prev, pager, next, jumper"
              :total="total"
      ></el-pagination>
      <el-button type="primary" @click="change(queryInfo.page)">确定</el-button>
    </div>
  </div>
  </el-card>
</template>

<script>
  import echarts from "echarts";
  export default {
    data() {
      return {
        loading: true,
        // 时间选择器
        // 时间选择器表单
        // 获取表格数据以及分页器
        queryInfo: {
          // 当前页码(第几页)
          page: 1,
          // 每页显示条数
          pagenum: 20,
          // gameid: "",
          Time: ""
        },
        total: 0,
        // 时间查询
        pickerOptions: {
          disabledDate: time => {
            return this.dealDisabledDate(time);
          }
        },
        // 表格
        tableData: [],
        // 输赢统计
        statistics: {},
        interval:11,
      };
    },
    // 获取默认昨天显示
    created() {
      this.getDay();
    },
    computed: {
      echartsOptions() {
        let that = this;
        let timeArr =[];
        var time;
        for (var i=0;i<24;i++){
          if(i < 10){
            time = '0'+i+":04";
          }else{
            time = i+":04";
          }
          timeArr.push(time);
        }
        timeArr.push("23:59");

        let echartsOptions = {
          title: {
            text: this.queryInfo.Time
          },
          tooltip: {
            trigger: "axis"
          },
          legend: {
            data: ["输赢统计最高线", "输赢统计最低线", "输赢统计平均线"]
          },
          color: ["#E6A23C", "#f56c6c", "#0e90e9"],
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: [],
            axisLabel: {
              interval:this.interval,
              rotate:30,
            }
          },
          yAxis: {
            type: "value"
          },

          series: [
            {
              name: "输赢统计最高线",
              symbol:(a,b)=>{
                if(this.isStrInArray(b.name,timeArr)){
                  return 'emptyCircle';
                }else{
                  return 'none';
                }
              },
              type: "line",
              data: []
            },
            {
              name: "输赢统计最低线",
              symbol:(a,b)=>{
                if(this.isStrInArray(b.name,timeArr)){
                  return 'emptyCircle';
                }else{
                  return 'none';
                }
              },
              type: "line",
              data: []
            },
            {
              name: "输赢统计平均线",
              symbol:(a,b)=>{
                if(this.isStrInArray(b.name,timeArr)){
                  return 'emptyCircle';
                }else{
                  return 'none';
                }
              },
              type: "line",
              data: []
            }
          ]
        };
        return echartsOptions;
      }
    },
    mounted() {
      // 获取折线图
      this.getline();
      this.getDay();
    },
    methods: {

      isStrInArray(str, arr) {
        let n = arr.length;
        for (let i = 0; i < n; i++) {
          if (arr[i] == str) {
            return true;
          }
        }
        return false;
      },
      // 折线图
      getLocalTime(nS) {
        var timestamp4 = new Date(nS * 1000);
        var str = timestamp4.toTimeString().substr(0, 8);
        return str;
      },
      getline() {
        var params = {
          page: this.queryInfo.page,
          pagenum: this.queryInfo.pagenum,
          time: this.queryInfo.Time
        };
        let that = this;
        this.$Http({
          url: "api/GameConfig/riskAnalysis",
          params,
          successful(res, data) {
            if (data.status == false) {
              that.loading = false;
              that.$message.error(data.message);
            } else {
              that.loading = false;
              that.statistics = data.data;
              that.tableData = data.data.gmarr;
              that.total = data.data.count;
              that.echartsOptions.xAxis.data = data.data.curve.time;
              that.echartsOptions.series[0].data = data.data.curve.data.max;
              that.echartsOptions.series[1].data = data.data.curve.data.min;
              that.echartsOptions.series[2].data = data.data.curve.data.average;
              that.chartLine = echarts.init(
                      document.getElementById("chartLineBox")
              );
              that.chartLine.setOption(that.echartsOptions, true);
            }
          }
        });
      },
      // 时间查询按钮
      onSubmit() {
        this.loading = true;
        this.getline();
      },
      // 默认显示今天时间
      getDay() {
        const start = new Date();
        start.setTime(start.getTime());
        this.queryInfo.Time = this.formatDate(start);
      },
      formatDate(date) {
        var myyear = date.getFullYear();
        var mymonth = date.getMonth() + 1;
        var myweekday = date.getDate();

        if (mymonth < 10) {
          mymonth = "0" + mymonth;
        }
        if (myweekday < 10) {
          myweekday = "0" + myweekday;
        }
        return myyear + "-" + mymonth + "-" + myweekday;
      },
      // 时间选择器
      dealDisabledDate(time) {
        var times = Date.now();
        return time.getTime() > times;
      },
      // 游戏名称跳转房间列表
      jumpRoom(row) {
        this.$router.push({
          name: "anRoomlist",
          params: {
            gameid: row.gameid,
            time: this.queryInfo.Time,
            game_name: row.game_name,
            name: row.name,
            areaid: row.areaid,
            modename:row.modename
          }
        });

      },
      // 分页器
      handleSizeChange(pagenum) {
        this.loading = false;
        this.queryInfo.pagenum = pagenum;
        this.getline();
        this.loading = true;
      },
      // 监听页码值改变的事件
      handleCurrentChange(newPage) {
        this.loading = false;
        this.queryInfo.page = newPage;
        this.getline();
        this.loading = true;
      }
    }
  };
</script>

<style lang="less" scoped>
  #anlysis {
    /deep/.el-input__inner {
      text-align: center !important;
    }
    .statistics {
      color: red;
      font-weight: 600;
      margin-bottom: 20px;
      .fontstyle {
        margin: 0 20px;
      }
    }
    .gamename {
      color: #409eff;
      cursor: pointer;
    }
    .page {
      margin-top: 20px;
    }
  }

  #ListBox .TitleBox, .page {
    display: flex;
    align-items: center;
  }
  .page {
    font-weight: 400;
    color: #606266;
    font-size: 13px;
    line-height: 28px;
    justify-content: flex-end;
  }

</style>