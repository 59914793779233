<template>
  <el-card
    shadow="hover"
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    style="height: 100%;overflow-y: auto;"
  >
  <!-- 游戏配置与调控---渠道服管理 -->
  <div id="ListBox">
    <div class="TitleBox">
      <div class="searchBox">
        <p class="title">游戏配置与调控--渠道服管理</p>
      </div>

      <div class="AddList">
        <el-button
                type="success"
                icon="el-icon-circle-plus-outline"
                round
                @click="addDialogVisible = true"
                :close-on-click-modal="false"
        >新增渠道服</el-button>
      </div>

    </div>

    <!-- 表格 -->
    <el-table
      :data="GameConfig"
      style="width: 100%;"
      ref="multipleTable"
      size="mini"
      height="720px"
    >
      <el-table-column prop="channelid" label="渠道ID" fixed></el-table-column>
      <el-table-column prop="agentid" label="代理商ID" fixed></el-table-column>
      <el-table-column label="是否开放" prop>
        <template slot-scope="scope">
          <span>{{scope.row.is_open===0?'关闭':'开启'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <span>{{scope.row.status===0?'关闭':'启动'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="addr" label="节点地址" width="100"></el-table-column>
      <el-table-column prop="listen_ip" label="对外监听地址"></el-table-column>
      <el-table-column prop="listen_port" label="监听端口"></el-table-column>
      <el-table-column prop="max_client" label="最大连接数"></el-table-column>

      <el-table-column label="web监听ip" prop="web_listen_ip" width="140"></el-table-column>
      <el-table-column label="web监听端口" prop="web_listen_port"></el-table-column>
      <el-table-column label="api地址" prop="gm_addr" width="140"></el-table-column>


      <!-- 表格按钮 -->
      <el-table-column fixed="right" label="操作" width="200" class="btns">
        <template slot-scope="scope">
          <el-button type="text" size="small" class="btn btn3"
                  round
                  @click="stopUserById(scope.row)"
          >{{scope.row.is_open === 1 ?'停用':'启用'}}</el-button>

          <el-button type="text" size="small" class="btn btn7" @click="showEditDialog(scope.row)">编辑</el-button>
          <el-button type="text" size="small" class="btn btn8" @click="removeUserById(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页器 -->
    <div class="page">
      <!-- <span>每页10条,</span> -->
      <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="queryInfo.page"
              :page-size="queryInfo.pagenum"
              :page-sizes="[20, 50, 100, 200,500]"
              style="float:right"
              layout="sizes,total, prev, pager, next, jumper"
              :total="total"
      ></el-pagination>
      <el-button type="primary" @click="change(queryInfo.page)">确定</el-button>
    </div>
    <!-- 新增游戏列表 -->
    <el-dialog
      :title="'新增渠道服'"
      style="text-align:left !important"
      :visible.sync="addDialogVisible"
      :close-on-click-modal="false"
    >
      <!-- 内容区域 -->
      <el-form ref="addFormRef" :model="addForm" :rules="addFormRules">
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="6">
            <el-form-item label="渠道ID" prop="channelid">
              <el-input v-model.number="addForm.channelid" maxlength="4"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="是否开放" prop="is_open">
              <el-select v-model="addForm.is_open" placeholder="是否开放">
                <el-option label="是" value="1"></el-option>
                <el-option label="否" value="0"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="状态" prop="status">
<!--              1启动服务器，0已经关闭，2正在维护',-->
              <el-select v-model="addForm.status" placeholder="状态">
                <el-option label="维护" value="2"></el-option>
                <el-option label="启动" value="1"></el-option>
                <el-option label="关闭" value="0"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="6">
            <el-form-item label="节点地址" prop="addr">
              <el-input v-model="addForm.addr" placeholder></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="对外监听地址" prop="listen_ip">
              <el-input v-model="addForm.listen_ip" placeholder></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="监听端口" prop="listen_port">
              <el-input v-model="addForm.listen_port" ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="6">
            <el-form-item label="最大连接数" prop="max_client">
              <el-input v-model.number="addForm.max_client"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="web监听ip" prop="web_listen_ip">
              <el-input v-model="addForm.web_listen_ip" placeholder></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="web监听端口" prop="web_listen_port">
              <el-input v-model="addForm.web_listen_port" placeholder></el-input>
            </el-form-item>
          </el-col>

<!--          <el-col :span="6">-->
<!--            <el-form-item label="登录地址" prop="login_addr">-->
<!--              <el-input v-model="addForm.login_addr" placeholder></el-input>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--          <el-col :span="6">-->
<!--            <el-form-item label="登录端口" prop="login_port">-->
<!--              <el-input v-model="addForm.login_port"></el-input>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
        </el-row>

        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="6">
            <el-form-item label="代理ID" prop="agentid" :model="addForm">
<!--              @change="indexSelect($event)"-->
              <el-select v-model="addForm.agentid" placeholder="代理ID">
                <el-option
                        :label="item.agent_name"
                        :value="item.agent_id"
                        v-for="(item,index) of agentidlist"
                        :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <!--            maxlength="4"-->
            <el-form-item label="api地址" prop="gm_addr">
              <el-input v-model="addForm.gm_addr"  placeholder></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
<!--            <el-form-item label="aeskey" prop="aeskey">-->
<!--           <el-input v-model="addForm.aeskey" disabled="disabled" maxlength="4" placeholder></el-input>&ndash;&gt;&ndash;&gt;-->
<!--            </el-form-item>-->
          </el-col>
<!--          <el-col :span="6">-->
<!--            <el-form-item label="md5key" prop="md5key">-->
<!--              <el-input v-model="addForm.md5key" disabled="disabled" placeholder></el-input>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--          <el-col :span="6">-->
<!--            <el-form-item label="api回调端口" prop="api_back_port">-->
<!--              <el-input v-model="addForm.api_back_port"></el-input>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
        </el-row>

      </el-form>
      <!-- 按钮区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="success" @click="addList()">提交</el-button>
        <el-button type="primary" @click="addDialogVisible = false">取消</el-button>
      </span>
    </el-dialog>

    <!-- 编辑部分 -->
    <el-dialog
      :title="'编辑渠道服'"
      style="text-align:left !important"
      :visible.sync="editDialogVisible"
      :close-on-click-modal="false"
    >
      <!-- 内容部分 -->
      <el-form ref="editFormRef" :model="editForm" :rules="editFormRules">
        <!-- 内容区域 -->
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="6">
            <el-form-item label="渠道ID" prop="channelid">
              <el-input v-model.number="editForm.channelid" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="是否开放" prop="is_open">
              <el-select v-model="editForm.is_open" placeholder="是否开放">
                <el-option label="是" value="1"></el-option>
                <el-option label="否" value="0"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="状态" prop="status">
              <el-select v-model="editForm.status" placeholder="是否开放">
                <el-option label="维护" value="2"></el-option>
                <el-option label="启动" value="1"></el-option>
                <el-option label="关闭" value="0"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="6">
            <el-form-item label="节点地址" prop="addr">
              <el-input v-model="editForm.addr" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="对外监听地址" prop="listen_ip">
              <el-input v-model="editForm.listen_ip" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="监听端口" prop="listen_port">
              <el-input v-model="editForm.listen_port" r></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="6">
            <el-form-item label="最大连接数" prop="max_client">
              <el-input v-model.number="editForm.max_client" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="web监听ip" prop="web_listen_ip">
              <el-input v-model="editForm.web_listen_ip" placeholder></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="web监听端口" prop="web_listen_port">
              <el-input v-model="editForm.web_listen_port" placeholder></el-input>
            </el-form-item>
          </el-col>
<!--          <el-col :span="6">-->
<!--            <el-form-item label="api回调地址" prop="api_back_addr">-->
<!--              <el-input v-model="editForm.api_back_addr" ></el-input>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--          <el-col :span="6">-->
<!--            <el-form-item label="api端口" prop="api_port">-->
<!--              <el-input v-model="editForm.api_port"></el-input>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--          <el-col :span="6">-->
<!--            <el-form-item label="登录地址" prop="login_addr">-->
<!--              <el-input v-model="editForm.login_addr" ></el-input>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--          <el-col :span="6">-->
<!--            <el-form-item label="登录端口" prop="login_port">-->
<!--              <el-input v-model.number="editForm.login_port"  ></el-input>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
        </el-row>

        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="6">
<!--            @change="indexSelect($event)"-->
            <el-form-item label="代理ID" prop="agentid" :model="editForm">
              <el-select v-model="editForm.agentid" placeholder="代理ID" >
                <el-option
                        :label="item.agent_name"
                        :value="item.agent_id"
                        v-for="(item,index) of agentidlist"
                        :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="api地址" prop="gm_addr">
              <el-input v-model="editForm.gm_addr" ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="6">
<!--            <el-form-item label="aeskey" prop="login_port">-->
<!--              <el-input v-model="editForm.aeskey" disabled="disabled"></el-input>-->
<!--            </el-form-item>-->
          </el-col>

<!--          <el-col :span="6">-->
<!--            <el-form-item label="md5key" prop="md5key">-->
<!--              <el-input v-model="editForm.md5key" disabled="disabled"></el-input>-->
<!--            </el-form-item>-->
<!--          </el-col>-->

<!--          <el-col :span="6">-->
<!--            <el-form-item label="api回调端口" prop="api_back_port">-->
<!--              <el-input v-model="editForm.api_back_port"></el-input>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
        </el-row>

      </el-form>
      <!-- 按钮区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="success" @click="editList">提交</el-button>
        <el-button type="primary" @click="editDialogVisible = false">取消</el-button>
      </span>
    </el-dialog>
  </div>
  </el-card>
</template>

<script>
import { Encrypt, Decrypt } from "../../assets/js/utils";
export default {
  data() {
    return {
      loading: true,
      GameConfig: [],
      // 分页器
      queryInfo: {
        pagenum: 20,
        page: 1,
      },
      total: 0,
      addDialogVisible: false,
      editDialogVisible: false,
      configDialogVisible: false,
      bootDialogVisible: false,
      addForm: {
        channelid: "",
        is_open: "",
        status: "",
        addr: "",
        listen_ip: "",
        listen_port: "",
        max_client: "",
        gm_addr: "",
        agentid: "",
        web_listen_ip:"",
        web_listen_port:"",
      },
      // 添加验证表单对象
      addFormRules: {
        channelid: [
          { required: true, message: "请输入ID", trigger: "blur" },
          { type: "number", message: "值必须为数字" }
        ],
        is_open: [{ required: true, message: "请选择状态", trigger: "blur" }],
        status: [{ required: true, message: "请选择状态", trigger: "blur" }],
        addr: [
          { required: true, message: "请输入节点地址", trigger: "blur" }
        ],
        listen_ip: [{ required: true, message: "请输入对外监听ip", trigger: "blur" }],
        listen_port: [{ required: true, message: "请输入对外监听端口", trigger: "blur" }],
        max_client: [
          { required: true, message: "最大连接数", trigger: "blur" },
          { type: "number", message: "值必须为数字" }],
        web_listen_ip: [{ required: true, message: "请输入web监听ip", trigger: "blur" }],
        web_listen_port: [{ required: true, message: "请输入web监听端口", trigger: "blur" }],
        agentid: [{ required: true, message: "请选择代理商", trigger: "blur" }],
        gm_addr: [{ required: true, message: "请输入api地址", trigger: "blur" }],
      },
      code: "",
      NameListForm: {},
      // 获取游戏名称
      NameList: {},
      // 编辑表单
      editForm: {
        channelid: "",
        is_open: "",
        status: "",
        addr: "",
        listen_ip: "",
        listen_port: "",
        max_client: "",
        gm_addr: "",
        agentid: "",
        web_listen_ip:"",
        web_listen_port:"",
      },
      // 添加验证表单对象
      editFormRules: {
        channelid: [
          { required: true, message: "请输入ID", trigger: "blur" },
          { type: "number", message: "值必须为数字" }
        ],
        is_open: [{ required: true, message: "请选择状态", trigger: "blur" }],
        status: [{ required: true, message: "请选择状态", trigger: "blur" }],
        addr: [
          { required: true, message: "请输入节点地址", trigger: "blur" }
        ],
        listen_ip: [{ required: true, message: "请输入对外监听ip", trigger: "blur" }],
        listen_port: [{ required: true, message: "请输入对外监听端口", trigger: "blur" }],
        max_client: [
          { required: true, message: "最大连接数", trigger: "blur" },
          { type: "number", message: "值必须为数字" }],
        web_listen_ip: [{ required: true, message: "请输入web监听ip", trigger: "blur" }],
        web_listen_port: [{ required: true, message: "请输入web监听端口", trigger: "blur" }],
        agentid: [{ required: true, message: "请选择代理商", trigger: "blur" }],
        gm_addr: [{ required: true, message: "请输入api地址", trigger: "blur" }],
      },
      agentidlist: [],
    };
  },
  mounted() {
    this.getConfigList();
    this.addqdf();
    String.prototype.format = function(args) {
      var result = this;
      if (arguments.length > 0) {
        if (arguments.length == 1 && typeof args == "object") {
          for (var key in args) {
            if (args[key] != undefined) {
              var reg = new RegExp("\\{" + key + "\\}", "g");
              result = result.replace(reg, args[key]);
            }
          }
        } else {
          for (var i = 0; i < arguments.length; i++) {
            if (arguments[i] != undefined) {
              var reg = new RegExp("\\{" + i + "\\}", "g");
              result = result.replace(reg, arguments[i]);
            }
          }
        }
      }
      return result;
    };
  },

  methods: {

    // 监听pageName改变的事件
    handleSizeChange(pagenum) {
      this.loading = false;
      this.queryInfo.pagenum = pagenum;
      this.getConfigList();
      this.loading = true;
    },
    // 监听页码值改变的事件
    handleCurrentChange(newPage) {
      this.loading = false;
      this.queryInfo.page = newPage;
      this.getConfigList();
      this.loading = true;
    },

    change(newPage) {
      this.loading = false;
      this.handleCurrentChange(newPage);
      this.loading = true;
    },


    // 获取表格列表
    getConfigList() {
      let that = this;
      this.$Http({
        url: "/api/list",
        successful(res, data) {
          // console.log(data);
          that.loading = false;
          that.GameConfig = data.data.data;
          that.total = data.data.total;
        },
      });
    },
    addqdf() {
      this.addDialogVisible = false;
      let that = this;
      this.$Http({
        url: "/api/getagent",
        successful(res, data) {
          that.agentidlist = data.data;
        },
      });
    },

    // indexSelect(event) {
    //   this.agentid = this.addForm.agentid = event;
    //   this.agentidlist.forEach((v, i) => {
    //     var agent = v;
    //     if(agent.agent_id == event){
    //       this.addForm.aeskey = agent.aeskey;
    //       this.addForm.md5key = agent.md5key;
    //       this.editForm.aeskey = agent.aeskey;
    //       this.editForm.md5key = agent.md5key;
    //     }
    //   });
    //
    // },

    addList() {
      var params = this.addForm;
      this.$refs.addFormRef.validate((valid) => {
        if (!valid) return;
        //  添加请求
        let that = this;
        this.$Http({
          url: "/api/addchannel",
          params,
          successful(res, data) {
            if (data.status == false) {
              that.$message.error(data.message);
            } else {
              that.addDialogVisible = false;
              that.$message.success("添加成功");
              that.getConfigList();
            }
          }
        });
      });
    },
    //编辑
    showEditDialog(row) {
      let that = this;
      that.editForm = Object.assign({},row);
      this.editDialogVisible = true;
      this.$Http({
        url: "/api/getagent",
        successful(res, data) {
          that.agentidlist = data.data;
          // console.log(row);
          that.editForm.is_open = String(row.is_open);
          that.editForm.status = String(row.status);
          that.editForm.agentid = String(row.agentid);
        },
      });
    },
    editList() {
      var params = this.editForm;
      this.editDialogVisible = false;
      this.$refs.editFormRef.validate((valid) => {
        let that = this;
        this.$Http({
          url: "/api/editchannel",
          params,
          successful(res, data) {
            if (data.status == false) {
              that.$message.error(data.message);
            } else {
              if (data.code != 200) {
                that.$message.error(data.message);
                params.code = data.code;
              } else {
                that.editDialogVisible = false;
                that.$message.success("修改"+data.message);
                that.getConfigList();
              }
            }
          }
        });
      });
    },
    removeUserById(row) {
        let that = this;
        var params = {channelid:row.channelid};
        // 根据弹框删除
        const confirmResult = this.$confirm(
          "此操作将删除该渠道服，是否继续？",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            if(row.is_open == true){
              that.$message.error("请先关闭当前渠道服，再进行删除操作！");
            }else{
              this.$Http({
                url: "/api/delchannel",
                params,
                successful(res, data) {
                  if (data.status == false) {
                    that.$message.error(data.message);
                  } else {
                    if (data.code != 200) {
                      that.$message.error(data.message);
                    } else {
                      that.getConfigList();
                      that.$message.success("删除"+data.message);
                    }
                  }
                }
              });
            }
          })
          .catch(() => {
            that.$message.info("已取消删除");
          });
    },
    // 停用部分
    stopUserById(row, index) {
      // console.log(row);
      let that = this;
      let is_open = row.is_open === 1 ? "停用" : "启用";
      var params = {channelid:row.channelid,is_open:row.is_open};
      const confirmResult = this.$confirm("提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        message: "此操作将" + is_open + "该渠道服，是否继续？"
      })
        .then(() => {
          this.$Http({
            url: "/api/isopen",
            params,
            successful(res, data) {
              if (data.status == false) {
                that.$message.error(data.message);
              } else {
                if (data.code != 200) {
                  that.$message.error(data.data.d.msg);
                  params.code = data.code;
                } else {
                  that.addDialogVisible = false;
                  that.getConfigList();
                  that.$message.success(data.data.d.msg);
                }
              }
            },
          });
        })
        .catch(() => {
          that.$message.info("已取消停用");
        });
    },
  },
};
</script>

<style lang="less" >
.searchBox {
  display: flex;
  align-items: center;
  /deep/ .el-form-item__error {
    color: red;
    font-size: 12px;
    font-weight: 600;
    line-height: 1;
    padding-top: 4px;
    position: absolute;
    top: 25% !important;
    right: -71px !important;
    left: initial;
  }
}
.el-input-group__append {
  background-color: unset !important;
  color: unset !important;
  vertical-align: middle !important;
  display: table-cell !important;
  position: relative !important;
  border: 0 !important;
  border-radius: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  right: 20px !important;
  white-space: nowrap !important;
}
/deep/.label .el-form-item__labe {
  width: 110px !important;
}
/deep/.el-dialog__body {
  padding: 0 !important;
}

.el-row--flex.is-justify-space-around {
   justify-content: space-around !important;
}

#ListBox .TitleBox .title {
  font-weight: 700;
  font-size: 20px;
  display: flex;
  align-items: center;
}

#ListBox .TitleBox {
  justify-content: space-between;
  margin-bottom: 20px;
}

#ListBox .TitleBox .AddList button {
  font-weight: 700;
  background: #6495ed;
  border: 1px solid grey;
  border-radius: 2px;
  padding: 5px;
  cursor: pointer;
  color: #f5f5f5;
}
#ListBox .TitleBox, .page {
   display: flex;
  align-items: center;
}
.page {
  font-weight: 400;
  color: #606266;
  font-size: 13px;
  line-height: 28px;
  justify-content: flex-end;
}
#ListBox .btn {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 5px;
  color: #f5f5f5;
  width: 100%;
  text-align: center;
  margin: 3px auto;
  background: purple;
  width: 100px;
}
#ListBox .btn3 {
  background: #00f;
}
#ListBox .btn7 {
  background: #78c2ea;
}
#ListBox .btn8 {
  background: #447792;
}
</style>