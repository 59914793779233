<template>
  <!-- 公共库配置 -->
  <div id="ListBox">
    <div class="TitleBox">
      <p class="title">公共库配置</p>
      <div class="AddList">
        <el-button
          type="success"
          icon="el-icon-circle-plus-outline"
          round
          @click="addDialogVisible = true"
        >新增公共库</el-button>
      </div>
    </div>
    <el-card class="TabelBox" show>
      <!-- 表格 -->
      <el-table :data="GameList" style="width: 100%" ref="multipleTable">
        <el-table-column label="名称" prop="name"></el-table-column>
        <el-table-column label="资源地址" prop="addr"></el-table-column>
        <el-table-column label="版本号" prop="version"></el-table-column>
        <el-table-column fixed="right" label="操作" class="btns">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" round @click="showEditDialog(scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" round @click="removeUserById(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top:10px;text-align:center">
        <el-button type="primary" @click="update()">更新版本</el-button>
      </div>
      <el-dialog
        :title="'新增公共库'"
        style="text-align:left !important"
        :visible.sync="addDialogVisible"
        :close-on-click-modal="false"
      >
        <el-form ref="addFormRef" label-position="right" :inline="true" :model="addForm" :rules="addFormRules">
          <el-row type="flex" justify="center">

            <el-col :span="8">
              <el-form-item label="名称" prop="name">
                <el-input v-model="addForm.name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="资源地址" prop="addr">
                <el-input v-model="addForm.addr"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="版本号" prop="version">
                <el-input v-model="addForm.version"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <!-- 按钮区域 -->
        <span slot="footer" class="dialog-footer">
          <el-button type="success" @click="addList">提交</el-button>
          <el-button type="primary" @click="addDialogVisible = false">取消</el-button>
        </span>
      </el-dialog>

      <!-- 编辑游戏列表对话框 -->
      <el-dialog
        :title="'编辑公共库'"
        style="text-align:left !important"
        :visible.sync="editDialogVisible"
        :close-on-click-modal="false"
      >
        <!-- 内容部分 -->
<!--        :rules="editFormRules"-->
        <el-form ref="editFormRef" :model="editForm" :inline="true" >
          <el-row type="flex" justify="center">
            <el-col :span="8">
              <el-form-item label="名称" prop="name">
                <el-input v-model="editForm.name" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="资源地址" prop="addr">
                <el-input v-model="editForm.addr"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="版本号" prop="version">
                <el-input v-model="editForm.version"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <!-- 按钮区域 -->
        <span slot="footer" class="dialog-footer">
          <el-button type="success" @click="editList">提交</el-button>
          <el-button type="primary" @click="editDialogVisible = false">取消</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import { Encrypt, Decrypt } from "../../assets/js/utils";
export default {
  data() {
    return {
      loading: true,
      search: "",
      GameList: [],
      addDialogVisible: false,
      editDialogVisible: false,
      addForm: {
        addr: "",
        name: "",
        version: ""
      },
      // 添加验证表单对象
      addFormRules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        addr: [{ required: true, message: "请输入资源地址", trigger: "blur" }],
        version: [{ required: true, message: "请输入版本号", trigger: "blur" }],
      },
      editForm: {
        addr: "",
        name: "",
        version: ""
      }
    };
  },
  mounted() {
    this.getGameList();
  },
  methods: {
    getGameList() {
      let that = this;
      this.$Http({
        url: "/api/gamebase/library",
        successful(res, data) {
          that.GameList = data.data;
        }
      });
    },
    // 点击添加列表提交按钮添加列表
    addList() {
      this.$refs.addFormRef.validate(valid => {
        //  添加请求
        var params = this.addForm;
        let that = this;
        this.$Http({
          url: "/api/gamebase/addlibrary",
          params,
          successful(res, data) {
            if (data.status == false) {
              that.$message.error(data.message);
            } else {
              if (data.code != 200) {
                that.$message.error(data.message);
                params.code = data.code;
              } else {
                that.addDialogVisible = false;
                that.getGameList();
                that.$message.success("新增"+data.message);
              }
            }
          }
        });
      });
    },
    showEditDialog(row) {
      this.editDialogVisible = true;
      let that = this;
      that.editForm.name = row.name;
      that.editForm.addr = row.addr;
      that.editForm.version = row.version;
    },
    editList() {
      this.$refs.editFormRef.validate(valid => {
        //修改请求
        let that = this;
        var params = this.editForm;
        this.$Http({
          url: "/api/gamebase/editlibrary",
          params,
          successful(res, data) {
            if (data.status == false) {
              that.$message.error(data.message);
            } else {
              that.$message.success("操作成功");
              that.editDialogVisible = false;
              that.getGameList();
            }
          }
        });
      });
    },
    removeUserById(row) {
      let that = this;
      var params = {
        name: row.name
      };
      const confirmResult = this.$confirm(
        "此操作将删除该公共库，是否继续？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }
      ).then(() => {
        this.$Http({
          url: "/api/gamebase/dellibrary",
          params,
          successful(res, data) {
            if (data.status == false) {
              that.$message.error(data.message);
            } else {
              that.$message.success(data.message);
              that.getGameList();
            }
          }
        });
      })
      .catch(() => {
        that.$message.info("已取消删除");
      });
    },
    update() {
      let that = this;
      this.$Http({
        url: "/api/gamebase/client",
        successful(res, data) {
            that.$message.success(data.data.d.msg);
            that.loading = false;
            that.getGameList();
        }
      });
    }
  }
};
</script>
<style>
  #ListBox .TitleBox{
    display: flex;
    align-items: center;
  }
  #ListBox .TitleBox {
    justify-content: space-between;
    margin-bottom: 20px;
  }

  #ListBox .TitleBox .AddList button {
    font-weight: 700;
    background: #6495ed;
    border: 1px solid grey;
    border-radius: 2px;
    padding: 5px;
    cursor: pointer;
    color: #f5f5f5;
  }


</style>
