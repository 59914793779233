<template>
  <!-- 游戏配置与调控--版本管理 -->
  <div
  >
    <!-- 第一部分 -->
    <el-card show>
      <h3 class="title">
        版本管理
      </h3>
    </el-card>
    <!-- 公共库  -->
    <el-card show style="margin:20px 0;">
      <el-row type="flex" justify="space-between" style="align-items:center">
        <h3>公共库</h3>
        <div class="AddList">
          <el-button
                  type="success"
                  icon="el-icon-circle-plus-outline"
                  round
                  @click="addcommon"
                  :close-on-click-modal="false"
          >新增公共库</el-button>
        </div>
      </el-row>

      <!-- 表格 -->
      <el-row>
        <el-table :data="commondata" style="width: 100%" height="200px">
          <el-table-column label="名称" prop="name"></el-table-column>
          <el-table-column label="版本号" prop="version"></el-table-column>
          <el-table-column label="资源地址" prop="addr"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button size="mini" type="success" @click="commondel(scope.$index, scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </el-card>



    <el-card show>
      <el-form
        :inline="true"
        ref="gameeditFormRef"
        :model="gameeditForm"
        label-width="80px"
        :rules="gameeditFormRules"
        :close-on-click-modal="false"
      >
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="8">
            <el-form-item label="GameID" prop="gameid">
              <el-input  v-model="gameeditForm.gameid" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="游戏名称" prop="gamename">
              <el-input  v-model="gameeditForm.gamename"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="版本号" prop="version">
              <el-input  v-model="gameeditForm.version"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="资源地址" prop="addr">
              <el-input  v-model="gameeditForm.addr"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" justify="center" style="margin:70px 0">
          <el-col :span="4">
            <el-form-item>
              <el-button type="primary" @click="updatemanager()">更新版本</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>

    <el-dialog
            :title="'添加公共库'"
            style="text-align:left !important"
            :visible.sync="addcommonVisible"
            :close-on-click-modal="false"
    >
      <!-- 内容部分 -->
      <el-form ref="commonaddFormRef" :model="commonaddForm" :inline="true" >
        <el-row type="flex" justify="center">
          <el-col :span="8">
            <el-form-item label="名称" prop="name">
              <el-select v-model="commonaddForm.name" placeholder="请选择" @change="indexSelect($event)">
                <el-option
                        :label="item.name"
                        v-for="(item,index) of namelist"
                        :key="index"
                        :value="item.name"
                ></el-option>
              </el-select>

            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="资源地址" prop="addr">
              <el-input v-model="commonaddForm.addr" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="版本号" prop="version">
              <el-input v-model="commonaddForm.version" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- 按钮区域 -->
      <span slot="footer" class="dialog-footer">
          <el-button type="success" @click="commonaddList">提交</el-button>
          <el-button type="primary" @click="addcommonVisible = false">取消</el-button>
        </span>
    </el-dialog>

  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      name: [],
      names: [],
      addcommonVisible: false,
      commonaddForm: {
        version: "",
        name: "",
        addr: ""
      },
      // 添加验证表单对象
      gameeditFormRules: {
        gamename: [{ required: true, message: "请填写游戏名称", trigger: "blur" }],
        version: [{ required: true, message: "请填写游戏版本号", trigger: "blur" }],
        addr: [{ required: true, message: "请填写游戏资源地址", trigger: "blur" }],
      },
      namelist: [],
      commondata: [],
      commonallData: [],
      commontotal: 0,
      gamenames: this.$route.params.gamenames,
      gameeditForm: {
        gameid: this.$route.params.gameid,
        name: "",
        version: this.$route.params.version,
        gamename: this.$route.params.gamename,
        addr: this.$route.params.addrd
      },
    };
  },
  mounted() {
    this.getcommontab();
  },
  methods: {
    getcommontab() {
      let that = this;
      var params = { gameid: that.gameeditForm.gameid}
      this.$Http({
        url: "api/gamebase/getversion",
        params,
        successful(res, data) {
            that.commondata = data.data;
            that.loading = false;
            data.data.forEach(val => {
              that.names.push(val.name);
           });
        }
      });
    },
    addcommon() {
      this.addcommonVisible = true;
      this.namelist = [];
      this.getnamelist();
    },
    getnamelist() {
      let that = this;
      this.$Http({
        url: "api/gamebase/library",
        successful(res, data) {
          that.namelist = data.data;
        }
      });
    },

    indexSelect(event) {
      if(this.names.indexOf(event) > -1){
        this.commonaddForm.version = " ";
        this.commonaddForm.addr = " ";
        this.$message.error("要添加的名称已存在，请重新添加！");
      }else{
        this.commonaddForm.name = event;
        this.namelist.forEach((v, i) => {
          if(v.name == event){
            this.commonaddForm.version = v.version;
            this.commonaddForm.addr = v.addr;
          }
        });
      }
    },

    commonaddList() {
      let that = this;
      this.$refs.commonaddFormRef.validate(valid => {
        let list = {
          version: that.commonaddForm.version,
          name: that.commonaddForm.name,
          addr: that.commonaddForm.addr
        };
        if(this.names.indexOf(list.name) > -1){
          this.commonaddForm.version = " ";
          this.commonaddForm.addr = " ";
          this.commonaddForm.name = " ";
          this.$message.error("要添加的名称已存在，请重新添加！");
        }else{
          that.commondata.push(list);
          that.addcommonVisible = false;
          that.addcommonClosed();
        }
      });
    },
    commondel(index, row) {
      let that = this;
      // 根据弹框删除
      const confirmResult = this.$confirm("提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        message: "此操作将删除该列表，是否继续？？"
      })
      .then(() => {
        that.commondata.splice(index,1);
        that.addcommonVisible = false;
        that.addcommonClosed();
      })
      .catch(() => {
        that.$message.info("已取消删除");
      });
    },
    updatemanager() {
      this.loading = true;
      let that = this;
      var params = {
          gameid:that.gameeditForm.gameid,
          dependences:that.names,
          gamename:that.gameeditForm.gamename,
          version:that.gameeditForm.version,
          addr:that.gameeditForm.addr
        };
      this.$refs.gameeditFormRef.validate(valid => {
          this.$Http({
            url: "api/gamebase/updateversion",
            params,
            successful(res, data) {
              if (data.status == false) {
                that.$message.error(data.message);
              } else {
                if (data.code != 200) {
                  that.$message.error(data.data.d.msg);
                  params.code = data.code;
                } else {
                  that.addDialogVisible = false;
                  that.getcommontab();
                  that.$message.success(data.data.d.msg);
                }
              }
            }
          });
      });
    },

    addcommonClosed(){
      let that = this;
      that.names = [];
      that.commondata.forEach(val => {
        that.names.push(val.name);
      });
    }

  }
};
</script>

<style lang="less" scoped>
/deep/ .editgame {
  .el-input {
    width: 210px;
  }
}

.AddList button {
  font-weight: 700;
  background: #6495ed;
  border: 1px solid grey;
  border-radius: 2px;
  padding: 5px;
  cursor: pointer;
  color: #f5f5f5;
}

.AddList .TitleBox {
  justify-content: space-between;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

</style>