<template>
  <!-- 风控系统--风控管理日志 -->
  <el-card
    shadow="hover"
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >

    <el-card>
      <div class="title" style="border-bottom:1px solid silver;padding-bottom:15px">风控管理日志</div>
      <el-row>
        <el-table :data="allData" border style="width: 100%" height="600px">
          <el-table-column label="操作用户" prop="owner" ></el-table-column>
          <el-table-column label="周期内第几天" prop="nday" ></el-table-column>
          <el-table-column label="操作对象" prop="object" ></el-table-column>
          <el-table-column label="操作前的周期盈利" prop="lod_profits">
            <template slot-scope="scope">
              <span>{{scope.row.lod_profits }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作前的周期流水" prop="lod_bets" >
            <template slot-scope="scope">
              <span>{{scope.row.lod_bets }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作前的税收" prop="lod_tax" ></el-table-column>
          <el-table-column label="操作前的牌局数" prop="lod_round"></el-table-column>
          <el-table-column label="操作后的周期盈利" prop="new_profits" >
            <template slot-scope="scope">
              <span>{{scope.row.new_profits }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作后的周期流水" prop="new_bets" >
            <template slot-scope="scope">
              <span>{{scope.row.new_bets }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作后的税收" prop="new_tax">
            <template slot-scope="scope">
              <span>{{scope.row.new_tax }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作后的牌局数" prop="new_round"></el-table-column>
          <el-table-column label="操作状态" prop="type">
            <template slot-scope="scope">
              <span v-if="scope.row.type == 1" style="color: green">成功</span>
              <span v-if="scope.row.type == 0" style="color: red">失败</span>
            </template>
          </el-table-column>
          <el-table-column label="记录时间" prop="create_time" width="180"></el-table-column>
        </el-table>
      </el-row>
      <!-- 分页器 -->
      <!-- 分页器 -->
      <div class="page">
        <!-- <span>每页10条,</span> -->
        <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryInfo.page"
                :page-size="queryInfo.pagenum"
                :page-sizes="[20, 50, 100, 200,500]"
                style="float:right"
                layout="sizes,total, prev, pager, next, jumper"
                :total="total"
        ></el-pagination>
        <el-button type="primary" @click="change(queryInfo.page)">确定</el-button>
      </div>
    </el-card>
  </el-card>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      tabledata: [],
      allData: [],
      gamelist:[],
      queryInfo: {
        page: 1,
        pagenum: 20
      },
      total: 0,
      // 时间查询
      pickerOptions: {
        disabledDate: time => {
          return this.dealDisabledDate(time);
        }
      },
      searchForm: {
        timearr: [],
        nday: "",
        areaid: ""
      }
    };
  },
  mounted() {
    this.gettab();
    this.getDay();
  },
  methods: {
    change(newPage) {
      this.loading = false;
      this.handleCurrentChange(newPage);
      this.loading = true;
    },
    // 监听pageName改变的事件
    handleSizeChange(pagenum) {
      this.loading = false;
      this.queryInfo.pagenum = pagenum;
      this.gettab();
      this.loading = true;
    },
    // 监听页码值改变的事件
    handleCurrentChange(newPage) {
      this.loading = false;
      this.queryInfo.page = newPage;
      this.gettab();
      this.loading = true;
    },

    // 表格列表
    gettab() {
      this.loading = true;
      let that = this;
      var params = this.searchForm;
      params.page = that.queryInfo.page;
      params.pagenum = that.queryInfo.pagenum;
      this.$Http({
        url: "api/riskcontrol/getlogList",
        params,
        successful(res, data) {
          if (data.status == false) {
            that.$message.error(data.message);
            that.loading = false;
          }
          if (data.status == true) {
            that.loading = false;
            that.allData = data.data.data;
            that.total = data.data.total;
          }
        }
      });
    },

    // 时间查询按钮
    onSubmit() {
      this.loading = true;
      this.gettab();
    },
    // 默认显示时间
    getDay() {
      var now = new Date();
      var year = now.getFullYear(); //得到当前年份
      var month = now.getMonth() + 1; //默认得到月份是上一个月，如果当前是3月，这个值为2月
      var d = now.getDate();
      var h = now.getHours();
      var m = now.getMinutes();
      var s = now.getSeconds();
      if (month == 12)
              //如果当前是1月，则获取到的数据为12月，年份减一
        year = year - 1;
      var nextMonth = month; //其实就是当前月份
      month = month.toString().padStart(2, "0"); //当小于10时，显示为01.02.03
      d = d.toString().padStart(2, "0"); //当小于10时，显示为01.02.03
      nextMonth = nextMonth.toString().padStart(2, "0");
      this.date = `${year}-${month}-${d}`; //拼接日期
      // this.enddate = `${year}-${nextMonth}-${d}`;
      this.queryInfo.Time = this.date;
      // this.queryInfo.Time[1] = this.enddate;
    },
    dealDisabledDate(time) {
      var times = Date.now();
      return time.getTime() > times;
    }
  },

};
</script>

<style>

  .el-select {
    display: inline-block;
    position: relative;
  }

  .el-row--flex {
    display: flex;
    margin-top: 20px;
  }
  .el-range-editor .el-range-input {
    line-height: 1;
    margin-left: 5px;
  }
  #ListBox .TitleBox, .page {
    display: flex;
    align-items: center;
  }
  .page {
    font-weight: 400;
    color: #606266;
    font-size: 13px;
    line-height: 28px;
    justify-content: flex-end;
  }
</style>