// 风控系统--风控数据分析--游戏名称房间列表
<template>
  <el-card
          shadow="hover"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          style="height: 100%;overflow-y: auto;"
  >
  <div id="roomlist">
    <div class="curZoom">
      <span class="cur">当前房间：{{quer.game_name}}({{quer.modename}})</span>
      <span>【</span>
      <span>输赢统计：{{statistics.profits}}</span>
      <span class="tax">税收统计：{{statistics.tax}}</span>
      <span>流水统计：{{statistics.bets}}</span>
      <span>】</span>
      <el-button type="primary" @click="refresh()">刷新</el-button>
    </div>
    <div class="taxtime">
      <span>统计时间：</span>
      <span>{{quer.time}}</span>
      <el-button type="primary"  style="margin-left: 10px" @click="refresh()">查询</el-button>
    </div>

    <!-- 折线图部分 -->
    <el-card>
      <!-- <p style="text-align:center;color:red;margin-bottom:10px">可滑动缩放折线图</p> -->
      <div id="chartLineBox" style="width:100%;height:400px;" ref="echartRef"></div>
    </el-card>
    <!-- 表格 -->
    <el-card>
      <el-table :data="tableData" stripe style="width: 100%" border>
        <!-- <el-table-column type="index" label="序号" width="100"></el-table-column> -->
        <el-table-column prop="guid" label="玩家ID"></el-table-column>
        <el-table-column prop="profits" label="玩家输赢" sortable></el-table-column>
        <el-table-column prop="tax" label="税收" sortable></el-table-column>
        <el-table-column prop="bets" label="流水" sortable></el-table-column>
        <el-table-column prop="round" label="游戏局数" sortable></el-table-column>
        <!-- <el-table-column prop="profits" label="输赢统计" sortable></el-table-column> -->
      </el-table>
    </el-card>
    <!-- 分页器 -->
    <div class="page">
      <!-- <span>每页10条,</span> -->
      <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="queryInfo.page"
              :page-size="queryInfo.pagenum"
              :page-sizes="[20, 50, 100, 200,500]"
              style="float:right"
              layout="sizes,total, prev, pager, next, jumper"
              :total="total"
      ></el-pagination>
      <el-button type="primary" @click="change(queryInfo.page)">确定</el-button>
    </div>
  </div>
  </el-card>
</template>

<script>
  import echarts from "echarts";
  export default {
    data() {
      return {
        loading: true,
        queryInfo: {
          // 当前页码(第几页)
          page: 1,
          // 每页显示条数
          pagenum: 20
        },
        total: 0,
        // 表格
        tableData: [],
        quer: {
          gameid: this.$route.params.gameid,
          game_details_id: this.$route.params.areaid,
          time: this.$route.params.time,
          game_name: this.$route.params.game_name,
          name: this.$route.params.name,
          modename:this.$route.params.modename
        },
        statistics: {},
        interval:11,
      };
    },
    computed: {
      echartsOptions() {
        let that = this;
        let timeArr =[];
        var time;
        for (var i=0;i<24;i++){
          if(i < 10){
            time = '0'+i+":04";
          }else{
            time = i+":04";
          }
          timeArr.push(time);
        }
        timeArr.push("23:59");

        let echartsOptions = {
          title: {
            text: this.quer.time
          },
          tooltip: {
            trigger: "axis"
          },
          legend: {
            data: ["输赢统计"]
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: [],
            // name: "TIME", //X轴 name
            axisLabel: {
              interval:this.interval,
              rotate:30,
            }
          },
          yAxis: {
            type: "value"
          },

          series: [
            {
              name: "输赢统计",
              symbol:(a,b)=>{
                if(this.isStrInArray(b.name,timeArr)){
                  return 'emptyCircle';
                }else{
                  return 'none';
                }
              },
              type: "line",
              data: []
            }
          ]
        };
        return echartsOptions;
      }
    },
    mounted() {
      // 获取折线图
      this.getline();
      this.getDay();
    },
    methods: {

      isStrInArray(str, arr) {
        let n = arr.length;
        for (let i = 0; i < n; i++) {
          if (arr[i] == str) {
            return true;
          }
        }
        return false;
      },

      dateFormat() {
        var date=new Date();
        var hours=date.getHours()<10 ? "0"+date.getHours() : date.getHours();
        this.start_time = hours+":00";
        this.end_time = hours+":59";
      },

      // 默认显示今天时间
      getDay() {
        const start = new Date();
        start.setTime(start.getTime());
        this.queryInfo.Time = this.formatDate(start);
      },
      formatDate(date) {
        var myyear = date.getFullYear();
        var mymonth = date.getMonth() + 1;
        var myweekday = date.getDate();

        if (mymonth < 10) {
          mymonth = "0" + mymonth;
        }
        if (myweekday < 10) {
          myweekday = "0" + myweekday;
        }
        return myyear + "-" + mymonth + "-" + myweekday;
      },
      // 分页器
      handleSizeChange(pagenum) {
        this.queryInfo.pagenum = pagenum;
      },
      // 监听页码值改变的事件
      handleCurrentChange(newPage) {
        this.queryInfo.page = newPage;
      },
      // 折线图
      getLocalTime(nS) {
        var timestamp4 = new Date(nS * 1000);
        var str = timestamp4.toTimeString().substr(0, 8);
        return str;
      },
      refresh(){
        this.getline();
      },
      getline() {
        var params = {
          game_details_id: this.quer.game_details_id,
          time: this.quer.time,
          start_time : this.start_time,
          end_time : this.end_time
        };
        let that = this;
        const array = [886,802,803,806,808,810];
        if(array.indexOf(that.quer.gameid) > 0){
          that.loading = true;
          this.$Http({
            url: "api/GameConfig/raba_riskAnalysis",
            params,
            successful(res, data) {
              if (data.status == false) {
                that.$message.error(data.message);
                that.loading = false;
              } else {
                that.statistics = data.data;
                that.tableData = data.data.gmarr;
                that.total = data.data.gmarr.length;
                that.echartsOptions.xAxis.data = data.data.curve.time;
                that.echartsOptions.series[0].data = data.data.curve.data;
                that.chartLine = echarts.init(
                        document.getElementById("chartLineBox")
                );
                that.chartLine.setOption(that.echartsOptions, true);
                that.loading = false;
              }
            }
          });
        }else {
            that.loading = true;
            this.$Http({
              url: "api/GameConfig/riskAnalysis",
              params,
              successful(res, data) {
                if (data.status == false) {
                  that.$message.error(data.message);
                  that.loading = false;
                } else {
                  that.statistics = data.data;
                  that.tableData = data.data.gmarr;
                  that.total = data.data.gmarr.length;
                  that.echartsOptions.xAxis.data = data.data.curve.time;
                  that.echartsOptions.series[0].data = data.data.curve.data;
                  that.chartLine = echarts.init(
                          document.getElementById("chartLineBox")
                  );
                  that.chartLine.setOption(that.echartsOptions, true);
                  that.loading = false;
                }
              }
            });
        }
      },
    }
  };
</script>

<style lang="less" scoped>
  #roomlist {
    .curZoom {
      color: red;
      font-weight: 600;
      .cur {
        margin-right: 30px;
      }
      .tax {
        margin: 0 20px;
      }
    }
    .taxtime {
      margin: 15px 0;
      font-size: 16px;
      font-weight: 600;
      .zhi {
        margin: 0 10px;
      }
    }
    .page {
      margin-top: 20px;
    }
  }

  .curZoom button {
    font-weight: 700;
    width: 50px;
    padding: 5px;
    cursor: pointer;
    color: #f5f5f5;
  }
  #ListBox .TitleBox {
    justify-content: space-between;
    margin-bottom: 20px;
  }

  #ListBox .TitleBox, .page {
    display: flex;
    align-items: center;
  }
  .page {
    font-weight: 400;
    color: #606266;
    font-size: 13px;
    line-height: 28px;
    justify-content: flex-end;
  }

</style>