<template>
  <el-card
      shadow="hover"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      style="height: 100%;overflow-y: auto;"
  >
  <!-- 游戏配置与调控--游戏配置 -->
  <div id="ListBox">
    <div class="TitleBox">
      <div class="searchBox">
        <p class="title">游戏配置与调控--游戏配置</p>
        <el-form
          :inline="true"
          :model="searchForm"
          class="demo-form-inline"
          style="margin:0 0 0 20px"
        >
          <el-form-item style="margin:0 10px 0 0 " onkeypress="if(event.keyCode == 13) return false;">
            <el-input v-model="searchForm.gameid" placeholder="游戏ID"></el-input>
          </el-form-item>
          <el-form-item style="margin:0">
            <el-button type="primary"  @click="searchClick" @keyup.enter.native="searchClick" >查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="AddList">
        <el-button
                type="success"
                @click="addDialogVisible = true"
                :close-on-click-modal="false"
                icon="el-icon-circle-plus-outline"
                round
        >新增游戏列表</el-button>
      </div>
    </div>

    <!-- 表格 -->
    <el-table :data="GameConfig" border stripe fit style="width: 100%;" size="mini" height="650">
      <el-table-column prop="gameid" label="GameID" sortable fixed></el-table-column>
      <el-table-column prop="areaid" sortable label="场次编号"></el-table-column>
      <el-table-column prop="gamename" label="游戏名称" width="120"></el-table-column>
      <el-table-column prop="gameModeName" label="游戏玩法" width="100">
        <template slot-scope="scope">{{ scope.row.gameModeName}}</template>
      </el-table-column>
      <el-table-column prop="levelname" label="游戏场次" width="100">
        <template slot-scope="scope">
          <div>{{ scope.row.gameLevelName}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="local_name" label="本地资源名称"></el-table-column>

      <el-table-column label="是否开放" prop>
        <template slot-scope="scope">
          <span>{{scope.row.is_open===0?'关闭':'开启'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="cost" sortable label="房费" width="60">
        <template slot-scope="scope">{{ scope.row.cost }}</template>
      </el-table-column>
      <el-table-column label="底分" sortable prop="base_score" width="60">
        <template slot-scope="scope">{{ scope.row.base_score }}</template>
      </el-table-column>

      <el-table-column label="入场下限" sortable prop="limit_min">
        <template slot-scope="scope">{{ scope.row.limit_min }}</template>
      </el-table-column>
      <el-table-column label="入场上限" sortable prop="limit_max">
        <template slot-scope="scope">{{ scope.row.limit_max }}</template>
      </el-table-column>
      <el-table-column label="税收开关" prop="tax_open">
        <template slot-scope="scope">
          <span>{{scope.row.tax_open===0?'关闭':'开启'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="税收模式" prop="tax_mode"></el-table-column>
      <el-table-column label="税率" sortable prop="tax" width="70"></el-table-column>
      <el-table-column label="支持平台" sortable prop="platform"></el-table-column>

      <!-- 表格按钮 -->
      <el-table-column fixed="right" label="操作" width="440" class="btns">
        <template slot-scope="scope">
<!--          <el-button @click="boot(scope.row)" type="text" size="small" class="btn btn9">机器人设置</el-button>-->
          <el-button @click="jump(scope.row)" type="text" size="small" class="btn btn1">房间列表</el-button>
          <el-button type="text" size="small" class="btn btn2" @click="configDialog(scope.row)">风控设置</el-button>
<!--          <el-button-->
<!--                  type="text"-->
<!--                  size="small"-->
<!--                  class="btn btn3"-->
<!--                  @click="stopUserById(scope.row,scope.$index)"-->
<!--          >{{scope.row.is_open===0?'启用':'停用'}}</el-button>-->
          <el-button type="text" size="small" class="btn btn7" @click="showEditDialog(scope.row)">编辑</el-button>
          <el-button type="text" size="small" class="btn btn8" @click="removeUserById(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页器 -->
    <div class="page">
      <!-- <span>每页10条,</span> -->
      <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="queryInfo.page"
              :page-size="queryInfo.pagenum"
              :page-sizes="[20, 50, 100, 200,500]"
              style="float:right"
              layout="sizes,total, prev, pager, next, jumper"
              :total="total"
      ></el-pagination>
      <el-button type="primary" @click="change(queryInfo.page)">确定</el-button>
    </div>
    <!-- 新增游戏列表 -->
    <el-dialog
      :title="'添加新游戏列表'"
      style="text-align:left !important"
      :close-on-click-modal="false"
      :visible.sync="addDialogVisible"
    >
      <!-- 内容区域 -->
      <el-form ref="addFormRef" :model="addForm" :rules="addFormRules">
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="6">
            <el-form-item label="游戏名称" prop="gamename" :model="addForm">
              <el-select v-model="NameList.gameid" placeholder="游戏名称" @change="indexSelect($event)">
                <el-option
                        :label="item.gamename"
                        :value="item.gameid"
                        v-for="(item,index) of NameList"
                        :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="GameID" prop="gameid">
              <el-input v-model="NameList.gameid" disabled :value="NameList.gameid"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="游戏玩法" prop="modename">
              <el-select v-model="addForm.mode" placeholder="游戏玩法">
                <el-option
                  :label="item.modename"
                  :value="item.mode"
                  v-for="(item,index) of addForm.list2"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="游戏场次" prop="levelname">
              <el-select v-model="addForm.level" placeholder="游戏场次">
                <el-option
                  :label="item.levelname"
                  :value="item.level"
                  v-for="(item,index) of addForm.list1"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="6">
            <el-form-item label="是否开放" prop="is_open">
              <el-select v-model="addForm.is_open" placeholder="是否开放">
                <el-option label="开" value="1"></el-option>
                <el-option label="关" value="0"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="	本地资源名称" prop="local_name">
              <el-input v-model="addForm.local_name" placeholder></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!--@blur="addForm.cost"-->
            <el-form-item label="房费" prop="cost">
              <el-input
                      v-model="addForm.cost"
                      onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="底分" prop="base_score">
              <el-input
                      v-model="addForm.base_score"
                      onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="6">
            <el-form-item label="入场下限" prop="limit_min">
              <el-input
                      v-model="addForm.limit_min"
                      onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="入场上限" prop="limit_max">
              <el-input
                      v-model="addForm.limit_max"
                      onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="税率" prop="tax">
              <el-input v-model.number="addForm.tax" placeholder></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="税收开关" prop="tax_open">
              <el-select v-model="addForm.tax_open" placeholder="税收开关">
                <el-option label="开" value="1"></el-option>
                <el-option label="关" value="0"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="6">
            <el-form-item label="税收模式" prop="tax_mode">
              <el-input v-model.number="addForm.tax_mode" placeholder></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="	支持平台" prop="platform">
              <el-input v-model="addForm.platform" placeholder></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="24">
            <el-form-item label="房间配置" prop="xml_config">
              <el-input v-model="addForm.xml_config" type="textarea"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- 按钮区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="success" @click="addList()">提交</el-button>
        <el-button type="primary" @click="addDialogVisible = false">取消</el-button>
      </span>
    </el-dialog>

    <!-- 编辑部分 -->
    <el-dialog
      :title="'编辑游戏列表'"
      style="text-align:left !important"
      :visible.sync="editDialogVisible"
      :close-on-click-modal="false"
    >
      <el-card
              shadow="hover"
              v-loading="editloading"
              element-loading-text="拼命加载中"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.5)"
              style="height: 100%;overflow-y: auto;"
      >
      <!-- 内容部分 -->
      <el-form ref="editFormRef" :model="editForm" :rules="editFormRules">
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="6">
            <el-form-item label="游戏名称" prop="gamename">
              <el-input v-model="editForm.gamename" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="GameID" prop="gameid">
              <el-input v-model="editForm.gameid" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="游戏玩法" prop="modename">
              <el-input v-model="list3.modename" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="游戏场次" prop="levelname">
              <el-input v-model="list4.levelname" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="6">
            <el-form-item label="是否开放" prop="is_open">
              <el-select v-model="editForm.is_open" placeholder="是否开放">
                <el-option label="开" value="1"></el-option>
                <el-option label="关" value="0"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="	本地资源名称" prop="local_name">
              <el-input v-model="editForm.local_name" placeholder></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item label="	房费" prop="cost">
              <el-input
                      v-model="editForm.cost"
                      onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item label="底分" prop="base_score">
              <el-input
                      v-model="editForm.base_score"
                      onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="6">
            <el-form-item label="入场下限" prop="limit_min">
              <el-input
                      v-model="editForm.limit_min"
                      onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="入场上限" prop="limit_max">
              <el-input
                      v-model="editForm.limit_max"
                      onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="税率" prop="tax">
              <el-input v-model.number="editForm.tax" placeholder></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="税收开关" prop="tax_open">
              <el-select v-model="editForm.tax_open" placeholder="税收开关">
                <el-option label="开" value="1"></el-option>
                <el-option label="关" value="0"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" justify="start">
          <el-col :span="6">
            <el-form-item label="	支持平台" prop="platform">
              <el-input v-model="editForm.platform" placeholder></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="税收模式" prop="tax">
              <el-input v-model.number="editForm.tax_mode" placeholder></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" justify="space-around"></el-row>
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="24">
            <el-form-item label="房间配置" prop="xml_config">
              <template>
                <vue-json-editor
                             v-model="editForm.xml_config"
                             :showBtns="false"
                             :mode="'code'"
                             lang="zh"
                             style="height: 300px"
                       />
                </template>
<!--              <el-input v-model="editForm.xml_config" type="textarea" style="min-height: 260px;">-->
<!--              </el-input>-->
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- 按钮区域 -->
      </el-card>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" :disabled="edit_do" @click="editList()">提交</el-button>
        <el-button type="primary" @click="editDialogVisible = false">取消</el-button>
      </span>
    </el-dialog>

    <!-- 风控设置部分 -->
    <el-dialog
      :title="'风控配置--'+title"
      style="text-align:left !important; font-weight: 600;"
      :close-on-click-modal="false"
      :visible.sync="configDialogVisible"
      @close="configDialogClosed"
    >
      <!-- 内容部分 -->
      <el-form
        ref="configFormRef"
        :model="configForm"
        :rules="configFormRules"
        label-position="right"
        :inline="true"
        label-width="250px"
        :close-on-click-modal="false"
      >
        <el-row type="flex">
          <el-col :span="12">
            <el-form-item label="游戏期望收益率" prop="yxrpr">
              <el-input v-model="configForm.yxrpr"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="游戏保底收益率" prop="yxspr">
              <el-input v-model="configForm.yxspr"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="12">
            <el-form-item label="游戏风控参数a1" prop="yxa1">
              <el-input v-model="configForm.yxa1"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="游戏风控参数a2" prop="yxa2">
              <el-input v-model="configForm.yxa2"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="12">
            <el-form-item label="游戏风控参数b1" prop="yxb1">
              <el-input v-model="configForm.yxb1"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="游戏风控参数b2" prop="yxb2">
              <el-input v-model="configForm.yxb2"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="12">
            <el-form-item label="游戏风控启动有效局数" prop="yx_start_round">
              <el-input v-model.number="configForm.yx_start_round"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="游戏风控启动有效流水" prop="yx_start_bill">
              <el-input v-model.number="configForm.yx_start_bill"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="12" v-if="gameid==670">
            <el-form-item label="整体调控控制力度" prop="EbgCoefficient1">
              <el-input v-model="configForm.EbgCoefficient1"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="gameid==670">
            <el-form-item label="调控风控系数和胜率对应关系" prop="EbgCoefficient2">
              <el-input v-model="configForm.EbgCoefficient2"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12" v-if="gameid==660">
            <el-form-item label="整体调控控制力度" prop="SssCoefficient1">
              <el-input v-model="configForm.SssCoefficient1"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="gameid==660">
            <el-form-item label="调控风控系数和胜率对应关系" prop="SssCoefficient2">
              <el-input v-model="configForm.SssCoefficient2"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="gameid==650">
            <el-form-item label="风控系数为1时的权重倍数" prop="TbnnCoefficient1">
              <el-input v-model="configForm.TbnnCoefficient1"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="gameid==650">
            <el-form-item label="风控系数为-1时的权重倍数" prop="TbnnCoefficient2">
              <el-input v-model="configForm.TbnnCoefficient2"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="gameid==900">
            <el-form-item label="调控风控系数和胜率关系(正值时)" prop="BrnnCoefficient1">
              <el-input v-model="configForm.BrnnCoefficient1"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="gameid==900">
            <el-form-item label="调控风控系数和胜率关系(负值时)" prop="BrnnCoefficient2">
              <el-input v-model="configForm.BrnnCoefficient2"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="gameid==630">
            <el-form-item label="调控风控系数和胜率关系(正值时)" prop="ZjhCoefficient1">
              <el-input v-model="configForm.ZjhCoefficient1"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="gameid==630">
            <el-form-item label="调控风控系数和胜率关系(负值时)" prop="ZjhCoefficient2">
              <el-input v-model="configForm.ZjhCoefficient2"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="gameid==930">
            <el-form-item label="调控风控系数和胜率关系(正值时)" prop="HhdzCoefficient1">
              <el-input v-model="configForm.HhdzCoefficient1"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="gameid==930">
            <el-form-item label="调控风控系数和胜率关系(负值时)" prop="HhdzCoefficient2">
              <el-input v-model="configForm.HhdzCoefficient2"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="gameid==640">
            <el-form-item label="整体调控控制力度">
              <el-input v-model="configForm.QznnCoefficient1"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="gameid==640">
            <el-form-item label="调控风控系数和胜率对应关系" prop="QznnCoefficient2">
              <el-input v-model="configForm.QznnCoefficient2"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="gameid==910">
            <el-form-item label="调控风控系数和胜率关系(正值时)" prop="BjlCoefficient1">
              <el-input v-model="configForm.BjlCoefficient1"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="gameid==910">
            <el-form-item label="调控风控系数和胜率关系(负值时)" prop="BjlCoefficient2">
              <el-input v-model="configForm.BjlCoefficient2"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="gameid==920">
            <el-form-item label="调控风控系数和胜率关系(正值时)" prop="LhdCoefficient1">
              <el-input v-model="configForm.LhdCoefficient1"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="gameid==920">
            <el-form-item label="调控风控系数和胜率关系(负值时)" prop="LhdCoefficient2">
              <el-input v-model="configForm.LhdCoefficient2"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12" v-if="gameid==888">
            <el-form-item label="调控风控系数和胜率关系（正值时）" prop="FxgzCoefficient1">
              <el-input v-model="configForm.FxgzCoefficient1"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="gameid==888">
            <el-form-item label="调控风控系数和胜率关系（负值时）" prop="FxgzCoefficient2">
              <el-input v-model="configForm.FxgzCoefficient2"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12" v-if="gameid==620">
            <el-form-item label="调控风控系数和胜率关系（正值时）" prop="EsydCoefficient1">
              <el-input v-model="configForm.EsydCoefficient1"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="gameid==620">
            <el-form-item label="调控风控系数和胜率关系（负值时）" prop="EsydCoefficient2">
              <el-input v-model="configForm.EsydCoefficient2"></el-input>
            </el-form-item>
          </el-col>


          <el-col :span="12" v-if="gameid==560 || gameid == 520">
            <el-form-item label="风控系数为1时的击杀概率提升倍数" prop="ByCoefficient1">
              <el-input v-model="configForm.ByCoefficient1"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="gameid==560 || gameid == 520">
            <el-form-item label="风控系数为正时的击杀概率倍数曲率" prop="ByCoefficient2">
              <el-input v-model="configForm.ByCoefficient2"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12" v-if="gameid==820">
            <el-form-item label="调控风控系数和胜率关系（正值时）" prop="CsCoefficient1">
              <el-input v-model="configForm.CsCoefficient1" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="gameid==820">
              <el-form-item label="调控风控系数和胜率关系（负值时）" prop="CsCoefficient2">
                <el-input v-model="configForm.CsCoefficient2" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"></el-input>
              </el-form-item>
          </el-col>


          <el-col :span="12" v-if="gameid==690">
            <el-form-item label="调控风控系数和标签关系（正值时）" prop="HbslCoefficient1">
                <el-input v-model.number="configForm.HbslCoefficient1"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12" v-if="gameid==690">
            <el-form-item label="调控风控系数和标签关系（负值时）" prop="HbslCoefficient2">
              <el-input v-model="configForm.HbslCoefficient2" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"></el-input>
            </el-form-item>
          </el-col>


          <el-col :span="12" v-if="gameid==710">
            <el-form-item label="风控系数为1时的权重倍数" prop="KpqznnCoefficient1">
              <el-input v-model="configForm.KpqznnCoefficient1" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="gameid==710">
            <el-form-item label="风控系数为-1时的权重倍数" prop="KpqznnCoefficient2">
              <el-input v-model="configForm.KpqznnCoefficient2" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12" v-if="gameid==886">
            <el-form-item label="调控风控系数和胜率关系(正值时)" prop="SjczCoefficient1">
              <el-input v-model="configForm.SjczCoefficient1" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="gameid==886">
            <el-form-item label="调控风控系数和胜率关系(负值时)" prop="SjczCoefficient2">
              <el-input v-model="configForm.SjczCoefficient2" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12" v-if="gameid==802">
            <el-form-item label="调控风控系数和胜率关系(正值时)" prop="WsjbCoefficient1">
              <el-input v-model="configForm.WsjbCoefficient1" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="gameid==802">
            <el-form-item label="调控风控系数和胜率关系(负值时)" prop="WsjbCoefficient2">
              <el-input v-model="configForm.WsjbCoefficient2" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"></el-input>
            </el-form-item>
          </el-col>


          <el-col :span="12" v-if="gameid==806">
            <el-form-item label="各赔率表权重的调节倍数(正值时)" prop="BszCoefficient1">
              <el-input v-model="configForm.BszCoefficient1" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="gameid==806">
            <el-form-item label="各赔率表权重的调节指数(正值时)" prop="BszCoefficient2">
              <el-input v-model="configForm.BszCoefficient2" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"></el-input>
            </el-form-item>
          </el-col>


          <el-col :span="12" v-if="gameid==803">
            <el-form-item label="调控风控系数和胜率关系(正值时)" prop="YqsCoefficient1">
              <el-input v-model="configForm.YqsCoefficient1" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="gameid==803">
            <el-form-item label="调控风控系数和胜率关系(负值时)" prop="YqsCoefficient2">
              <el-input v-model="configForm.YqsCoefficient2" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12" v-if="gameid==808">
            <el-form-item label="各赔率表权重的调节倍数(正值时)" prop="MlhgCoefficient1">
              <el-input v-model="configForm.MlhgCoefficient1" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="gameid==808">
            <el-form-item label="各赔率表权重的调节指数(正值时)" prop="MlhgCoefficient2">
              <el-input v-model="configForm.MlhgCoefficient2" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"></el-input>
            </el-form-item>
          </el-col>


          <el-col :span="12" v-if="gameid==810">
            <el-form-item label="各赔率表权重的调节倍数(正值时)" prop="SgpdCoefficient1">
              <el-input v-model="configForm.SgpdCoefficient1" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="gameid==810">
            <el-form-item label="各赔率表权重的调节指数(正值时)" prop="SgpdCoefficient2">
              <el-input v-model="configForm.SgpdCoefficient2" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"></el-input>
            </el-form-item>
          </el-col>


        </el-row>

        <el-row type="flex">
          <el-col :span="12" v-if="gameid==640">
            <el-form-item label="调控顺位偏离控制力度" prop="QznnCoefficient3">
              <el-input v-model="configForm.QznnCoefficient3"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="gameid==910">
            <el-form-item label="仅可开“和”时的“和”概率缩放系数" prop="BjlCoefficient3">
              <el-input v-model="configForm.BjlCoefficient3"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="gameid==910">
            <el-form-item label="必杀时对子的概率缩放系数" prop="BjlCoefficient4">
              <el-input v-model="configForm.BjlCoefficient4"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="gameid==670">
            <el-form-item label="调控顺位偏离控制力度" prop="EbgCoefficient3">
              <el-input v-model="configForm.EbgCoefficient3"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="gameid==920">
            <el-form-item label="仅可开“和”时的“和”概率缩放系数" prop="LhdCoefficient3">
              <el-input v-model="configForm.LhdCoefficient3"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="gameid==660">
            <el-form-item label="调控顺位偏离控制力度" prop="SssCoefficient3">
              <el-input v-model="configForm.SssCoefficient3"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="gameid==630">
            <el-form-item label="强杀概率上限" prop="ZjhCoefficient3">
              <el-input v-model="configForm.ZjhCoefficient3"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="gameid==630">
            <el-form-item label="调整强杀概率和风控系数关系" prop="ZjhCoefficient4">
              <el-input v-model="configForm.ZjhCoefficient4"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12" v-if="gameid==888">
            <el-form-item label="必送时对巨奖命中几率的提升倍率" prop="FxgzCoefficient3">
              <el-input v-model="configForm.FxgzCoefficient3"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="gameid==888">
            <el-form-item label="必送时对免费游戏触发几率的提升倍率" prop="FxgzCoefficient4">
              <el-input v-model="configForm.FxgzCoefficient4"></el-input>
            </el-form-item>
          </el-col>


          <el-col :span="12" v-if="gameid==560 || gameid == 520">
            <el-form-item label="风控系数为-1时的击杀概率缩小倍数" prop="ByCoefficient3">
              <el-input v-model="configForm.ByCoefficient3"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="gameid==560 || gameid == 520">
            <el-form-item label="风控系数为负时的击杀概率倍数曲率" prop="ByCoefficient4">
              <el-input v-model="configForm.ByCoefficient4"></el-input>
            </el-form-item>
          </el-col>


          <el-col :span="12" v-if="gameid==820">
            <el-form-item label="必送时中奖类型2权重的提升倍数" prop="CsCoefficient3">
              <el-input v-model="configForm.CsCoefficient3" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="gameid==820">
            <el-form-item label="必送时中奖类型3权重的提升倍数" prop="CsCoefficient4">
              <el-input v-model="configForm.CsCoefficient4" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"></el-input>
            </el-form-item>
          </el-col>


          <el-col :span="12" v-if="gameid==886">
            <el-form-item label="必送时中奖类型2权重的提升倍率" prop="SjczCoefficient3">
              <el-input v-model="configForm.SjczCoefficient3" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12" v-if="gameid==802">
            <el-form-item label="必送时中奖类型2权重的提升倍率" prop="WsjbCoefficient3">
              <el-input v-model="configForm.WsjbCoefficient3" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="gameid==802">
            <el-form-item label="必送时中奖类型3权重的提升倍率" prop="WsjbCoefficient4">
              <el-input v-model="configForm.WsjbCoefficient4" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12" v-if="gameid==806">
            <el-form-item label="各赔率表权重的调节倍数(负值时)" prop="BszCoefficient3">
              <el-input v-model="configForm.BszCoefficient3" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="gameid==806">
            <el-form-item label="各赔率表权重的调节指数(负值时)" prop="BszCoefficient4">
              <el-input v-model="configForm.BszCoefficient4" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"></el-input>
            </el-form-item>
          </el-col>


          <el-col :span="12" v-if="gameid==803">
            <el-form-item label="必送时中奖类型2权重的提升倍率" prop="YqsCoefficient3">
              <el-input v-model="configForm.YqsCoefficient3" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="gameid==803">
            <el-form-item label="必送时中奖类型3权重的提升倍率" prop="YqsCoefficient4">
              <el-input v-model="configForm.YqsCoefficient4" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12" v-if="gameid==808">
            <el-form-item label="各赔率表权重的调节倍数(负值时)" prop="MlhgCoefficient3">
              <el-input v-model="configForm.MlhgCoefficient3" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="gameid==808">
            <el-form-item label="各赔率表权重的调节指数(负值时)" prop="MlhgCoefficient4">
              <el-input v-model="configForm.MlhgCoefficient4" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12" v-if="gameid==810">
            <el-form-item label="各赔率表权重的调节倍数(负值时)" prop="SgpdCoefficient3">
              <el-input v-model="configForm.SgpdCoefficient3" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="gameid==810">
            <el-form-item label="各赔率表权重的调节指数(负值时)" prop="SgpdCoefficient4">
              <el-input v-model="configForm.SgpdCoefficient4" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"></el-input>
            </el-form-item>
          </el-col>

        </el-row>

        <el-row type="flex">
          <el-col :span="12" v-if="gameid==910">
            <el-form-item label="必送时对子的概率缩放系数" prop="BjlCoefficient5">
              <el-input v-model="configForm.BjlCoefficient5"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="12" v-if="gameid==910">
            <el-form-item label="庄初始权重值" prop="bjlopt1">
              <el-input v-model.number="configForm.bjlopt1"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="gameid==910">
            <el-form-item label="闲初始权重值" prop="bjlopt2">
              <el-input v-model.number="configForm.bjlopt2"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="gameid==920">
            <el-form-item label="龙初始权重值" prop="lhdopt1">
              <el-input v-model.number="configForm.lhdopt1"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="gameid==920">
            <el-form-item label="虎初始权重值" prop="lhdopt2">
              <el-input v-model.number="configForm.lhdopt2"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12" v-if="gameid==888">
            <el-form-item label="必送时对Jackpot触发几率的提升倍率" prop="FxgzCoefficient5">
              <el-input v-model="configForm.FxgzCoefficient5"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="gameid==888">
            <el-form-item label="免费游戏上限保底金额的倍数" prop="FxgzCoefficient6">
              <el-input v-model="configForm.FxgzCoefficient6"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12" v-if="gameid==820">
            <el-form-item label="玩家并发系数" prop="CsCoefficient5">
              <el-input v-model="configForm.CsCoefficient5" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,1})?).*$/g, '$1')"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12" v-if="gameid==802">
            <el-form-item label="必送时中奖类型4权重的提升倍率" prop="WsjbCoefficient5">
              <el-input v-model="configForm.WsjbCoefficient5" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12" v-if="gameid==806">
            <el-form-item label="中奖概率上调上限(正值时)" prop="BszCoefficient5">
              <el-input v-model="configForm.BszCoefficient5" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="gameid==806">
            <el-form-item label="中奖概率下调上限(负值时)" prop="BszCoefficient6">
              <el-input v-model="configForm.BszCoefficient6" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12" v-if="gameid==808">
            <el-form-item label="中奖概率上调上限(正值时)" prop="MlhgCoefficient5">
              <el-input v-model="configForm.MlhgCoefficient5" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="gameid==808">
            <el-form-item label="中奖概率下调上限(负值时)" prop="MlhgCoefficient6">
              <el-input v-model="configForm.MlhgCoefficient6" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"></el-input>
            </el-form-item>
          </el-col>


          <el-col :span="12" v-if="gameid==810">
            <el-form-item label="中奖概率上调上限(正值时)" prop="SgpdCoefficient5">
              <el-input v-model="configForm.SgpdCoefficient5" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="gameid==810">
            <el-form-item label="中奖概率下调上限(负值时)" prop="SgpdCoefficient6">
              <el-input v-model="configForm.SgpdCoefficient6" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"></el-input>
            </el-form-item>
          </el-col>


        </el-row>
        <el-row type="flex">
          <el-col :span="12" v-if="gameid==888">
            <el-form-item label="玩家并发系数" prop="FxgzCoefficient7">
              <el-input v-model="configForm.FxgzCoefficient7" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,1})?).*$/g, '$1')"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12" v-if="gameid==910">
            <el-form-item label="和初始权重值" prop="bjlopt3">
              <el-input v-model.number="configForm.bjlopt3"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="gameid==920">
            <el-form-item label="和初始权重值" prop="lhdopt3">
              <el-input v-model.number="configForm.lhdopt3"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12" v-if="gameid==806">
            <el-form-item label="允许赢取奖池的最大比例" prop="BszCoefficient7">
              <el-input v-model="configForm.BszCoefficient7" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="gameid==806">
            <el-form-item label="更换赔率玩法权重表的时间间隔(秒)" prop="BszCoefficient8">
              <el-input v-model="configForm.BszCoefficient8" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12" v-if="gameid==808">
            <el-form-item label="允许赢取奖池的最大比例" prop="MlhgCoefficient7">
              <el-input v-model="configForm.MlhgCoefficient7" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="gameid==808">
            <el-form-item label="更换赔率玩法权重表的时间间隔(秒)" prop="MlhgCoefficient8">
              <el-input v-model="configForm.MlhgCoefficient8" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12" v-if="gameid==810">
            <el-form-item label="允许赢取奖池的最大比例" prop="SgpdCoefficient7">
              <el-input v-model="configForm.SgpdCoefficient7" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="gameid==810">
            <el-form-item label="更换赔率玩法权重表的时间间隔(秒)" prop="SgpdCoefficient8">
              <el-input v-model="configForm.SgpdCoefficient8" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"></el-input>
            </el-form-item>
          </el-col>


        </el-row>
        <el-row type="flex">
          <el-col :span="12" v-if="gameid==910">
            <el-form-item label="庄对初始概率值" prop="opt_pair1">
              <el-input v-model="configForm.opt_pair1"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="gameid==910">
            <el-form-item label="闲对初始概率值" prop="opt_pair2">
              <el-input v-model="configForm.opt_pair2"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
      <!-- 按钮区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="success" @click="configList">提交</el-button>
        <el-button type="primary" @click="configDialogVisible = false">取消</el-button>
      </span>
    </el-dialog>

    <!-- 机器人设置 -->
    <el-dialog
      :title="'机器人设置:'+this.gamename+'('+this.gameModeName+')'"
      style="text-align:left !important; font-weight: 600;border-bottom:1px solid !important;"
      :visible.sync="bootDialogVisible"
      @close="bootDialogClosed"
      :close-on-click-modal="false"
    >
      <!-- 内容部分 -->
      <el-form
        ref="bootFormRef"
        :model="bootForm"
        :rules="bootFormRules"
        label-position="left"
        :inline="true"
        label-width="120px"
      >
        <el-row type="flex">
          <el-col>
            <h2 style="border-top:1px solid #000;padding:20px 0;color:red">已有</h2>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="9">
            <el-form-item label="机器人个数:" prop="num">
              <span>{{bootForm.num}}</span>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="机器人批次数量:" prop="count">
              <span>{{bootForm.count}}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="3">
            <h2 style="border-top:1px solid #000;padding:15px 0;color:red">新增</h2>
          </el-col>
          <el-col>
            <h2 style="border-top:1px solid #000;padding:15px 0;color:red">( 机器人个数 / 批次数量 >=100 )</h2>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="8">
            <el-form-item label="机器人个数" class="label" prop="robotNum">
              <el-input v-model="bootForm.robotNum"></el-input>
            </el-form-item>
          </el-col>
          <el-row type="flex">
            <el-col style="width:170px; line-height:40px">机器人ID号</el-col>
            <el-col :span="8">
              <el-form-item prop="guid_start">
                <el-input v-model="bootForm.guid_start" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="2" style="line-height:40px;margin-right: 5px;">到</el-col>
            <el-col>
              <el-form-item prop="bootsum">
                <el-input v-model="bootsum" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-row>
        <el-row type="flex">
          <el-col :span="8">
            <el-form-item label="批次数量" prop="batchNum">
              <el-input v-model="bootForm.batchNum"></el-input>
            </el-form-item>
          </el-col>
          <el-row type="flex">
            <el-col style="width:170px; line-height:40px">批次ID号</el-col>
            <el-col :span="8">
              <el-form-item prop="batchid">
                <el-input v-model="bootForm.batchid" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="2" style="line-height:40px;margin-right: 5px;">到</el-col>
            <el-col>
              <el-form-item prop="num">
                <el-input v-model="num" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-row>
      </el-form>
      <!-- 按钮区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="success" @click="bootList">提交</el-button>
        <el-button type="primary" @click="bootDialogVisible = false">取消</el-button>
      </span>
    </el-dialog>
  </div>
  </el-card>
</template>

<script>
import { Encrypt, Decrypt } from "../../assets/js/utils";
import vueJsonEditor from 'vue-json-editor'

export default {
  data() {
    return {
      loading: true,
      title: "",
      searchForm: {
        gameid: ""
      },
      searchForm_localStorage:"searchForm_localStorage",
      areaid: "",
      gameid: "",
      levelname: "",
      gamemode: "",
      gamename: "",
      gameModeName: "",
      gamelevel: "",
      GameConfig: [],
      list1: [],
      list2: [],
      list3: [],
      list4: [],
      queryInfo: {
        pagenum: 20,
        page: 1
      },
      total: 0,
      addDialogVisible: false,
      editDialogVisible: false,
      configDialogVisible: false,
      bootDialogVisible: false,
      addForm: {
        gameid: "",
        gamename: "",
        areaid: "",
        is_open: "",
        local_name: "",
        code: "",
        cost: "",
        base_score: "",
        limit_min: "",
        limit_max: "",
        tax: "",
        tax_open: "",
        tax_mode: "",
        platform: "",
        xml_config: "",
        modename: "",
        levelname: "",
        NameList: {},
        list1: [],
        list2: []
      },
      code: "",
      // 添加验证表单对象
      addFormRules: {
        grade: [{ required: true, message: "请输入等级", trigger: "blur" }],
        areaid: [{ required: true, message: "请输入编号", trigger: "blur" }],
        is_open: [
          { required: true, message: "请选择是否开放", trigger: "blur" }
        ],
        local_name: [
          { required: true, message: "请输入本地资源名称", trigger: "blur" }
        ],
        cost: [
          { required: true, message: "请输入房费", trigger: "blur" },
          // { type: "float", message: "必须为数字或小数" }
        ],
        base_score: [
          { required: true, message: "请填写底分", trigger: "blur" },
          // { type: "float", message: "必须为数字或小数" }
        ],
        limit_min: [
          { required: true, message: "(0表示没有限制)", trigger: "blur" },
          // { type: "float", message: "必须为数字或小数" }
        ],
        limit_max: [
          { required: true, message: "(0表示无限大)", trigger: "blur" },
          // { type: "float", message: "必须为数字或小数" }
        ],
        tax: [
          { required: true, message: "请填写数字", trigger: "blur" },
          { type: "number", message: "必须为数字" }
        ],
        // tax_open: [
        //   { required: true, message: "请填写数字", trigger: "blur" },
        //   { type: "number", message: "必须为数字" }
        // ],
        tax_mode: [
          { required: true, message: "请填写模式", trigger: "blur" },
          { type: "number", message: "必须为数字" }
        ],
        platform: [{ required: true, message: "请填写平台", trigger: "blur" }],
        xml_config: [{ required: true, message: "请填写配置", trigger: "blur" }]
      },
      // 获取游戏名称
      NameList: {},
      // 编辑表单
      editForm: {
        gameid: "",
        gamename: "",
        modename: "",
        levelname: "",
        areaid: "",
        is_open: "",
        local_name: "",
        cost: "",
        base_score: "",
        limit_min: "",
        limit_max: "",
        tax: "",
        tax_open: "",
        tax_mode: "",
        platform: "",
        code: "",
        xml_config: ""
      },
      editloading:false,
      edit_do:true,
      // 添加编辑表单对象
      editFormRules: {
        is_open: [
          { required: true, message: "请选择是否开放", trigger: "blur" }
        ],
        local_name: [
          { required: true, message: "请输入本地资源名称", trigger: "blur" }
        ],
        cost: [
          { required: true, message: "请输入房费", trigger: "blur" },
          // { type: "float", message: "必须为数字或小数" }
        ],
        base_score: [
          { required: true, message: "请填写底分", trigger: "blur" },
          // { type: "float", message: "必须为数字或小数" }
        ],
        limit_min: [
          { required: true, message: "(0表示没有限制)", trigger: "blur" },
          // { type: "float", message: "必须为数字或小数" }
        ],
        limit_max: [
          { required: true, message: "(0表示无限大)", trigger: "blur" },
          // { type: "float", message: "必须为数字或小数" }
        ],
        tax: [{ required: true, message: "请填写数字", trigger: "blur" }],
        tax_open: [{ required: true, message: "请选择", trigger: "blur" }],
        tax_mode: [{ required: true, message: "请填写模式", trigger: "blur" }],
        platform: [{ required: true, message: "请填写平台", trigger: "blur" }],
        xml_config: [
          {
            required: true,
            message: "请填写配置必须为JSON格式",
            trigger: "blur"
          }
        ]
      },
      // 风控配置表单
      configForm: {
        areaid: "",
        yxrpr: "",
        yxspr: "",
        yxa1: "",
        yxa2: "",
        yxb1: "",
        yxb2: "",
        yx_start_round: "",
        yx_start_bill: "",
        //抢庄牛牛
        QznnCoefficient1: "",
        QznnCoefficient2: "",
        QznnCoefficient3: "",
        //百人牛牛
        BrnnCoefficient1: "",
        BrnnCoefficient2: "",
        //百家乐
        BjlCoefficient1: "",
        BjlCoefficient2: "",
        BjlCoefficient3: "",
        BjlCoefficient4: "",
        BjlCoefficient5: "",
        bjlopt1: "",
        bjlopt2: "",
        bjlopt3: "",
        opt_pair1: "",
        opt_pair2: "",

        //龙虎斗
        LhdCoefficient1: "",
        LhdCoefficient2: "",
        LhdCoefficient3: "",
        lhdopt1: "",
        lhdopt2: "",
        lhdopt3: "",

        //二八杠
        EbgCoefficient1:"",
        EbgCoefficient2:"",
        EbgCoefficient3:"",
        //十三水
        SssCoefficient1:"",
        SssCoefficient2:"",
        SssCoefficient3:"",
        //650 通比牛牛
        TbnnCoefficient1:"",
        TbnnCoefficient2:"",
        //红黑大战
        HhdzCoefficient1:"",
        HhdzCoefficient2:"",
        //630 炸金花
        ZjhCoefficient1:"",
        ZjhCoefficient2:"",
        ZjhCoefficient3:"",
        ZjhCoefficient4:"",
        //福星高照
        FxgzCoefficient1:"",
        FxgzCoefficient2:"",
        FxgzCoefficient3:"",
        FxgzCoefficient4:"",
        FxgzCoefficient5:"",
        FxgzCoefficient6:"",
        FxgzCoefficient7:"",
        //二十一点
        EsydCoefficient1:"",
        EsydCoefficient2:"",

        //捕鱼
        ByCoefficient1:"",
        ByCoefficient2:"",
        ByCoefficient3:"",
        ByCoefficient4:"",

        //财神
        CsCoefficient1: "",
        CsCoefficient2: "",
        CsCoefficient3: "",
        CsCoefficient4: "",
        CsCoefficient5: "",

        //690 红包扫雷
        HbslCoefficient1:"",
        HbslCoefficient2:"",

        //710 看牌抢庄牛牛
        KpqznnCoefficient1:"",
        KpqznnCoefficient2:"",

        //886 赏金船长
        SjczCoefficient1:"",
        SjczCoefficient2:"",
        SjczCoefficient3:"",

        //802 舞狮进宝
        WsjbCoefficient1:"",
        WsjbCoefficient2:"",
        WsjbCoefficient3:"",
        WsjbCoefficient4:"",
        WsjbCoefficient5:"",

        //白蛇传
        BszCoefficient1:"",
        BszCoefficient2:"",
        BszCoefficient3:"",
        BszCoefficient4:"",
        BszCoefficient5:"",
        BszCoefficient6:"",
        BszCoefficient7:"",
        BszCoefficient8:"",

        //803 摇钱树
        YqsCoefficient1:"",
        YqsCoefficient2:"",
        YqsCoefficient3:"",
        YqsCoefficient4:"",

        //808 麻辣火锅
        MlhgCoefficient1:"",
        MlhgCoefficient2:"",
        MlhgCoefficient3:"",
        MlhgCoefficient4:"",
        MlhgCoefficient5:"",
        MlhgCoefficient6:"",
        MlhgCoefficient7:"",
        MlhgCoefficient8:"",

        //810 水果玛丽
        SgpdCoefficient1:"",
        SgpdCoefficient2:"",
        SgpdCoefficient3:"",
        SgpdCoefficient4:"",
        SgpdCoefficient5:"",
        SgpdCoefficient6:"",
        SgpdCoefficient7:"",
        SgpdCoefficient8:"",
      },

      // 风控配置表单验证
      configFormRules: {
        yxrpr: [{ required: true, message: "请输入", trigger: "blur" }],
        yxspr: [{ required: true, message: "请输入", trigger: "blur" }],
        yxa1: [{ required: true, message: "请输入", trigger: "blur" }],
        yxa2: [{ required: true, message: "请输入", trigger: "blur" }],
        yxb1: [{ required: true, message: "请输入", trigger: "blur" }],
        yxb2: [{ required: true, message: "请输入", trigger: "blur" }],
        yx_start_round: [
          { required: true, message: "请输入", trigger: "blur" },
          { type: "number", message: "必须为数字" }
        ],
        yx_start_bill: [
          { required: true, message: "请输入", trigger: "blur" },
          { type: "number", message: "必须为数字" }
        ],

        //630 炸金花
        ZjhCoefficient1:[
          { required: true, message: "请输入", trigger: "blur" },
        ],
        ZjhCoefficient2:[
          { required: true, message: "请输入", trigger: "blur" },
        ],
        ZjhCoefficient3:[
          { required: true, message: "请输入", trigger: "blur" },
        ],
        ZjhCoefficient4:[
          { required: true, message: "请输入", trigger: "blur" },
        ],

        // 650 通比牛牛
        TbnnCoefficient1:[{ required: true, message: "请输入", trigger: "blur" },],
        TbnnCoefficient2:[{ required: true, message: "请输入", trigger: "blur" },],

        //660 十三水
        SssCoefficient1:[{ required: true, message: "请输入", trigger: "blur" },],
        SssCoefficient2:[{ required: true, message: "请输入", trigger: "blur" },],
        SssCoefficient3:[{ required: true, message: "请输入", trigger: "blur" },],

        //抢庄牛牛
        QznnCoefficient1: [
          { required: true, message: "请输入", trigger: "blur" }
        ],
        QznnCoefficient2: [
          { required: true, message: "请输入", trigger: "blur" }
        ],
        QznnCoefficient3: [
          { required: true, message: "请输入", trigger: "blur" }
        ],

        //百人牛牛
        BrnnCoefficient1: [
          { required: true, message: "请输入", trigger: "blur" }
        ],
        BrnnCoefficient2: [
          { required: true, message: "请输入", trigger: "blur" }
        ],

        //红黑大战
        HhdzCoefficient1:[
          { required: true, message: "请输入", trigger: "blur" }
        ],
        HhdzCoefficient2:[
          { required: true, message: "请输入", trigger: "blur" }
        ],

        //二八杠
        EbgCoefficient1:[
          { required: true, message: "请输入", trigger: "blur" }
        ],
        EbgCoefficient2:[
          { required: true, message: "请输入", trigger: "blur" }
        ],
        EbgCoefficient3:[
          { required: true, message: "请输入", trigger: "blur" }
        ],

        //百家乐
        BjlCoefficient1: [
          { required: true, message: "请输入", trigger: "blur" }
        ],
        BjlCoefficient2: [
          { required: true, message: "请输入", trigger: "blur" }
        ],
        BjlCoefficient3: [
          { required: true, message: "请输入", trigger: "blur" }
        ],
        BjlCoefficient4: [
          { required: true, message: "请输入", trigger: "blur" }
        ],
        BjlCoefficient5: [
          { required: true, message: "请输入", trigger: "blur" }
        ],
        opt_pair1: [{ required: true, message: "请输入", trigger: "blur" }],
        opt_pair2: [{ required: true, message: "请输入", trigger: "blur" }],
        bjlopt1: [
          { required: true, message: "请输入", trigger: "blur" },
          { type: "number", message: "必须为数字" }
        ],
        bjlopt2: [
          { required: true, message: "请输入", trigger: "blur" },
          { type: "number", message: "必须为数字" }
        ],
        bjlopt3: [
          { required: true, message: "请输入", trigger: "blur" },
          { type: "number", message: "必须为数字" }
        ],


        //龙虎斗
        LhdCoefficient1: [
          { required: true, message: "请输入", trigger: "blur" }
        ],
        LhdCoefficient2: [
          { required: true, message: "请输入", trigger: "blur" }
        ],
        LhdCoefficient3: [
          { required: true, message: "请输入", trigger: "blur" }
        ],
        lhdopt1: [
          { required: true, message: "请输入", trigger: "blur" },
          { type: "number", message: "必须为数字" }
        ],
        lhdopt2: [
          { required: true, message: "请输入", trigger: "blur" },
          { type: "number", message: "必须为数字" }
        ],
        lhdopt3: [
          { required: true, message: "请输入", trigger: "blur" },
          { type: "number", message: "必须为数字" }
        ],

        //福星高照
        FxgzCoefficient1:[{ required: true, message: "请输入", trigger: "blur" },],
        FxgzCoefficient2:[{ required: true, message: "请输入", trigger: "blur" },],
        FxgzCoefficient3:[{ required: true, message: "请输入", trigger: "blur" },],
        FxgzCoefficient4:[{ required: true, message: "请输入", trigger: "blur" },],
        FxgzCoefficient5:[{ required: true, message: "请输入", trigger: "blur" },],
        FxgzCoefficient6:[{ required: true, message: "请输入", trigger: "blur" },],
        FxgzCoefficient7:[{ required: true, message: "请输入", trigger: "blur" },],

        //二十一点
        EsydCoefficient1:[{ required: true, message: "请输入", trigger: "blur" },],


        //捕鱼
        ByCoefficient1:[{ required: true, message: "请输入", trigger: "blur" },],
        ByCoefficient2:[{ required: true, message: "请输入", trigger: "blur" },],
        ByCoefficient3:[{ required: true, message: "请输入", trigger: "blur" },],
        ByCoefficient4:[{ required: true, message: "请输入", trigger: "blur" },],

        //财神
        CsCoefficient1: [{ required: true, message: "请输入", trigger: "blur" },],
        CsCoefficient2: [{ required: true, message: "请输入", trigger: "blur" },],
        CsCoefficient3: [{ required: true, message: "请输入", trigger: "blur" },],
        CsCoefficient4: [{ required: true, message: "请输入", trigger: "blur" },],
        CsCoefficient5: [{ required: true, message: "请输入", trigger: "blur" },],


        //690 红包扫雷
        HbslCoefficient1:[{ required: true, message: "请输入", trigger: "blur" },],
        HbslCoefficient2:[{ required: true, message: "请输入", trigger: "blur" },],

        //710 看牌抢庄牛牛
        KpqznnCoefficient1:[{ required: true, message: "请输入", trigger: "blur" },],
        KpqznnCoefficient2:[{ required: true, message: "请输入", trigger: "blur" },],

        //886 赏金船长
        SjczCoefficient1:[{ required: true, message: "请输入", trigger: "blur" },],
        SjczCoefficient2:[{ required: true, message: "请输入", trigger: "blur" },],
        SjczCoefficient3:[{ required: true, message: "请输入", trigger: "blur" },],

        //802 舞狮进宝
        WsjbCoefficient1:[{ required: true, message: "请输入", trigger: "blur" },],
        WsjbCoefficient2:[{ required: true, message: "请输入", trigger: "blur" },],
        WsjbCoefficient3:[{ required: true, message: "请输入", trigger: "blur" },],
        WsjbCoefficient4:[{ required: true, message: "请输入", trigger: "blur" },],
        WsjbCoefficient5:[{ required: true, message: "请输入", trigger: "blur" },],

        //806 白蛇传
        BszCoefficient1:[{ required: true, message: "请输入", trigger: "blur" },],
        BszCoefficient2:[{ required: true, message: "请输入", trigger: "blur" },],
        BszCoefficient3:[{ required: true, message: "请输入", trigger: "blur" },],
        BszCoefficient4:[{ required: true, message: "请输入", trigger: "blur" },],
        BszCoefficient5:[{ required: true, message: "请输入", trigger: "blur" },],
        BszCoefficient6:[{ required: true, message: "请输入", trigger: "blur" },],
        BszCoefficient7:[{ required: true, message: "请输入", trigger: "blur" },],
        BszCoefficient8:[{ required: true, message: "请输入", trigger: "blur" },],


        //803 摇钱树
        YqsCoefficient1:[{ required: true, message: "请输入", trigger: "blur" },],
        YqsCoefficient2:[{ required: true, message: "请输入", trigger: "blur" },],
        YqsCoefficient3:[{ required: true, message: "请输入", trigger: "blur" },],
        YqsCoefficient4:[{ required: true, message: "请输入", trigger: "blur" },],

        //808 麻辣火锅
        MlhgCoefficient1:[{ required: true, message: "请输入", trigger: "blur" },],
        MlhgCoefficient2:[{ required: true, message: "请输入", trigger: "blur" },],
        MlhgCoefficient3:[{ required: true, message: "请输入", trigger: "blur" },],
        MlhgCoefficient4:[{ required: true, message: "请输入", trigger: "blur" },],
        MlhgCoefficient5:[{ required: true, message: "请输入", trigger: "blur" },],
        MlhgCoefficient6:[{ required: true, message: "请输入", trigger: "blur" },],
        MlhgCoefficient7:[{ required: true, message: "请输入", trigger: "blur" },],
        MlhgCoefficient8:[{ required: true, message: "请输入", trigger: "blur" },],


        //810 水果玛丽
        SgpdCoefficient1:[{ required: true, message: "请输入", trigger: "blur" },],
        SgpdCoefficient2:[{ required: true, message: "请输入", trigger: "blur" },],
        SgpdCoefficient3:[{ required: true, message: "请输入", trigger: "blur" },],
        SgpdCoefficient4:[{ required: true, message: "请输入", trigger: "blur" },],
        SgpdCoefficient5:[{ required: true, message: "请输入", trigger: "blur" },],
        SgpdCoefficient6:[{ required: true, message: "请输入", trigger: "blur" },],
        SgpdCoefficient7:[{ required: true, message: "请输入", trigger: "blur" },],
        SgpdCoefficient8:[{ required: true, message: "请输入", trigger: "blur" },],

      },
      // 机器人设置表单
      bootForm: {},
      // 机器人设表单验证
      bootFormRules: {
        batchNum: [{ required: true, message: "请填写数量" }],
        robotNum: [{ required: true, message: "请填写个数" }]
      }
    };
  },
  components: {
    vueJsonEditor
  },
  mounted() {
    // 配置列表(表格)
    this.getConfigList();
    this.getlist3();
    // this.getlist4();
    this.getNameList();
    // String.prototype.format = function(args) {
    //   var result = this;
    //   if (arguments.length > 0) {
    //     if (arguments.length == 1 && typeof args == "object") {
    //       for (var key in args) {
    //         if (args[key] != undefined) {
    //           var reg = new RegExp("\\{" + key + "\\}", "g");
    //           result = result.replace(reg, args[key]);
    //         }
    //       }
    //     } else {
    //       for (var i = 0; i < arguments.length; i++) {
    //         if (arguments[i] != undefined) {
    //           var reg = new RegExp("\\{" + i + "\\}", "g");
    //           result = result.replace(reg, arguments[i]);
    //         }
    //       }
    //     }
    //   }
    //   return result;
    // };
  },

  computed: {
    //  计算机器人设置
    bootsum() {
      if (this.bootForm.robotNum == undefined || this.bootForm.robotNum == "") {
        return "";
      } else {
        return (
            parseFloat(this.bootForm.robotNum - 1) +
            parseFloat(this.bootForm.guid_start)
        );
      }
    },
    num() {
      if (this.bootForm.batchNum == undefined || this.bootForm.batchNum == "") {
        return "";
      } else {
        return (
            parseFloat(this.bootForm.batchNum - 1) +
            parseFloat(this.bootForm.batchid)
        );
      }
    }
  },

  created(){

    this.keyupSubmit();
  },
  methods: {
    keyupSubmit(){
      document.onkeydown=e=>{
        let _key=window.event.keyCode;
        if(_key===13){
          this.searchClick();
        }
      }
    },

    yxrprblur(e) {
      var obj = e.target;
      var t = obj.value.charAt(0);
      obj.value = obj.value
              .replace(".", "$#$") //把第一个字符'.'替换成'$#$'
              .replace(/\./g, "") //把其余的字符'.'替换为空
              .replace("$#$", ".") //把字符'$#$'替换回原来的'.'
              .replace(/[^\d.]/g, "") //只能输入数字和'.'
              .replace(/^\./g, "") //不能以'.'开头
              .replace(/([0-9]+\.[0-9]{1})[0-9]*/, "$1"); //只保留2位小数
      if (t == "-") {
        obj.value = "-" + obj.value;
      }
      if (obj.value == "" || obj.value > 50 || obj.value < 0) {
        this.$message.error("游戏期望收益率值在0%-50%之间");
      }
    },
    yxsprblur(e) {
      var obj = e.target;
      var t = obj.value.charAt(0);
      obj.value = obj.value
              .replace(".", "$#$") //把第一个字符'.'替换成'$#$'
              .replace(/\./g, "") //把其余的字符'.'替换为空
              .replace("$#$", ".") //把字符'$#$'替换回原来的'.'
              .replace(/[^\d.]/g, "") //只能输入数字和'.'
              .replace(/^\./g, "") //不能以'.'开头
              .replace(/([0-9]+\.[0-9]{1})[0-9]*/, "$1"); //只保留2位小数
      if (t == "-") {
        obj.value = "-" + obj.value;
      }
      if (obj.value == "" || obj.value > 10 || obj.value < -10) {
        this.$message.error("游戏保底收益率值在-10%-10%之间");
      }
    },
    yxa1blur(e) {
      var obj = e.target;
      var t = obj.value.charAt(0);
      obj.value = obj.value
              .replace(".", "$#$") //把第一个字符'.'替换成'$#$'
              .replace(/\./g, "") //把其余的字符'.'替换为空
              .replace("$#$", ".") //把字符'$#$'替换回原来的'.'
              .replace(/[^\d.]/g, "") //只能输入数字和'.'
              .replace(/^\./g, "") //不能以'.'开头
              .replace(/([0-9]+\.[0-9]{1})[0-9]*/, "$1"); //只保留2位小数
      if (t == "-") {
        obj.value = "-" + obj.value;
      }
      if (obj.value == "" || obj.value > 100 || obj.value < 0) {
        this.$message.error("游戏风控参数a1值在0-100之间");
      }
    },
    yxa2blur(e) {
      var obj = e.target;
      var t = obj.value.charAt(0);
      obj.value = obj.value
              .replace(".", "$#$") //把第一个字符'.'替换成'$#$'
              .replace(/\./g, "") //把其余的字符'.'替换为空
              .replace("$#$", ".") //把字符'$#$'替换回原来的'.'
              .replace(/[^\d.]/g, "") //只能输入数字和'.'
              .replace(/^\./g, "") //不能以'.'开头
              .replace(/([0-9]+\.[0-9]{1})[0-9]*/, "$1"); //只保留2位小数
      if (t == "-") {
        obj.value = "-" + obj.value;
      }
      if (obj.value == "" || obj.value > 5 || obj.value < 0) {
        this.$message.error("游戏风控参数a2值在0-5之间");
      }
    },
    yxb1blur(e) {
      var obj = e.target;
      var t = obj.value.charAt(0);
      obj.value = obj.value
              .replace(".", "$#$") //把第一个字符'.'替换成'$#$'
              .replace(/\./g, "") //把其余的字符'.'替换为空
              .replace("$#$", ".") //把字符'$#$'替换回原来的'.'
              .replace(/[^\d.]/g, "") //只能输入数字和'.'
              .replace(/^\./g, "") //不能以'.'开头
              .replace(/([0-9]+\.[0-9]{1})[0-9]*/, "$1"); //只保留2位小数
      if (t == "-") {
        obj.value = "-" + obj.value;
      }
      if (obj.value == "" || obj.value > 100 || obj.value < 0) {
        this.$message.error("游戏风控参数b1值在0-100之间");
      }
    },

    //630 炸金花风控
    ZjhCoefficient1(e){
      var val = e.target.value;
      if (val == "" || val > 5 || val < 0) {
        this.$message.error("整体调控控制力度值在0-5之间");
      }
    },
    ZjhCoefficient2(e) {
      var val = e.target.value;
      if (val == "" || val > 5 || val < 0) {
        this.$message.error("整体调控控制力度值在0-5之间");
      }
    },
    ZjhCoefficient3(e) {
      var val = e.target.value;
      if (val == "" || val > 1 || val < 0) {
        this.$message.error("整体调控控制力度值在0-1之间");
      }
    },
    ZjhCoefficient4(e) {
      var val = e.target.value;
      if (val == "" || val > 2 || val < 0) {
        this.$message.error("整体调控控制力度值在0-2之间");
      }
    },

     //650 通比牛牛
    TbnnCoefficient1(){
      var val = e.target.value;
      if (val == "" || val > 1 || val < 10) {
        this.$message.error("风控系数为1时的权重倍数值在1-10之间");
      }
    },
    TbnnCoefficient2(){
      var val = e.target.value;
      if (val == "" || val > 1 || val < 0) {
        this.$message.error("风控系数为-1时的权重倍数值在0-1之间");
      }
    },

    //十三水
    SssCoefficient1(){
      var val = e.target.value;
      if (val == "" || val > 5 || val < 0) {
        this.$message.error("整体调控控制力度数值在0-5之间");
      }
    },
    SssCoefficient2(){
      var val = e.target.value;
      if (val == "" || val > 5 || val < 0) {
        this.$message.error("调控风控系数和胜率对应关系数值在0-5之间");
      }
    },
    SssCoefficient3(){
      var val = e.target.value;
      if (val == "" || val > 1 || val < 0) {
        this.$message.error("调控顺位偏离控制力度数值在0-1之间");
      }
    },
    //红黑大战
    HhdzCoefficient1(){
      var val = e.target.value;
      if (val == "" || val > 5 || val < 0) {
        this.$message.error("调控风控系数和胜率关系数值在0-5之间");
      }
    },
    HhdzCoefficient2(){
      var val = e.target.value;
      if (val == "" || val > 5 || val < 0) {
        this.$message.error("调控风控系数和胜率关系数值在0-5之间");
      }
    },

    //二八杠
    EbgCoefficient1(){
      var val = e.target.value;
      if (val == "" || val > 5 || val < 0) {
        this.$message.error("整体调控控制力度数值在0-5之间");
      }
    },
    EbgCoefficient2(){
      var val = e.target.value;
      if (val == "" || val > 5 || val < 0) {
        this.$message.error("调控风控系数和胜率对应关系数值在0-5之间");
      }
    },
    EbgCoefficient3(){
      var val = e.target.value;
      if (val == "" || val > 1 || val < 0) {
        this.$message.error("调控顺位偏离控制力度数值在0-1之间");
      }
    },



    yxb2blur(e) {
      var obj = e.target;
      var t = obj.value.charAt(0);
      obj.value = obj.value
              .replace(".", "$#$") //把第一个字符'.'替换成'$#$'
              .replace(/\./g, "") //把其余的字符'.'替换为空
              .replace("$#$", ".") //把字符'$#$'替换回原来的'.'
              .replace(/[^\d.]/g, "") //只能输入数字和'.'
              .replace(/^\./g, "") //不能以'.'开头
              .replace(/([0-9]+\.[0-9]{1})[0-9]*/, "$1"); //只保留2位小数
      if (t == "-") {
        obj.value = "-" + obj.value;
      }
      if (obj.value == "" || obj.value > 5 || obj.value < 0) {
        this.$message.error("游戏风控参数b2值在0-5之间");
      }
    },
    yx_start_roundblur(e) {
      var val = e.target.value;
      if (val == "" || val > 100 || val < 0) {
        this.$message.error("游戏风控启动有效局数在0-100之间");
      }
    },
    yx_start_billblur(e) {
      var val = e.target.value;
      if (val == "" || val > 10000000 || val < 0) {
        this.$message.error("游戏风控启动有效流水值在0-10000000之间");
      }
    },

    //抢庄牛牛
    QznnCoefficient1(e) {
      var val = e.target.value;
      if (val == "" || val > 5 || val < 0) {
        this.$message.error("整体调控控制力度值在0-5之间");
      }
    },
    QznnCoefficient2(e) {
      var val = e.target.value;
      if (val == "" || val > 5 || val < 0) {
        this.$message.error("调控风控系数和胜率对应关系在0-5之间");
      }
    },
    QznnCoefficient3(e) {
      var val = e.target.value;
      if (val == "" || val > 1 || val < 0) {
        this.$message.error("调控顺位偏离控制力度在0-1之间");
      }
    },
    //百人牛牛
    BrnnCoefficient1(e) {
      var obj = e.target;
      var t = obj.value.charAt(0);
      obj.value = obj.value
              .replace(".", "$#$") //把第一个字符'.'替换成'$#$'
              .replace(/\./g, "") //把其余的字符'.'替换为空
              .replace("$#$", ".") //把字符'$#$'替换回原来的'.'
              .replace(/[^\d.]/g, "") //只能输入数字和'.'
              .replace(/^\./g, "") //不能以'.'开头
              .replace(/([0-9]+\.[0-9]{1})[0-9]*/, "$1"); //只保留2位小数
      if (t == "-") {
        obj.value = "-" + obj.value;
      }
      if (obj.value == "" || obj.value > 5 || obj.value < 0) {
        this.$message.error("调控风控系数和胜率关系(正值时)值在0-5之间");
      }
    },
    BrnnCoefficient2(e) {
      var obj = e.target;
      var t = obj.value.charAt(0);
      obj.value = obj.value
              .replace(".", "$#$") //把第一个字符'.'替换成'$#$'
              .replace(/\./g, "") //把其余的字符'.'替换为空
              .replace("$#$", ".") //把字符'$#$'替换回原来的'.'
              .replace(/[^\d.]/g, "") //只能输入数字和'.'
              .replace(/^\./g, "") //不能以'.'开头
              .replace(/([0-9]+\.[0-9]{1})[0-9]*/, "$1"); //只保留2位小数
      if (t == "-") {
        obj.value = "-" + obj.value;
      }
      if (obj.value == "" || obj.value > 5 || obj.value < 0) {
        this.$message.error("调控风控系数和胜率关系(负值时)值在0-5之间");
      }
    },

    //二十一点
    EsydCoefficient1(e){
      var val = e.target.value;
      if (val == "" || val > 5 || val < 0) {
        this.$message.error("调控风控系数和胜率关系（正值时）值在0-5之间");
      }
    },
    EsydCoefficient2(e){
      var val = e.target.value;
      if (val == "" || val > 5 || val < 0) {
        this.$message.error("调控风控系数和胜率关系（负值时）值在0-5之间");
      }
    },

    //福星高照
    FxgzCoefficient1(e){
      var val = e.target.value;
      if (val == "" || val > 5 || val < 0) {
        this.$message.error("调控风控系数和胜率关系（正值时）值在0-5之间");
      }
    },
    FxgzCoefficient2(e){
      var val = e.target.value;
      if (val == "" || val > 5 || val < 0) {
        this.$message.error("调控风控系数和胜率关系（负值时）值在0-5之间");
      }
    },
    FxgzCoefficient3(e){
      var val = e.target.value;
      if (val == "" || val > 5 || val < 1) {
        this.$message.error("必送时对巨奖命中几率的提升倍率值在1-5之间");
      }
    },
    FxgzCoefficient4(e){
      var val = e.target.value;
      if (val == "" || val > 5 || val < 1) {
        this.$message.error("必送时对免费游戏触发几率的提升倍率值在1-5之间");
      }
    },
    FxgzCoefficient5(e){
      var val = e.target.value;
      if (val == "" || val > 5 || val < 1) {
        this.$message.error("必送时对Jackpot触发几率的提升倍率值在1-5之间");
      }
    },
    FxgzCoefficient6(e){
      var val = e.target.value;
      if (val == "" || val > 100 || val < 0) {
        this.$message.error("免费游戏上限保底金额的倍数值在0-100之间");
      }
    },
    FxgzCoefficient7(e){
      var val = e.target.value
              .replace(".", "$#$") //把第一个字符'.'替换成'$#$'
              .replace(/\./g, "") //把其余的字符'.'替换为空
              .replace("$#$", ".") //把字符'$#$'替换回原来的'.'
              .replace(/[^\d.]/g, "") //只能输入数字和'.'
              .replace(/^\./g, "") //不能以'.'开头
              .replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1'); //只保留2位小数

      if (val == "" || val <= 1) {
        this.$message.error("玩家并发系数值必须大于等于1");
      }
    },


    //财神
    CsCoefficient1(e){
      var val = e.target.value;
      if (val == "" || val > 5 || val < 0) {
        this.$message.error("调控风控系数和胜率关系（正值时）值在0-5之间");
      }
    },
    CsCoefficient2(e){
      var val = e.target.value;
      if (val == "" || val > 5 || val < 0) {
        this.$message.error("调控风控系数和胜率关系（负值时）值在0-5之间");
      }
    },
    CsCoefficient3(e){
      var val = e.target.value;
      if (val == "" || val > 5 || val < 1) {
        this.$message.error("必送时中奖类型2权重的提升倍率值在1-5之间");
      }
    },
    CsCoefficient4(e){
      var val = e.target.value;
      if (val == "" || val > 5 || val < 1) {
        this.$message.error("必送时中奖类型3权重的提升倍率值在1-5之间");
      }
    },
    CsCoefficient5(e){
      var val = e.target.value
              .replace(".", "$#$") //把第一个字符'.'替换成'$#$'
              .replace(/\./g, "") //把其余的字符'.'替换为空
              .replace("$#$", ".") //把字符'$#$'替换回原来的'.'
              .replace(/[^\d.]/g, "") //只能输入数字和'.'
              .replace(/^\./g, "") //不能以'.'开头
              .replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1'); //只保留2位小数

      if (val == "" || val <= 1) {
        this.$message.error("玩家并发系数值必须大于等于1");
      }
    },


    //百人牛牛
    BjlCoefficient1(e) {
      var val = e.target.value;
      if (val == "" || val > 5 || val < 0) {
        this.$message.error("调控风控系数和胜率关系(正值时)值在0-5之间");
      }
    },
    BjlCoefficient2(e) {
      var val = e.target.value;
      if (val == "" || val > 5 || val < 0) {
        this.$message.error("调控风控系数和胜率关系(负值时)值在0-5之间");
      }
    },
    BjlCoefficient3(e) {
      var val = e.target.value;
      if (val == "" || val > 10 || val < 1) {
        this.$message.error("仅可开“和”时的“和”概率缩放系数值在1-10之间");
      }
    },
    BjlCoefficient4(e) {
      var val = e.target.value;
      if (val == "" || val > 1 || val < 0) {
        this.$message.error("必杀时对子的概率缩放系数值在0-1之间");
      }
    },
    BjlCoefficient5(e) {
      var val = e.target.value;
      if (val == "" || val > 10 || val < 1) {
        this.$message.error("必送时对子的概率缩放系数值在1-10之间");
      }
    },

    //捕鱼
    ByCoefficient1(e){
      var val = e.target.value;
      if (val == "" || val > 3 || val < 0) {
        this.$message.error("捕鱼风控计算参数1值在0-3之间");
      }
    },
    ByCoefficient2(e){
      var val = e.target.value;
      if (val == "" || val > 5 || val < 1) {
        this.$message.error("捕鱼风控计算参数2值在1-5之间");
      }
    },
    ByCoefficient3(e){
      var val = e.target.value;
      if (val == "" || val > 1 || val < 0) {
        this.$message.error("捕鱼风控计算参数3值在0-1之间");
      }
    },
    ByCoefficient4(e){
      var val = e.target.value;
      if (val == "" || val > 5 || val < 1) {
        this.$message.error("捕鱼风控计算参数4值在1-5之间");
      }
    },

    //龙虎斗
    LhdCoefficient1(e) {
      var val = e.target.value;
      if (val == "" || val > 5 || val < 0) {
        this.$message.error("调控风控系数和胜率关系(正值时)值在0-5");
      }
    },
    LhdCoefficient2(e) {
      var val = e.target.value;
      if (val == "" || val > 5 || val < 0) {
        this.$message.error("调控风控系数和胜率关系(负值时)值在0-5");
      }
    },
    LhdCoefficient3(e) {
      var val = e.target.value;
      if (val == "" || val > 10 || val < 0) {
        this.$message.error("仅可开“和”时的“和”概率缩放系数值在0-10");
      }
    },
    bjlopt1(e) {
      var val = e.target.value;
      if (val == "" || val > 1000000 || val < 1) {
        this.$message.error("庄初始权重值在1-1000000之间");
      }
    },
    bjlopt2(e) {
      var val = e.target.value;
      if (val == "" || val > 1000000 || val < 1) {
        this.$message.error("闲初始权重值在1-1000000之间");
      }
    },
    bjlopt3(e) {
      var val = e.target.value;
      if (val == "" || val > 1000000 || val < 1) {
        this.$message.error("和初始权重值在1-1000000之间");
      }
    },


    lhdopt1(e) {
      var val = e.target.value;
      if (val == "" || val > 1000000 || val < 1) {
        this.$message.error("龙初始权重值在1-1000000之间");
      }
    },
    lhdopt2(e) {
      var val = e.target.value;
      if (val == "" || val > 1000000 || val < 1) {
        this.$message.error("虎初始权重值在1-1000000之间");
      }
    },
    lhdopt3(e) {
      var val = e.target.value;
      if (val == "" || val > 1000000 || val < 1) {
        this.$message.error("和初始权重值在1-1000000之间");
      }
    },
    opt_pair1(e) {
      var obj = e.target;
      var t = obj.value.charAt(0);
      obj.value = obj.value
              .replace(".", "$#$") //把第一个字符'.'替换成'$#$'
              .replace(/\./g, "") //把其余的字符'.'替换为空
              .replace("$#$", ".") //把字符'$#$'替换回原来的'.'
              .replace(/[^\d.]/g, "") //只能输入数字和'.'
              .replace(/^\./g, "") //不能以'.'开头
              .replace(/([0-9]+\.[0-9]{20})[0-9]*/, "$1"); //只保留2位小数
      if (t == "-") {
        obj.value = "-" + obj.value;
      }
      if (obj.value == "" || obj.value > 20 || obj.value < 0) {
        this.$message.error("庄对初始概率值在0%-20%之间");
      }
    },
    opt_pair2(e) {
      var obj = e.target;
      var t = obj.value.charAt(0);
      obj.value = obj.value
              .replace(".", "$#$") //把第一个字符'.'替换成'$#$'
              .replace(/\./g, "") //把其余的字符'.'替换为空
              .replace("$#$", ".") //把字符'$#$'替换回原来的'.'
              .replace(/[^\d.]/g, "") //只能输入数字和'.'
              .replace(/^\./g, "") //不能以'.'开头
              .replace(/([0-9]+\.[0-9]{20})[0-9]*/, "$1"); //只保留2位小数
      if (t == "-") {
        obj.value = "-" + obj.value;
      }
      if (obj.value == "" || obj.value > 20 || obj.value < 0) {
        this.$message.error("闲对初始概率值在在0-20之间");
      }
    },
    // 分页器
    // 监听pageName改变的事件
    handleSizeChange(pagenum) {
      this.loading = false;
      this.queryInfo.pagenum = pagenum;
      this.getConfigList();
      this.loading = true;
    },
    // 监听页码值改变的事件
    handleCurrentChange(newPage) {
      this.loading = false;
      this.queryInfo.page = newPage;
      this.getConfigList();
      this.loading = true;
    },

    // 监听添加列表的对话框关闭事件
    addDialogClosed() {
      this.$refs.addFormRef.resetFields();
    },
    // 监听编辑列表对话框的关闭事件
    editDialogClosed() {
      this.$refs.editFormRef.resetFields();
    },
    // 监听风控设置对话框的关闭事件
    configDialogClosed() {
      this.$refs.configFormRef.resetFields();
    },
    // 监听机器人设置对话框的关闭事件
    bootDialogClosed() {
      this.$refs.bootFormRef.resetFields();
    },
    change(newPage) {
      this.loading = false;
      this.handleCurrentChange(newPage);
      this.loading = true;
    },
    // 房间列表
    jump(row) {
      this.$router.push({
        name: "RoomList",
        params: {
          areaid: row.areaid,
          gameid: row.gameid,
          gamelevel: row.gamelevel,
          gamemode: row.gamemode,
          gameLevelName: row.gameLevelName,
          gameModeName: row.gameModeName,
          gamename: row.gamename
        }
      });
    },

    // 获取表格列表
    getConfigList() {
      var gameid = localStorage.getItem(this.searchForm_localStorage) != null ? localStorage.getItem(this.searchForm_localStorage) :this.searchForm.gameid
      this.searchForm.gameid = gameid;
      var params = this.queryInfo;
        params.gameid = gameid;
        if(params.gameid != ""){
          params.page = 1;
        }
      let that = this;
      this.$Http({
        url: "/api/GameConfig/index",
        params,
        successful(res, data) {
          that.loading = false;
          that.GameConfig = data.data.data;
          that.total = data.data.total;
        }
      });
    },
    // 新增弹框
    addgamelist() {
      this.addDialogVisible = true;
      // this.getlist1();
      // this.getlist2();
    },
    // 点击添加按钮添加列表
    addList() {
      // // 获取游戏名称
      var params = {
        modename: this.addForm.mode,
        levelname: this.addForm.level,
        gameid: this.addForm.gameid,
        is_open: this.addForm.is_open,
        local_name: this.addForm.local_name,
        cost: this.addForm.cost,
        base_score: this.addForm.base_score,
        limit_min: this.addForm.limit_min,
        limit_max: this.addForm.limit_max,
        tax: this.addForm.tax,
        tax_open: this.addForm.tax_open,
        tax_mode: this.addForm.tax_mode,
        platform: this.addForm.platform,
        xml_config: this.addForm.xml_config,
        code: this.code
      };
      let that = this;
      this.$refs.addFormRef.validate(valid => {
        if (!valid) return;
        //  添加请求
        this.$Http({
          url: "/api/GameConfig/add",
          params,
          successful(res, data) {
            if (data.status == false) {
              that.$message.error(data.message);
            } else {
              if (data.code != 200) {
                that.$message.error(data.message);
                that.code = res.data.data.code;
              } else {
                that.addDialogVisible = false;
                that.getConfigList();
                that.$message.success("添加成功");
                that.addDialogClosed();
              }
            }
          }
        });
      });
    },
    // 获取游戏名称
    getNameList() {
      var params = this.queryInfo;
      // (this.queryInfo.pagenum=10000);
      let that = this;
      this.$Http({
        url: "/api/gamebase/gameList",
        params,
        successful(res, data) {
          that.NameList = data.data.data;
        }
      });
    },
    // 获取游戏等级
    getlist1() {
      var params = {
        gameid: this.gameid
      };
      let that = this;
      this.$Http({
        url: "/api/GameConfig/getLevel",
        params,
        successful(res, data) {
          that.addForm.list1 = data.data;
          that.addForm.list1.forEach((v, i) => {
            that.addForm.levelname = that.addForm.list1[i].levelname;
          });
        }
      });
    },
    // 获取游戏玩法
    getlist2() {
      var params = {
        gameid: this.gameid
      };
      let that = this;
      this.$Http({
        url: "/api/GameConfig/getMode",
        params,
        successful(res, data) {
          that.addForm.list2 = data.data;
          that.addForm.list2.forEach((v, i) => {
            that.addForm.modename = that.addForm.list2[i].modename;
          });
        }
      });
    },
    getlist3() {
      var params = {
        gameid: this.gameid,
        gamemode: this.gamemode,
        levelname: this.levelname
      };
      // console.log(params);
      let that = this;
      this.$Http({
        url: "/api/GameConfig/getMode",
        params,
        successful(res, data) {
          that.list3 = data.data[0];
          // console.log(that.list3);
        }
      });
    },
    getlist4() {
      var params = {
        gameid: this.gameid,
        modename: this.modename,
        levelname: this.levelname,
        gamelevel: this.gamelevel
      };
      let that = this;
      this.$Http({
        url: "/api/GameConfig/getLevel",
        params,
        successful(res, data) {
          that.list4 = data.data[0];
        }
      });
    },
    // 编辑按钮
    showEditDialog(row) {

      this.editDialogVisible = true;
      this.editForm = row;
      this.editForm.levelname = row.gamelevel;
      this.editForm.gamemode = row.gamemode;
      this.gameid = row.gameid;
      this.gamemode = row.gamemode;
      this.gamelevel = row.gamelevel;
      this.levelname = row.levelname;
      // this.modename = row.modename;
      this.getlist1();
      this.getlist2();
      this.getlist3();
      this.getlist4();
      var params = {
        areaid: row.areaid,
        gameid: row.gameid,
        gamelevel: row.gamelevel,
        code: row.code,
        cost: row.cost,
        base_score: row.base_score,
        limit_min: row.limit_min,
        limit_max: row.limit_max
      };
      let that = this;
      that.editloading = true;
      that.edit_do = true;
      var rate = 1;
      this.$Http({
        url: "/api/GameConfig/edit",
        params,
        successful(res, data) {
          if (data.status == false) {
            that.$message.error(data.message);
            that.editDialogVisible = false;
          } else {

            that.editForm = data.data;
            that.editForm.cost = data.data.cost/rate;
            that.editForm.base_score = data.data.base_score/rate;
            that.editForm.limit_min = data.data.limit_min/rate;
            that.editForm.limit_max = data.data.limit_max/rate;
            that.editForm.is_open = String(that.editForm.is_open);
            that.editForm.tax_open = String(that.editForm.tax_open);
            that.editForm.xml_config = JSON.parse(data.data.xml_config);
          }
          that.edit_do = false;
          that.editloading = false;
        }
      });
    },
    // 编辑并提交信息
    editList() {
      let that = this;
      this.editDialogVisible = true;
      var params = that.editForm;
      params.levelname = that.editForm.gamelevel;
      params.modename = that.editForm.gamemode;
      params.code = that.editForm.code;
      params.xml_config = JSON.stringify(that.editForm.xml_config, null, 4);
      this.$refs.editFormRef.validate(valid => {
        if (!valid) return;
        //  添加请求
        let that = this;
        this.$Http({
          url: "/api/GameConfig/editSave",
          params,
          successful(res, data) {
            if (data.status == false) {
              that.editDialogVisible = false;
              that.$message.error(data.message);
            } else {
              if (data.code != 200) {
                that.editDialogVisible = false;
                that.$message.error(data.message);
                params.code = data.code;
              } else {
                that.editDialogVisible = false;
                that.getConfigList();
                that.$message.success("修改成功");
              }
            }
          }
        });
      });
    },
    // 删除部分
    // 根据id删除信息
    removeUserById(row) {
      var params = { areaid: row.areaid };
      let that = this;
      // 根据弹框删除
      const confirmResult = this.$confirm(
        "此操作将删除该游戏，是否继续？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }
      )
        .then(() => {
          this.$Http({
            url: "/api/GameConfig/del",
            params,
            successful(res, data) {
              if (data.code != 200) {
                that.$message.error(data.message);
              } else {
                that.$message.success("删除成功");
                that.getConfigList();
              }
            }
          });
        })
        .catch(() => {
          that.$message.info("已取消删除");
        });
    },
    // 停用部分
    stopUserById(row, index) {
      let areaid = row.areaid;
      let is_open = row.is_open === 1 ? "停用" : "启用";
      var params = { areaid, is_open };
      let that = this;
      // 根据弹框停用
      const confirmResult = this.$confirm("提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        message: "此操作将" + is_open + "该游戏，是否继续？"
      })
        .then(() => {
          this.$Http({
            url: "/api/GameConfig/open",
            params,
            successful(res, data) {
              that.$message.success(is_open + "成功");
              that.getConfigList();
            }
          });
        })
        .catch(() => {
          that.$message.info("已取消" + is_open);
        });
    },
    indexSelect(event) {
      this.gameid = this.addForm.gameid = event;
      this.getlist1();
      this.getlist2();
    },
    handleMethod() {},
    // 查询
    searchClick() {
      var params = {
        page: 1,
        pagenum: this.queryInfo.pagenum,
        gameid: this.searchForm.gameid
      };
      this.loading = true;
      let that = this;
      this.$Http({
        url: "/api/GameConfig/index",
        params,
        successful(res, data) {
          that.GameConfig = data.data.data;
          that.total = data.data.total;
          that.loading = false;
        }
      });
      localStorage.setItem(
              this.searchForm_localStorage,
              this.searchForm.gameid
      );
    },
    // 风控设置
    configDialog(row) {
      this.configDialogVisible = true;
      this.areaid = row.areaid;
      this.title = row.gamename;
      this.gameid = row.gameid;
      var params = { areaid: this.areaid };
      let that = this;
      this.$Http({
        url: "/api/GameConfig/risk",
        params,
        successful(res, data) {
          that.configForm = data.data;
          that.configForm.yxrpr *= 100;
          that.configForm.yxspr *= 100;
        }
      });
    },
    // // 风控设置提交
    configList() {
      var params = this.configForm;
      params.areaid = this.areaid;
      params.gameid = this.gameid;
      this.$refs.configFormRef.validate(valid => {
        var obj = JSON.parse(JSON.stringify(this.configForm));
        obj.yxrpr /= 100;
        obj.yxspr /= 100;
        // obj.opt_pair1 /= 100;
        // obj.opt_pair2 /= 100;
        var params = {
          params: JSON.stringify(obj)
        };

        try {
          if (!valid) {
            throw { message: "参数值为空", value: [] };
          }
          if (obj.yxrpr < 0 || obj.yxrpr > 0.5) {
            throw {
              message: "游戏期望收益率值在0%-50%之间",
              value: [0, 50]
            };
          }
          if (obj.yxspr < -0.1 || obj.yxspr > 0.1) {
            throw {
              message: "游戏保底收益率值在-10%-10%之间",
              value: [-10, 10]
            };
          }
          if (obj.yxb1 < 0 || obj.yxb1 > 100) {
            throw {
              message: "游戏风控参数b1值在0-100之间",
              value: [0, 100]
            };
          }
          if (obj.yxb2 < 0 || obj.yxb2 > 5) {
            throw {
              message: "游戏风控参数b2值在0-5之间",
              value: [0, 5]
            };
          }
          if (obj.yxa1 < 0 || obj.yxa1 > 100) {
            throw {
              message: "游戏风控参数a1值在0-100之间",
              value: [0, 100]
            };
          }
          if (obj.yxa2 < 0 || obj.yxa2 > 5) {
            throw {
              message: "游戏风控参数a2值在0-5之间",
              value: [0, 5]
            };
          }
          if (obj.yx_start_round < 0 || obj.yx_start_round > 100) {
            throw {
              message: "游戏风控启动有效局数在0-100之间",
              value: [0, 100]
            };
          }
          if (obj.yx_start_bill < 0 || obj.yx_start_bill > 10000000) {
            throw {
              message: "游戏风控启动有效流水值在0-10000000之间",
              value: [0, 10000000]
            };
          }

          //抢庄牛牛
          if (obj.QznnCoefficient1 < 0 || obj.QznnCoefficient1 > 5) {
            throw {
              message: "整体调控控制力度值在0-5之间",
              value: [0, 5]
            };
          }
          if (obj.QznnCoefficient2 < 0 || obj.QznnCoefficient2 > 5) {
            throw {
              message: "调控风控系数和胜率对应关系在0-5之间",
              value: [0, 5]
            };
          }
          if (obj.QznnCoefficient3 < 0 || obj.QznnCoefficient3 > 1) {
            throw {
              message: "调控顺位偏离控制力度在0-1之间",
              value: [0, 1]
            };
          }

          //龙虎斗
          if (obj.LhdCoefficient1 < 0 || obj.LhdCoefficient1 > 5) {
            throw {
              message: "调控风控系数和胜率关系(正值时)值在0-5之间",
              value: [0, 5]
            };
          }
          if (obj.LhdCoefficient2 < 0 || obj.LhdCoefficient2 > 5) {
            throw {
              message: "调控风控系数和胜率关系(负值时)值在0-5之间",
              value: [0, 5]
            };
          }
          if (obj.lhdopt1 < 1 || obj.lhdopt1 > 1000000) {
            throw {
              message: "龙初始权重值在0-1000000之间",
              value: [1, 1000000]
            };
          }
          if (obj.lhdopt2 < 1 || obj.lhdopt2 > 1000000) {
            throw {
              message: "虎初始权重值在0-1000000之间",
              value: [1, 1000000]
            };
          }
          if (obj.lhdopt3 < 1 || obj.lhdopt3 > 1000000) {
            throw {
              message: "和初始权重值在0-1000000之间",
              value: [1, 1000000]
            };
          }


          //炸金花风控数据
          if (obj.ZjhCoefficient1 < 0 || obj.ZjhCoefficient1 > 5) {
            throw {
              message: "调控风控系数和胜率关系（正值时）0-5之间",
              value: [0, 5]
            };
          }
          if (obj.ZjhCoefficient2 < 0 || obj.ZjhCoefficient2 > 5) {
            throw {
              message: "调控风控系数和胜率关系（负值时）0-5之间",
              value: [0, 5]
            };
          }
          if (obj.ZjhCoefficient3 < 0 || obj.ZjhCoefficient3 > 1) {
            throw {
              message: "强杀概率上限0-1之间",
              value: [0, 1]
            };
          }
          if (obj.ZjhCoefficient4 < 0 || obj.ZjhCoefficient4 > 2) {
            throw {
              message: "调整强杀概率和风控系数关系0-2之间",
              value: [0, 2]
            };
          }

          //通比牛牛
          if (obj.TbnnCoefficient1 < 1 || obj.TbnnCoefficient1 > 10) {
            throw {
              message: "风控系数为1时的权重倍数1-10之间",
              value: [1, 10]
            };
          }
          if (obj.TbnnCoefficient2 < 0 || obj.TbnnCoefficient2 > 1) {
            throw {
              message: "风控系数为-1时的权重倍数0-1之间",
              value: [0, 1]
            };
          }

          //十三水
          if (obj.SssCoefficient1 < 0 || obj.SssCoefficient1 > 5) {
            throw {
              message: "整体调控控制力度0-5之间",
              value: [0, 1]
            };
          }
          if (obj.SssCoefficient2 < 0 || obj.SssCoefficient2 > 5) {
            throw {
              message: "调控风控系数和胜率对应关系0-5之间",
              value: [0, 5]
            };
          }
          if (obj.SssCoefficient3 < 0 || obj.SssCoefficient3 > 1) {
            throw {
              message: "调控顺位偏离控制力度0-1之间",
              value: [0, 1]
            };
          }
          //红黑大战
          if (obj.HhdzCoefficient1 < 0 || obj.HhdzCoefficient1 > 5) {
            throw {
              message: "调控风控系数和胜率关系数值0-5之间",
              value: [0, 5]
            };
          }
          if (obj.HhdzCoefficient2 < 0 || obj.HhdzCoefficient2 > 5) {
            throw {
              message: "调控风控系数和胜率关系数值0-5之间",
              value: [0, 5]
            };
          }

          //二八杠
          if (obj.EbgCoefficient1 < 0 || obj.EbgCoefficient1 > 5) {
            throw {
              message: "整体调控控制力度数值0-5之间",
              value: [0, 5]
            };
          }
          if (obj.EbgCoefficient2 < 0 || obj.EbgCoefficient2 > 5) {
            throw {
              message: "调控风控系数和胜率对应关系数值0-5之间",
              value: [0, 5]
            };
          }
          if (obj.EbgCoefficient3 < 0 || obj.EbgCoefficient3 > 1) {
            throw {
              message: "调控顺位偏离控制力度数值0-1之间",
              value: [0, 1]
            };
          }

          //百人牛牛
          if (obj.BrnnCoefficient1 < 0 || obj.BrnnCoefficient1 > 5) {
            throw {
              message: "调控风控系数和胜率关系(正值时)值在0-5之间",
              value: [0, 5]
            };
          }
          if (obj.BrnnCoefficient2 < 0 || obj.BrnnCoefficient2 > 5) {
            throw {
              message: "调控风控系数和胜率关系(负值时)值在0-5之间",
              value: [0, 5]
            };
          }

          //百家乐
          if (obj.BjlCoefficient1 < 0 || obj.BjlCoefficient1 > 5) {
            throw {
              message: "调控风控系数和胜率关系(正值时)值在0-5之间",
              value: [0, 5]
            };
          }
          if (obj.BjlCoefficient2 < 0 || obj.BjlCoefficient2 > 5) {
            throw {
              message: "调控风控系数和胜率关系(负值时)值在0-5之间",
              value: [0, 5]
            };
          }
          if (obj.BjlCoefficient3 < 1 || obj.BjlCoefficient3 > 10) {
            throw {
              message: "仅可开“和”时的“和”概率缩放系数值在0-10之间",
              value: [1, 10]
            };
          }
          if (obj.BjlCoefficient4 < 0 || obj.BjlCoefficient4 > 1) {
            throw {
              message: "必杀时对子的概率缩放系数值在0-1之间",
              value: [0, 1]
            };
          }
          if (obj.BjlCoefficient5 < 1 || obj.BjlCoefficient5 > 10) {
            throw {
              message: "必送时对子的概率缩放系数值1在0-10之间",
              value: [1, 10]
            };
          }

          if (obj.bjlopt1 < 1 || obj.bjlopt1 > 1000000) {
            throw {
              message: "庄初始权重值在0-1000000之间",
              value: [1, 1000000]
            };
          }
          if (obj.bjlopt2 < 1 || obj.bjlopt2 > 1000000) {
            throw {
              message: "闲初始权重值在0-1000000之间",
              value: [1, 1000000]
            };
          }
          if (obj.bjlopt3 < 1 || obj.bjlopt3 > 1000000) {
            throw {
              message: "和初始权重值在0-1000000之间",
              value: [1, 1000000]
            };
          }
          if (obj.opt_pair1 < 0 || obj.opt_pair1 > 20) {
            throw {
              message: "庄对初始概率值在0%-20%之间",
              value: [0, 20]
            };
          }
          if (obj.opt_pair2 < 0 || obj.opt_pair2 > 20) {
            throw {
              message: "闲对初始概率值在0%-20%之间",
              value: [0, 20]
            };
          }
          if (obj.LhdCoefficient3 < 1 || obj.LhdCoefficient3 > 10) {
            throw {
              message: "仅可开“和”时的“和”概率缩放系数值在0-10之间",
              value: [1, 10]
            };
          }

          //福星高照

          if (obj.FxgzCoefficient1 < 0 || obj.FxgzCoefficient1 > 5) {
            throw {
              message: "调控风控系数和胜率关系（正值时）在0-5之间",
              value: [0, 5]
            };
          }
          if (obj.FxgzCoefficient2 < 0 || obj.FxgzCoefficient2 > 5) {
            throw {
              message: "调控风控系数和胜率关系（负值时）在0-5之间",
              value: [0, 5]
            };
          }
          if (obj.FxgzCoefficient3 < 1 || obj.FxgzCoefficient3 > 5) {
            throw {
              message: "必送时对巨奖命中几率的提升倍率在1-5之间",
              value: [1, 5]
            };
          }
          if (obj.FxgzCoefficient4 < 1 || obj.FxgzCoefficient4 > 5) {
            throw {
              message: "必送时对免费游戏触发几率的提升倍率在1-5之间",
              value: [1, 5]
            };
          }
          if (obj.FxgzCoefficient5 < 1 || obj.FxgzCoefficient5 > 5) {
            throw {
              message: "必送时对Jackpot触发几率的提升倍率在1-5之间",
              value: [0, 1]
            };
          }
          if (obj.FxgzCoefficient6 < 0 || obj.FxgzCoefficient6 > 100) {
            throw {
              message: "免费游戏上限保底金额的倍数在0-100之间",
              value: [0, 100]
            };
          }
          if (obj.FxgzCoefficient7 < 1) {
            throw {
              message: "玩家并发系数必须大于等于1",
              value: [1]
            };
          }
        //二十一点
          if (obj.EsydCoefficient1 < 0 || obj.EsydCoefficient1 > 5) {
            throw {
              message: "调控风控系数和胜率关系（正值时）在0-5之间",
              value: [0, 5]
            };
          }
          if (obj.EsydCoefficient2 < 0 || obj.EsydCoefficient2 > 5) {
            throw {
              message: "调控风控系数和胜率关系（负值时）在0-5之间",
              value: [0, 5]
            };
          }

          //捕鱼
          if(obj.ByCoefficient1 < 0 || obj.ByCoefficient1 > 3){
            throw {
              message: "捕鱼风控计算参数1值在0-3之间",
              value: [0, 3]
            };
          }
          if(obj.ByCoefficient2 < 0 || obj.ByCoefficient2 > 5){
            throw {
              message: "捕鱼风控计算参数1值在0-5之间",
              value: [0, 5]
            };
          }
          if(obj.ByCoefficient3 < 0 || obj.ByCoefficient3 > 1){
            throw {
              message: "捕鱼风控计算参数1值在0-1之间",
              value: [0, 1]
            };
          }
          if(obj.ByCoefficient4 < 0 || obj.ByCoefficient4 > 5){
            throw {
              message: "捕鱼风控计算参数1值在0-5之间",
              value: [0, 5]
            };
          }

          //财神
          if (obj.CsCoefficient1 < 0 || obj.CsCoefficient1 > 5) {
            throw {
              message: "调控风控系数和胜率关系（正值时）在0-5之间",
              value: [0, 5]
            };
          }
          if (obj.CsCoefficient2 < 0 || obj.CsCoefficient2 > 5) {
            throw {
              message: "调控风控系数和胜率关系（负值时）在0-5之间",
              value: [0, 5]
            };
          }
          if (obj.CsCoefficient3 < 1 || obj.CsCoefficient3 > 5) {
            throw {
              message: "必送时对巨奖命中几率的提升倍率在1-5之间",
              value: [1, 5]
            };
          }
          if (obj.CsCoefficient4 < 1 || obj.CsCoefficient4 > 5) {
            throw {
              message: "必送时对免费游戏触发几率的提升倍率在1-5之间",
              value: [1, 5]
            };
          }
          if (obj.CsCoefficient5 < 1) {
            throw {
              message: "玩家并发系数必须大于等于1",
              value: [1]
            };
          }

          //690 红包扫雷
          if (obj.HbslCoefficient1 < 0 || obj.HbslCoefficient1 > 50) {
            throw {
              message: "调控风控系数和标签关系（正值时）在0-50之间",
              value: [0, 50]
            };
          }
          if (obj.HbslCoefficient2 < 0 || obj.HbslCoefficient2 > 5) {
            throw {
              message: "调控风控系数和标签关系（负值时）值在0-5之间",
              value: [0, 5]
            };
          }

          //710 看牌抢庄牛牛
          if (obj.KpqznnCoefficient1 < 0 || obj.KpqznnCoefficient1 > 100) {
            throw {
              message: "风控系数为1时的权重倍数值在1-100之间",
              value: [0, 100]
            };
          }
          if (obj.KpqznnCoefficient2 < 0 || obj.KpqznnCoefficient2 > 1) {
            throw {
              message: "风控系数为-1时的权重倍数值在0-1之间",
              value: [0, 1]
            };
          }

          //886 赏金船长
          if (obj.SjczCoefficient1 < 0 || obj.SjczCoefficient1 > 5) {
            throw {
              message: "调控风控系数和胜率关系（正值时）值在0-5之间",
              value: [0, 5]
            };
          }
          if (obj.SjczCoefficient2 < 0 || obj.SjczCoefficient2 > 5) {
            throw {
              message: "调控风控系数和胜率关系（负值时）值在0-5之间",
              value: [0, 5]
            };
          }
          if (obj.SjczCoefficient3 < 1 || obj.SjczCoefficient3 > 5) {
            throw {
              message: "必送时中奖类型2权重的提升倍率值在0-5之间",
              value: [1, 5]
            };
          }


          //802 舞狮进宝
          if (obj.WsjbCoefficient1 < 0 || obj.WsjbCoefficient1 > 5) {
            throw {
              message: "调控风控系数和胜率关系(正值时)值在0-5之间",
              value: [0, 5]
            };
          }
          if (obj.WsjbCoefficient2 < 0 || obj.WsjbCoefficient2 > 5) {
            throw {
              message: "调控风控系数和胜率关系(负值时)值在0-5之间",
              value: [0, 5]
            };
          }
          if (obj.WsjbCoefficient3 < 1 || obj.WsjbCoefficient3 > 5) {
            throw {
              message: "必送时中奖类型2权重的提升倍率值在0-5之间",
              value: [1, 5]
            };
          }
          if (obj.WsjbCoefficient4 < 1 || obj.WsjbCoefficient4 > 5) {
            throw {
              message: "必送时中奖类型3权重的提升倍率值在0-5之间",
              value: [1, 5]
            };
          }
          if (obj.WsjbCoefficient5 < 1 || obj.WsjbCoefficient5 > 5) {
            throw {
              message: "必送时中奖类型4权重的提升倍率值在0-5之间",
              value: [1, 5]
            };
          }

          //806 白蛇传
          if (obj.BszCoefficient1 < 0 || obj.BszCoefficient1 > 5) {
            throw {
              message: "各赔率表权重的调节倍数（正值时）值在0-5之间",
              value: [0, 5]
            };
          }
          if (obj.BszCoefficient2 < 0 || obj.BszCoefficient2 > 100) {
            throw {
              message: "各赔率表权重的调节指数（正值时）值在0-100之间",
              value: [0, 100]
            };
          }
          if (obj.BszCoefficient3 < 0 || obj.BszCoefficient3 > 5) {
            throw {
              message: "各赔率表权重的调节倍数（负值时）值在0-5之间",
              value: [0, 5]
            };
          }
          if (obj.BszCoefficient4 < 0 || obj.BszCoefficient4 > 100) {
            throw {
              message: "各赔率表权重的调节指数（负值时）值在0-100之间",
              value: [0, 100]
            };
          }
          if (obj.BszCoefficient5 < 0 || obj.BszCoefficient5 > 1) {
            throw {
              message: "中奖概率上调上限（正值时）值在0-1之间",
              value: [0, 1]
            };
          }
          if (obj.BszCoefficient6 < 0 || obj.BszCoefficient6 > 2) {
            throw {
              message: "中奖概率下调上限（负值时）值在0-2之间",
              value: [0, 2]
            };
          }
          if (obj.BszCoefficient7 < 0.1 || obj.BszCoefficient7 > 1) {
            throw {
              message: "允许赢取奖池的最大比例值在0.1-1之间",
              value: [0.1, 1]
            };
          }
          if (obj.BszCoefficient8 < 10 || obj.BszCoefficient8 > 120) {
            throw {
              message: "更换赔率玩法权重表的时间间隔（秒）值在10-120之间",
              value: [10, 120]
            };
          }

          //803 摇钱树
          if (obj.YqsCoefficient1 < 0 || obj.YqsCoefficient1 > 5) {
            throw {
              message: "调控风控系数和胜率关系(正值时)值在0-5之间",
              value: [0, 5]
            };
          }
          if (obj.YqsCoefficient2 < 0 || obj.YqsCoefficient2 > 5) {
            throw {
              message: "调控风控系数和胜率关系(负值时)值在0-5之间",
              value: [0, 5]
            };
          }
          if (obj.YqsCoefficient3 < 1 || obj.YqsCoefficient3 > 5) {
            throw {
              message: "必送时中奖类型2权重的提升倍率值在1-5之间",
              value: [1, 5]
            };
          }
          if (obj.YqsCoefficient4 < 1 || obj.YqsCoefficient4 > 5) {
            throw {
              message: "必送时中奖类型3权重的提升倍率值在1-5之间",
              value: [1, 5]
            };
          }

          //808 麻辣火锅
          if (obj.MlhgCoefficient1 < 0 || obj.MlhgCoefficient1 > 5) {
            throw {
              message: "各赔率表权重的调节倍数（正值时）值在0-5之间",
              value: [0, 5]
            };
          }
          if (obj.MlhgCoefficient2 < 0 || obj.MlhgCoefficient2 > 100) {
            throw {
              message: "各赔率表权重的调节指数（正值时）值在0-100之间",
              value: [0, 100]
            };
          }
          if (obj.MlhgCoefficient3 < 0 || obj.MlhgCoefficient3 > 5) {
            throw {
              message: "各赔率表权重的调节倍数（负值时）值在0-5之间",
              value: [0, 5]
            };
          }
          if (obj.MlhgCoefficient4 < 0 || obj.MlhgCoefficient4 > 100) {
            throw {
              message: "各赔率表权重的调节指数（负值时）值在0-100之间",
              value: [0, 100]
            };
          }
          if (obj.MlhgCoefficient5 < 0 || obj.MlhgCoefficient5 > 1) {
            throw {
              message: "中奖概率上调上限（正值时）值在0-1之间",
              value: [0, 1]
            };
          }
          if (obj.MlhgCoefficient6 < 0 || obj.MlhgCoefficient6 > 2) {
            throw {
              message: "中奖概率下调上限（负值时）值在0-2之间",
              value: [0, 2]
            };
          }
          if (obj.MlhgCoefficient7 < 0.1 || obj.MlhgCoefficient7 > 1) {
            throw {
              message: "允许赢取奖池的最大比例值在0.1-1之间",
              value: [0.1, 1]
            };
          }
          if (obj.MlhgCoefficient8 < 10 || obj.MlhgCoefficient8 > 120) {
            throw {
              message: "更换赔率玩法权重表的时间间隔（秒）值在10-120之间",
              value: [10, 120]
            };
          }


          //810 水果玛丽
          if (obj.SgpdCoefficient1 < 0 || obj.SgpdCoefficient1 > 5) {
            throw {
              message: "各赔率表权重的调节倍数（正值时）值在0-5之间",
              value: [0, 5]
            };
          }
          if (obj.SgpdCoefficient2 < 0 || obj.SgpdCoefficient2 > 100) {
            throw {
              message: "各赔率表权重的调节指数（正值时）值在0-100之间",
              value: [0, 100]
            };
          }
          if (obj.SgpdCoefficient3 < 0 || obj.SgpdCoefficient3 > 5) {
            throw {
              message: "各赔率表权重的调节倍数（负值时）值在0-5之间",
              value: [0, 5]
            };
          }
          if (obj.SgpdCoefficient4 < 0 || obj.SgpdCoefficient4 > 100) {
            throw {
              message: "各赔率表权重的调节指数（负值时）值在0-100之间",
              value: [0, 100]
            };
          }
          if (obj.SgpdCoefficient5 < 0 || obj.SgpdCoefficient5 > 1) {
            throw {
              message: "中奖概率上调上限（正值时）值在0-1之间",
              value: [0, 1]
            };
          }
          if (obj.SgpdCoefficient6 < 0 || obj.SgpdCoefficient6 > 2) {
            throw {
              message: "中奖概率下调上限（负值时）值在0-2之间",
              value: [0, 2]
            };
          }
          if (obj.SgpdCoefficient7 < 0.1 || obj.SgpdCoefficient7 > 1) {
            throw {
              message: "允许赢取奖池的最大比例值在0.1-1之间",
              value: [0.1, 1]
            };
          }
          if (obj.SgpdCoefficient8 < 10 || obj.SgpdCoefficient8 > 120) {
            throw {
              message: "更换赔率玩法权重表的时间间隔（秒）值在10-120之间",
              value: [10, 120]
            };
          }


          let that = this;
          this.$Http({
            url: "/api/GameConfig/riskSave",
            params,
            successful(res, data) {
              if (data.status) {
                that.$message.success(data.data.message);
                that.configDialogVisible = false;
              } else {
                that.$message.error(data.message);
              }
            }
          });
        } catch (e) {
          this.$message.error(e.message);
        }
      });
    },
    // 机器人设置
    boot(row) {
      this.bootForm = row;
      this.gameModeName = row.gameModeName;
      this.gamename = row.gamename;
      this.$set(this.bootForm, "gamename", row.gamename);
      this.$set(this.bootForm, "gameid", row.gameid);
      this.$set(this.bootForm, "areaid", row.areaid);
      this.bootDialogVisible = true;
      var params = this.bootForm;
      let that = this;
      this.$Http({
        url: "/api/robotsettings/getMaxBatch",
        params,
        successful(res, data) {
          that.$set(that.bootForm, "num", data.data.num);
          that.$set(that.bootForm, "count", data.data.count);
          that.$set(that.bootForm, "guid_start", data.data.guid_start);
          that.$set(that.bootForm, "batchid", data.data.batchid);
        }
      });
    },
    // 机器人设置提交
    bootList() {
      var params = this.bootForm;
      this.$refs.bootFormRef.validate(valid => {
        if (!valid) return;
        //  添加请求
        let that = this;
        this.$Http({
          url: "/api/robotsettings/addBatchs",
          params,
          successful(res, data) {
            if (data.status == false) {
              that.$message.error(data.message);
            } else {
              that.$message.success(data.message);
              that.bootDialogVisible = false;
            }
          }
        });
      });
    },
  }
};
</script>

<style lang="less">
.searchBox {
  display: flex;
  align-items: center;
  /deep/ .el-form-item__error {
    color: red;
    font-size: 12px;
    font-weight: 600;
    line-height: 1;
    padding-top: 4px;
    position: absolute;
    top: 25% !important;
    right: -71px !important;
    left: initial;
  }
}
.el-input-group__append {
  background-color: unset !important;
  color: unset !important;
  vertical-align: middle !important;
  display: table-cell !important;
  position: relative !important;
  border: 0 !important;
  border-radius: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  right: 20px !important;
  white-space: nowrap !important;
}
/deep/.label .el-form-item__labe {
  width: 110px !important;
}
/deep/.el-dialog__body {
  padding: 0 !important;
}
</style>

<style >
.el-col-8 {
  width: 50% !important;
}
.el-col-6 {
  width: 20%;
  margin-right: 5%;
}
.el-row--flex.is-justify-space-around {
  justify-content: end!important;
}

#ListBox .TitleBox .AddList button {
  font-weight: 700;
  background: #6495ed;
  border: 1px solid grey;
  border-radius: 2px;
  padding: 5px;
  cursor: pointer;
  color: #f5f5f5;
}
#ListBox .TitleBox {
  justify-content: space-between;
  margin-bottom: 20px;
}
#ListBox .TitleBox .title {
  font-weight: 700;
  font-size: 20px;
  display: flex;
  align-items: center;
}

#ListBox .TitleBox, .page {
  display: flex;
  align-items: center;
}
.page {
  font-weight: 400;
  color: #606266;
  font-size: 13px;
  line-height: 28px;
  justify-content: flex-end;
}

#ListBox .btn {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 5px;
  color: #f5f5f5;
  text-align: center;
  margin: 3px auto;
  background: purple;
  width: 100px;
}
#ListBox .btn1 {
  background: green!important;;
}
#ListBox .btn2 {
  background: red!important;;
}
#ListBox .btn3 {
  background: #00f!important;;
}
#ListBox .btn7 {
  background: #78c2ea!important;;
}
#ListBox .btn8 {
  background: #447792!important;;
}

.el-textarea__inner{
  min-height: 260px !important;
}
.el-form-item__label {
  text-align: inherit !important;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #606266;
  line-height: 40px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
}

.jsoneditor-vue{
  height: 100%;
}
.jsoneditor-poweredBy{
  display: none;
}
</style>