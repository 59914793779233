<template>
  <div id="ChangePwd">
    <h3>修改密码</h3>
    <div class="formbox">
      <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" class="demo-ruleForm">
         <el-row type="flex" class="row-bg" justify="center">
          <el-col :span="6">
            <el-form-item label="旧密码" prop="oldpassword">
              <el-input type="password" v-model="ruleForm.oldpassword"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" justify="center">
          <el-col :span="6">
            <el-form-item label="密码" prop="pass">
              <el-input type="password" v-model="ruleForm.pass" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" justify="center">
          <el-col :span="6">
            <el-form-item label="确认密码" prop="checkPass">
              <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" justify="center">
          <el-col :span="6">
            <el-form-item>
              <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
              <el-button @click="resetForm('ruleForm')">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        oldpassword: "",
        pass: "",
        checkPass: ""
      },
      rules: {
        oldpassword: [{ required: true, message: "请输入原密码", trigger: "blur" }],
        pass: [
          { validator: validatePass, trigger: "blur" },
          { min: 6, max: 16, message: "长度在 6 到 18", trigger: "blur" }
        ],
        checkPass: [
          { validator: validatePass2, trigger: "blur" },
          { min: 6, max: 16, message: "长度在 6 到 18", trigger: "blur" }
        ]
      }
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          var params = {
            id: localStorage.getItem("UserInfoID"),
            oldpassword:this.ruleForm.oldpassword,
            password: this.ruleForm.pass,
            Authorization: localStorage.getItem("token")
          };
          let that = this;
          this.$Http({
            url: "/api/v1/resetpwd",
            params,
            successful(res, data) {
              if (data.status == false) {
                that.$message.error(data.message);
              } else {
                if (data.code != 200) {
                  that.$message.error(data.message);
                } else {
                  that.$message.success("修改"+data.message);
                  that.$router.push("/login");
                }
              }
            }
          });
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
</script>
<style lang="less" scoped>
/deep/.el-form-item__content {
  text-align: center;
}
</style>