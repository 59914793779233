<template>
    <el-card
            shadow="hover"
            v-loading="loading"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
            style="height: 100%;overflow-y: auto;"
    >
        <!-- 游戏配置与调控---渠道服管理日志 -->
        <div id="ListBox">
            <div class="TitleBox">
                <div class="searchBox">
                    <p class="title">游戏配置与调控--游戏配置日志</p>
                </div>
            </div>
            <!-- 搜索 -->
            <div class="Time">
                <el-form :model="searchForm"  :rules="searchRules" style="margin-bottom: 15px;">
                    <el-row type="flex" :gutter="20">
                        <el-col :span="6">
                            <el-form-item>
                                <el-select clearable placeholder="请选择类型" v-model="searchForm.object">
                                    <el-option label="全部" value=""></el-option>
                                    <el-option label="游戏" value="game"></el-option>
                                    <el-option label="风控" value="config"></el-option>
                                    <el-option label="房间" value="room"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>

                        <el-col :span="6">
                            <el-date-picker
                                    v-model="searchForm.time"
                                    type="daterange"
                                    value-format="yyyy-MM-dd"
                                    range-separator="-"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    :picker-options="pickerOptions"
                            ></el-date-picker>
                        </el-col>
                        <el-col>
                            <el-button type="primary" @click="onSubmit()">查询</el-button>
                        </el-col>
                    </el-row>
                </el-form>
            </div>

            <!-- 表格 -->
            <el-table
                    :data="tabledata"
                    style="width:100%;"
                    ref="multipleTable"
                    size="mini"
            >
<!--                <el-table-column prop="id" label="序号" fixed width="200"></el-table-column>-->
                <el-table-column prop="gamename" label="名称" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.gameid == 888">福星高照</span>
                        <span v-else-if="scope.row.roomid == null">{{scope.row.gamename}}</span>
                        <span v-else>{{scope.row.gamename}}--{{scope.row.roomid}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="obj_name" label="修改项" align="center"></el-table-column>
                <el-table-column prop="before" label="操作前" show-overflow-tooltip align="center"></el-table-column>
                <el-table-column prop="after" label="操作后" show-overflow-tooltip align="center"></el-table-column>
                <el-table-column prop="event" label="操作事件" width="100" align="center">
                    <template slot-scope="scope" >
                        {{scope.row.event == "reset"?"恢复":"更新"}}
                    </template>
                </el-table-column>
                <el-table-column prop="owner" label="操作用户"align="center"></el-table-column>
                <el-table-column  prop="create_time" label="操作时间" align="center"></el-table-column>
                <el-table-column fixed="right" label="操作" width="100" class="btns">
                    <template slot-scope="scope">
                        <el-button v-if="scope.row.obj_key == 'xml_config' && scope.row.event == 'update' " size="mini" type="primary"  @click="reset_xmlConfig(scope.row)">重置</el-button>
                        <el-button v-else size="mini" type="primary" style="color: #82848a" disabled >重置</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页器 -->
            <div class="page">
                <!-- <span>每页10条,</span> -->
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="queryInfo.page"
                        :page-size="queryInfo.pagenum"
                        :page-sizes="[20, 50, 100, 200,500]"
                        style="float:right"
                        layout="sizes,total, prev, pager, next, jumper"
                        :total="total"
                ></el-pagination>
                <el-button type="primary" @click="change(queryInfo.page)">确定</el-button>
            </div>
        </div>
    </el-card>
</template>

<script>
    import { Encrypt, Decrypt } from "../../assets/js/utils";
    export default {
        data() {
            return {
                loading: true,
                // 分页器
                queryInfo: {
                    pagenum: 20,
                    page: 1,
                    start: "",
                    end: "",
                },
                total: 0,
                tabledata: [],
                // 时间查询
                pickerOptions: {
                    disabledDate: time => {
                        return this.dealDisabledDate(time);
                    }
                },
                searchForm: {
                    time: [],
                    object:"",
                },
                searchRules:{
                    object:[{ required: true, message: "请选择类型", trigger: "blur" }],
                }
            };
        },
        mounted() {
            this.getDay();
            this.getTableList();
        },

        methods: {
            change(newPage) {
                this.loading = false;
                this.handleCurrentChange(newPage);
                this.loading = true;
            },
            // 监听pageName改变的事件
            handleSizeChange(pagenum) {
                this.loading = false;
                this.queryInfo.pagenum = pagenum;
                this.getTableList();
                this.loading = true;
            },
            // 监听页码值改变的事件
            handleCurrentChange(newPage) {
                this.loading = false;
                this.queryInfo.page = newPage;
                this.getTableList();
                this.loading = true;
            },

            // 获取表格列表
            getTableList() {
                var params = this.queryInfo;
                params.time = this.searchForm.time;
                params.object = this.searchForm.object;
                let that = this;
                this.$Http({
                    url: "/api/GameConfig/gamelog",
                    params,
                    successful(res, data) {
                        that.loading = false;
                        that.tabledata = data.data.data;
                        that.total = data.data.total;
                    },
                });
            },
            // 时间查询按钮
            onSubmit() {
                if (!this.searchForm.timearr) {
                    this.searchForm.timearr = [this.startdate, this.enddate];
                }
                this.queryInfo.page = 1;
                this.queryInfo.start =  this.searchForm.time[0];
                this.queryInfo.end =  this.searchForm.time[1];
                this.loading = true;
                this.getTableList();
            },
            //重置房间lua配置
            reset_xmlConfig(row){
                var params = row;
                let that = this;
                const confirmResult = this.$confirm(
                    "此操作将重置该游戏场次lua配置，是否继续？",
                    "提示",
                    {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning"
                    }
                )
                    .then(() => {
                        this.$Http({
                            url: "/api/GameConfig/reset_xmlConfig",
                            params,
                            successful(res, data) {
                                if (data.status == false) {
                                    that.$message.error(data.message);
                                } else {
                                    that.$message.success(data.data.message);
                                    that.getTableList();
                                }
                            },
                        });
                    })
                    .catch(() => {
                        that.$message.info("已取消重置");
                    });
            },
            // 默认显示时间
            getDay() {
                var now = new Date();
                var year = now.getFullYear(); //得到当前年份
                var month = now.getMonth() + 1; //默认得到月份是上一个月，如果当前是3月，这个值为2月
                var d = now.getDate();
                var h = now.getHours();
                var m = now.getMinutes();
                var s = now.getSeconds();
                if (month == 12)
                    //如果当前是1月，则获取到的数据为12月，年份减一
                    year = year - 1;
                var nextMonth = month; //其实就是当前月份
                month = month.toString().padStart(2, "0"); //当小于10时，显示为01.02.03
                d = d.toString().padStart(2, "0"); //当小于10时，显示为01.02.03
                nextMonth = nextMonth.toString().padStart(2, "0");
                this.startdate = `${year}-${month}-${d}`; //拼接日期
                this.enddate = `${year}-${nextMonth}-${d}`;
                this.queryInfo.start= this.startdate;
                this.queryInfo.end= this.enddate;
            },
            dealDisabledDate(time) {
                var times = Date.now();
                return time.getTime() > times;
            }

        },
    };
</script>

<style lang="less" >
    #ListBox .TitleBox .title {
        font-weight: 700;
        font-size: 20px;
        display: flex;
        align-items: center;
    }

    #ListBox .TitleBox {
        justify-content: space-between;
        margin-bottom: 20px;
    }

    #ListBox .TitleBox .AddList button {
        font-weight: 700;
        background: #6495ed;
        border: 1px solid grey;
        border-radius: 2px;
        padding: 5px;
        cursor: pointer;
        color: #f5f5f5;
    }
    #ListBox .TitleBox, .page {
        display: flex;
        align-items: center;
    }
    .page {
        font-weight: 400;
        color: #606266;
        font-size: 13px;
        line-height: 28px;
        justify-content: flex-end;
    }
    #ListBox .btn {
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: 5px;
        color: #f5f5f5;
        text-align: center;
        margin: 3px auto;
        background: purple;
        width: 100px;
    }
</style>
<style>

    #ListBox .el-form .el-form-item__label {
        float: none;
    }

    .el-table--enable-row-transition .el-table__body td {
        border-right: 1px solid #ebeef5;
    }
</style>

