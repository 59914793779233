<template>
    <!-- 风控系统--风控管理 -->
    <el-card
            shadow="hover"
            v-loading="loading"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
    >

        <el-card>
            <div class="title" style="border-bottom:1px solid silver;padding-bottom:15px">风控管理
                <span class="prompt" style="color:#07187b;margin-left:15px;font-size:18px; margin-top:-20px; font-weight: bold" v-text="msg"></span>
            </div>


            <!-- 查询部分 -->
            <!-- 搜索 -->
            <div class="Time">
                <el-form :model="searchForm">
                    <el-row type="flex" :gutter="20">
                        <el-col :span="8" style="width: 45%!important;">
                            <el-form-item>
                                <el-select clearable placeholder="请选择" v-model="type" @change="indexSelect($event)">
                                    <el-option label="平台" :value="0"></el-option>
                                    <el-option label="游戏" :value="1"></el-option>
                                    <el-option label="代理商" :value="2"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>

                        <el-col :span="8" style="width: 45%!important;" v-if="agentShow">
                            <el-form-item>
                                <el-select clearable placeholder="请选择代理商" v-model="searchForm.agent_id">
                                    <el-option
                                            :label="item.agent_id"
                                            v-for="(item,index) of agentlist"
                                            :key="index"
                                            :value="item.agent_id"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>

                        <el-col :span="8" style="width: 45%!important;" v-if="gameShow">
                            <el-form-item>
                                <el-select clearable placeholder="请选择游戏" filterable  v-model="searchForm.areaid">
                                    <el-option
                                            :label="item.gamename +'('+ item.modename.substring(0,2)+')'"
                                            v-for="(item,index) of gamelist"
                                            :key="index"
                                            :value="item.areaid"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>

                        <el-col>
                            <el-button type="primary" @click="onSubmit()">查询</el-button>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <!-- 表格 -->
<!--            .slice((page-1)*pagenum,page*pagenum)-->
            <el-row>
                <el-table :data="tabledata" border style="width: 100%" height="600px" :row-class-name="tableRowClassName">
                    <el-table-column label="周期内第几天"  prop="nday"></el-table-column>
                    <el-table-column label="周期盈利" prop="period_profits" >
                        <template slot-scope="scope">
                            <span>{{scope.row.period_profits }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="周期流水" prop="period_bets">
                        <template slot-scope="scope">
                            <span>{{scope.row.period_bets }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="税收" prop="period_tax">
                        <template slot-scope="scope">
                            <span>{{scope.row.period_tax }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="牌局数" prop="period_round"></el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button size="mini" type="primary" @click="edit(scope.$index, scope.row)">重置</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-row>
            <!-- 分页器 -->
<!--            <el-row>-->
<!--            <div class="page">-->
<!--                &lt;!&ndash; <span>每页10条,</span> &ndash;&gt;-->
<!--                <el-pagination-->
<!--                        @size-change="handleSizeChange"-->
<!--                        @current-change="handleCurrentChange"-->
<!--                        :current-page="page"-->
<!--                        :page-size="pagenum"-->
<!--                        :page-sizes="[20, 50, 100, 200,500]"-->
<!--                        style="float:right"-->
<!--                        layout="sizes,total, prev, pager, next, jumper"-->
<!--                        :total="total"-->
<!--                ></el-pagination>-->
<!--                <el-button type="primary" @click="change(page)">确定</el-button>-->
<!--            </div>-->
<!--            </el-row>-->
        <!-- 编辑类目 -->
        <!--      :rules="editFormRules"-->
        </el-card>

        <el-row >
            <el-dialog title="编辑风控管理" :visible.sync="editDialogVisible" :close-on-click-modal="false" width="35%">
                <el-form ref="editform"  :model="editform" label-width="120px">
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="初始流水：">
                                <el-input v-model.number="editform.period_profits" style="width:240px"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row type="flex">
                        <el-col :span="8">
                            <el-form-item label="盈利率(%)：">
                                <el-input v-model="editform.profit_rate" style="width:240px"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-form-item>
                        <el-row type="flex" justify="end">
                            <el-button @click="editDialogVisible = false">取 消</el-button>
                            <el-button type="primary" @click="editok()">确 定</el-button>
                        </el-row>
                    </el-form-item>
                </el-form>
            </el-dialog>
        </el-row>

    </el-card>
</template>

<script>
    export default {
        data() {
            return {
                loading: true,
                type:0,
                agentShow:false,
                gameShow:false,
                msg:"",
                nowDay:"",
                tabledata: [],
                gamelist:[],
                agentlist:[],
                editDialogVisible: false,
                total: 0,
                page: 1,
                pagenum: 20,
                searchForm: {
                    areaid:"",
                    agent_id: ""
                },
                editform:{
                    period_profits:0,
                    profit_rate:0,
                    nday:0,
                    lod_profits:0,
                    lod_bets:0,
                    lod_tax:0,
                    lod_round:0,
                }
            };
        },
        mounted() {
            this.gettab();
            this.getpubicList();
        },
        methods: {
            tableRowClassName({row}) {
                if (row.nday == this.nowDay) {
                    return 'onday';
                }else{
                    return '';
                }
            },


            change(newPage) {
                this.loading = false;
                this.handleCurrentChange(newPage);
                this.loading = true;
            },
            // 监听pageName改变的事件
            handleSizeChange(pagenum) {
                this.loading = false;
                this.pagenum = pagenum;
                this.loading = true;
            },
            // 监听页码值改变的事件
            handleCurrentChange(newPage) {
                this.loading = false;
                this.page = newPage;
                this.loading = true;
            },

            // 表格列表
            gettab() {
                this.loading = true;
                let that = this;
                var params = this.searchForm;
                this.$Http({
                    url: "api/riskcontrol/getList",
                    params,
                    successful(res, data) {
                        if (data.status == false) {
                            that.$message.error(data.message);
                            that.loading = false;
                        }
                        if (data.status == true) {
                            that.loading = false;
                            that.tabledata = data.data.data;
                            that.msg = "当天："+data.data.nowDay+" 周期："+data.data.period_days+"天 总盈利："+data.data.sum_profits +" 总流水："+data.data.sum_bets+" 盈利率："+data.data.profit_rate;
                            that.nowDay = data.data.nowDay;
                            that.total = data.data.data.length;
                        }
                    }
                });
            },
            // 编辑按钮
            edit(index, row) {
                this.editDialogVisible = true;
                let that = this;
                this.$Http({
                    url: "api/riskcontrol/getProfits",
                    successful(res, data) {
                        if (data.status == false) {
                            that.$message.error(data.message);
                        }
                        if (data.status == true) {
                            that.editform.lod_profits = row.period_profits;
                            that.editform.lod_bets = row.period_bets;
                            that.editform.lod_tax = row.period_tax;
                            that.editform.lod_round = row.period_round;
                            that.editform.period_profits = data.data.period_profits;
                            that.editform.profit_rate = row.ptrpr*100;
                            that.editform.nday = row.nday;
                        }
                    }
                });
            },
            // 编辑提交按钮
            editok() {
                // this.editDialogVisible = true;
                var params = this.searchForm;
                let that = this;
                params.profits = that.editform.period_profits;
                params.profit_rate = that.editform.profit_rate;
                params.nday = that.editform.nday;

                params.lod_profits = that.editform.lod_profits;
                params.lod_bets = that.editform.lod_bets;
                params.lod_tax = that.editform.lod_tax;
                params.lod_round = that.editform.lod_round;
                params.owner = localStorage.getItem("ms_username");
                if (params.profit_rate  == "" || params.profit_rate  < 0) {
                    return this.$message.error("盈利率值在0-100之间");
                }
                this.$Http({
                    url: "/api/riskcontrol/saveRisk",
                    params,
                    successful(res, data) {
                        // console.log(data);
                        if (data.status == false) {
                            that.$message.error(data.message);
                        } else {
                            if (data.code != 200) {
                                that.$message.error("重置"+data.data.d.msg);
                            } else {
                                that.editDialogVisible = false;
                                that.gettab();
                                that.$message.success("重置"+data.data.d.msg);
                            }
                        }
                    }
                });
            },
            // 下拉框
            getpubicList() {
                this.loading = true;
                let that = this;
                this.$Http({
                    url: "api/riskcontrol/publilist",
                    successful(res, data) {
                        if (data.status == false) {
                            that.$message.error(data.message);
                            that.loading = false;
                        }
                        if (data.status == true) {
                            that.loading = false;
                            that.agentlist = data.data.agend_list;
                            that.gamelist = data.data.gamename;
                        }
                    }
                });
            },
            // 时间查询按钮
            onSubmit() {
                if(this.gameShow == true && this.searchForm.areaid == ""){
                   return this.$message.error("请选择查询游戏场次");
                }

                if(this.agentShow == true && this.searchForm.agent_id == ""){
                    return this.$message.error("请选择查询代理商");
                }

                this.loading = true;
                this.gettab();
            },

            indexSelect(event) {
                if(event == 1){
                    this.searchForm.areaid = "";
                    this.searchForm.agent_id = "";
                    this.gameShow = true;
                    this.agentShow = false;
                }else if(event == 2){
                    this.searchForm.areaid = "";
                    this.searchForm.agent_id = "";
                    this.gameShow = false;
                    this.agentShow = true;
                }else{
                    this.searchForm.areaid = "";
                    this.searchForm.agent_id = "";
                    this.gameShow = false;
                    this.agentShow = false;
                }
            },
        },

    };
</script>

<style>

    .onday{
        color: #ff0000;
        font-weight: bold
    }
    .el-select {
        display: inline-block;
        position: relative;
    }

    .el-row--flex {
        display: flex;
        margin-top: 20px;
    }
    .el-range-editor .el-range-input {
        line-height: 1;
        margin-left: 5px;
    }
    #ListBox .TitleBox, .page {
        display: flex;
        align-items: center;
    }
    .page {
        font-weight: 400;
        color: #606266;
        font-size: 13px;
        line-height: 28px;
        justify-content: flex-end;
    }
</style>