
<template>
  <el-card
    shadow="hover"
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    style="height: 100%;overflow-y: auto;"
  >
  <el-card shadow="hover" id="configuration">
    <h3 class="title">风控系统--平台风控配置</h3>
    <div class="detailBox">
      <el-form
              ref="configFormRef"
              :rules="configFormRules"
              :model="configForm"
              label-position="right"
              :inline="true"
              label-width="170px"
              style="text-align:center"
      >
        <el-row type="flex" :gutter="20" style="border-top:1px solid">
          <el-col :span="12">
            <el-form-item label="风控周期" prop="period_days">
              <el-input v-model.number="configForm.period_days" @blur="period_days"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" :gutter="20">
          <el-col :span="12">
            <el-form-item label="平台期望收益率" prop="ptrpr">
              <el-input v-model="configForm.ptrpr" @blur="ptrprblur($event)">
                <template slot="append">%</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="平台保底收益率" prop="ptspr">
              <el-input v-model="configForm.ptspr" @blur="ptsprblur($event)">
                <template slot="append">%</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" :gutter="20">
          <el-col :span="12">
            <el-form-item label="平台风控参数a1" prop="pta1">
              <el-input
                      v-model="configForm.pta1"
                      @blur="pta1"
                      onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,1})?).*$/g, '$1')"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="平台风控参数a2" prop="pta2">
              <el-input
                      v-model="configForm.pta2"
                      onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,1})?).*$/g, '$1')"
                      @blur="pta2"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" :gutter="20">
          <el-col :span="12">
            <el-form-item label="平台风控参数b1" prop="ptb1">
              <el-input
                      v-model="configForm.ptb1"
                      onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,1})?).*$/g, '$1')"
                      @blur="ptb1"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="平台风控参数b2" prop="ptb2">
              <el-input
                      v-model="configForm.ptb2"
                      onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,1})?).*$/g, '$1')"
                      @blur="ptb2"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" :gutter="20">
          <el-col :span="12">
            <el-form-item label="平台风控启动有效局数" prop="pt_start_round">
              <el-input
                      v-model.number="configForm.pt_start_round"
                      onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,1})?).*$/g, '$1')"
                      @blur="round"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="平台风控启动有效流水" prop="pt_start_bill">
              <el-input
                      v-model.number="configForm.pt_start_bill"
                      onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,1})?).*$/g, '$1')"
                      @blur="bill"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" :gutter="20">
          <el-col :span="12">
            <el-form-item label="玩家期望收益率" prop="wjrpr">
              <el-input v-model="configForm.wjrpr" @blur="wjrprblur($event)">
                <template slot="append">%</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" :gutter="20">
          <el-col :span="12">
            <el-form-item label="玩家风控参数a1" prop="wja1">
              <el-input
                      v-model="configForm.wja1"
                      onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,1})?).*$/g, '$1')"
                      @blur="wja1"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="玩家风控参数a2" prop="wja2">
              <el-input
                      v-model="configForm.wja2"
                      onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,1})?).*$/g, '$1')"
                      @blur="wja2"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" :gutter="20">
          <el-col :span="12">
            <el-form-item label="玩家风控参数b1" prop="wjb1">
              <el-input
                      v-model="configForm.wjb1"
                      onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,1})?).*$/g, '$1')"
                      @blur="wjb1"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="玩家风控参数b2" prop="wjb2">
              <el-input
                      v-model="configForm.wjb2"
                      onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,1})?).*$/g, '$1')"
                      @blur="wjb2"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" :gutter="20">
          <el-col :span="12">
            <el-form-item label="玩家风控启动有效局数" prop="wj_start_round">
              <el-input v-model.number="configForm.wj_start_round" @blur="wj_start_round"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="玩家风控启动有效流水" prop="wj_start_bill">
              <el-input v-model.number="configForm.wj_start_bill" @blur="wj_start_bill"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" :gutter="20">
          <el-col :span="12">
            <el-form-item label="连败风控参数a1" prop="lba1">
              <el-input v-model="configForm.lba1" @blur="lba1"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="连败风控参数a2" prop="lba2">
              <el-input v-model="configForm.lba2" @blur="lba2"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" :gutter="20">
          <el-col :span="12">
            <el-form-item label="连败风控启动有效局数" prop="lb_start_round">
              <el-input
                      v-model="configForm.lb_start_round"
                      onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,1})?).*$/g, '$1')"
                      @blur="lb_start_round"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" :gutter="20" style="border:0">
          <el-col :span="12">
            <el-form-item label="平台权重" prop="ptw">
              <el-input v-model.number="configForm.ptw" @blur="ptw"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="游戏房间权重" prop="yxw">
              <el-input v-model.number="configForm.yxw" @blur="yxw"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" :gutter="20">
          <el-col :span="12">
            <el-form-item label="玩家权重" prop="wjw">
              <el-input v-model.number="configForm.wjw" @blur="wjw"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="连败权重" prop="lbw">
              <el-input v-model.number="configForm.lbw" @blur="lbw"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row type="flex" :gutter="20" style="border:0">
          <el-col :span="12">
            <el-form-item label="代理期望盈利率" prop="dlrpr">
              <el-input v-model.number="configForm.dlrpr" @blur="dlrpr($event)">
                <template slot="append">%</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="代理风控参数a1" prop="dla1">
              <el-input v-model="configForm.dla1" @blur="dla1($event)"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row type="flex" :gutter="20" style="border:0">
          <el-col :span="12">
            <el-form-item label="代理风控参数a2" prop="dla2">
              <el-input v-model="configForm.dla2" @blur="dla2($event)"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="代理风控参数b1" prop="dlb1">
              <el-input v-model="configForm.dlb1" @blur="dlb1($event)"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row type="flex" :gutter="20" style="border:0">
          <el-col :span="12">
            <el-form-item label="代理风控参数b2" prop="dlb2">
              <el-input v-model="configForm.dlb2" @blur="dlb2($event)"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="代理风控启动局数" prop="dl_start_round">
              <el-input v-model.number="configForm.dl_start_round" @blur="dl_start_round($event)"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row type="flex" :gutter="20">
          <el-col :span="12">
            <el-form-item label="代理风控启动流水" prop="dl_start_bill">
              <el-input v-model.number="configForm.dl_start_bill" @blur="dl_start_bill($event)"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="代理风控权重" prop="dlw">
              <el-input v-model.number="configForm.dlw" @blur="dlw($event)"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row type="flex" :gutter="20" justify="end" style="border:0">
          <el-form-item>
            <el-button type="success" @click="submitForm()">提交</el-button>
            <el-button type="primary" @click="resetForm()">取消</el-button>
          </el-form-item>
        </el-row>
      </el-form>
    </div>
  </el-card>
  </el-card>
</template>
<script>
  import { Encrypt, Decrypt } from "../../assets/js/utils";
  export default {
    data() {
      return {
        loading: true,
        // 表单
        configForm: {
          dlrpr:0,
        },
        // 表单验证
        configFormRules: {
          period_days: [
            { required: true, message: "请输入", trigger: "blur" },
            { type: "number", message: "必须为数字" }
          ],
          ptrpr: [{ required: true, message: "请输入", trigger: "blur" }],
          ptspr: [{ required: true, message: "请输入", trigger: "blur" }],
          pta1: [{ required: true, message: "请输入", trigger: "blur" }],
          pta2: [{ required: true, message: "请输入", trigger: "blur" }],
          ptb1: [{ required: true, message: "请输入", trigger: "blur" }],
          ptb2: [{ required: true, message: "请输入", trigger: "blur" }],
          pt_start_round: [
            { required: true, message: "请输入", trigger: "blur" },
            { type: "number", message: "必须为数字" }
          ],
          pt_start_bill: [
            { required: true, message: "请输入", trigger: "blur" },
            { type: "number", message: "必须为数字" }
          ],
          wjrpr: [{ required: true, message: "请输入", trigger: "blur" }],
          wja1: [{ required: true, message: "请输入", trigger: "blur" }],
          wja2: [{ required: true, message: "请输入", trigger: "blur" }],
          wjb1: [{ required: true, message: "请输入", trigger: "blur" }],
          wjb2: [{ required: true, message: "请输入", trigger: "blur" }],
          wj_start_round: [
            { required: true, message: "请输入", trigger: "blur" },
            { type: "number", message: "必须为数字" }
          ],
          wj_start_bill: [
            { required: true, message: "请输入", trigger: "blur" },
            { type: "number", message: "必须为数字" }
          ],
          lba1: [{ required: true, message: "请输入", trigger: "blur" }],
          lba2: [{ required: true, message: "请输入", trigger: "blur" }],
          lb_start_round: [
            { required: true, message: "请输入", trigger: "blur" }
          ],
          ptw: [
            { required: true, message: "请输入", trigger: "blur" },
            { type: "number", message: "必须为数字" }
          ],
          yxw: [
            { required: true, message: "请输入", trigger: "blur" },
            { type: "number", message: "必须为数字" }
          ],
          wjw: [
            { required: true, message: "请输入", trigger: "blur" },
            { type: "number", message: "必须为数字" }
          ],
          lbw: [
            { required: true, message: "请输入", trigger: "blur" },
            { type: "number", message: "必须为数字" }
          ],

          dlrpr: [
            { required: true, message: "请输入", trigger: "blur" },
          ],
          dla1: [
            { required: true, message: "请输入", trigger: "blur" },
          ],
          dla2: [
            { required: true, message: "请输入", trigger: "blur" },
          ],
          dlb1: [
            { required: true, message: "请输入", trigger: "blur" },
          ],
          dlb2: [
            { required: true, message: "请输入", trigger: "blur" },
          ],
          dl_start_round : [
            { required: true, message: "请输入", trigger: "blur" },
            { type: "number", message: "必须为数字" }
          ],
          dl_start_bill: [
            { required: true, message: "请输入", trigger: "blur" },
            { type: "number", message: "必须为数字" }
          ],
          dlw: [
            { required: true, message: "请输入", trigger: "blur" },
            { type: "number", message: "必须为数字" }
          ]
        }
      };
    },
    mounted() {
      this.getconfigForm();
      String.prototype.format = function(args) {
        var result = this;
        if (arguments.length > 0) {
          if (arguments.length == 1 && typeof args == "object") {
            for (var key in args) {
              if (args[key] != undefined) {
                var reg = new RegExp("\\{" + key + "\\}", "g");
                result = result.replace(reg, args[key]);
              }
            }
          } else {
            for (var i = 0; i < arguments.length; i++) {
              if (arguments[i] != undefined) {
                var reg = new RegExp("\\{" + i + "\\}", "g");
                result = result.replace(reg, arguments[i]);
              }
            }
          }
        }
        return result;
      };
    },
    methods: {
      period_days(e) {
        var val = e.target.value;
        if (val == "" || val < 0 || val > 31) {
          this.$message.error("风控周期值在0-31天之间");
        }
      },
      ptrprblur(e) {
        var obj = e.target;
        var t = obj.value.charAt(0);
        obj.value = obj.value
                .replace(".", "$#$") //把第一个字符'.'替换成'$#$'
                .replace(/\./g, "") //把其余的字符'.'替换为空
                .replace("$#$", ".") //把字符'$#$'替换回原来的'.'
                .replace(/[^\d.]/g, "") //只能输入数字和'.'
                .replace(/^\./g, "") //不能以'.'开头
                .replace(/([0-9]+\.[0-9]{1})[0-9]*/, "$1"); //只保留2位小数
        if (t == "-") {
          obj.value = "-" + obj.value;
        }
        if (obj.value == "" || obj.value < 0 || obj.value > 50) {
          this.$message.error("平台期望收益率值在0%-50%之间");
        }
      },
      ptsprblur(e) {
        var obj = e.target;
        var t = obj.value.charAt(0);
        obj.value = obj.value
                .replace(".", "$#$") //把第一个字符'.'替换成'$#$'
                .replace(/\./g, "") //把其余的字符'.'替换为空
                .replace("$#$", ".") //把字符'$#$'替换回原来的'.'
                .replace(/[^\d.]/g, "") //只能输入数字和'.'
                .replace(/^\./g, "") //不能以'.'开头
                .replace(/([0-9]+\.[0-9]{1})[0-9]*/, "$1"); //只保留2位小数
        if (t == "-") {
          obj.value = "-" + obj.value;
        }
        if (obj.value == "" || obj.value > 10 || obj.value < -10) {
          this.$message.error("平台保底收益率值在-10%-10%之间");
        }
      },
      pta1(e) {
        var val = e.target.value;
        if (val == "" || val < 0 || val > 100) {
          this.$message.error("平台风控参数a1值在0-100之间");
        }
      },
      pta2(e) {
        var val = e.target.value;
        if (val == "" || val > 5 || val < 0) {
          this.$message.error("平台风控参数a2值在0-5之间");
        }
      },
      ptb1(e) {
        var val = e.target.value;
        if (val == "" || val < 0 || val > 100) {
          this.$message.error("平台风控参数b1值在0-100之间");
        }
      },
      ptb2(e) {
        var val = e.target.value;
        if (val == "" || val > 5 || val < 0) {
          this.$message.error("平台风控参数b2值在0-5之间");
        }
      },
      round(e) {
        var val = e.target.value;
        if (val == "" || val < 0 || val > 1000) {
          this.$message.error("平台风控启动有效局数值在0-1000之间");
        }
      },
      bill(e) {
        var val = e.target.value;
        if (val == "" || val > 100000000 || val < 0) {
          this.$message.error("平台风控启动有效流水值在0-100000000之间");
        }
      },
      wjrprblur(e) {
        var obj = e.target;
        var t = obj.value.charAt(0);
        obj.value = obj.value
                .replace(".", "$#$") //把第一个字符'.'替换成'$#$'
                .replace(/\./g, "") //把其余的字符'.'替换为空
                .replace("$#$", ".") //把字符'$#$'替换回原来的'.'
                .replace(/[^\d.]/g, "") //只能输入数字和'.'
                .replace(/^\./g, "") //不能以'.'开头
                .replace(/([0-9]+\.[0-9]{1})[0-9]*/, "$1"); //只保留2位小数
        if (t == "-") {
          obj.value = "-" + obj.value;
        }
        if (obj.value == "" || obj.value < -50 || obj.value > 0) {
          this.$message.error("玩家期望收益率值在-50%-0%之间");
        }
      },
      wja1(e) {
        var val = e.target.value;
        if (val == "" || val > 100 || val < 0) {
          this.$message.error("玩家风控参数a1值在0-100之间");
        }
      },
      wja2(e) {
        var val = e.target.value;
        if (val == "" || val < 0 || val > 5) {
          this.$message.error("玩家风控参数a2值在0-5之间");
        }
      },
      wjb1(e) {
        var val = e.target.value;
        if (val == "" || val > 100 || val < 0) {
          this.$message.error("玩家风控参数b1值在0-100之间");
        }
      },
      wjb2(e) {
        var val = e.target.value;
        if (val == "" || val < 0 || val > 5) {
          this.$message.error("玩家风控参数b2值在0-5之间");
        }
      },
      wj_start_round(e) {
        var val = e.target.value;
        if (val == "" || val > 100 || val < 0) {
          this.$message.error("玩家风控启动有效局数值在0-100之间");
        }
      },
      wj_start_bill(e) {
        var val = e.target.value;
        if (val == "" || val < 0 || val > 10000000) {
          this.$message.error("玩家风控启动有效流水值在0-10000000之间");
        }
      },
      lba1(e) {
        var obj = e.target;
        var t = obj.value.charAt(0);
        obj.value = obj.value
                .replace(".", "$#$") //把第一个字符'.'替换成'$#$'
                .replace(/\./g, "") //把其余的字符'.'替换为空
                .replace("$#$", ".") //把字符'$#$'替换回原来的'.'
                .replace(/[^\d.]/g, "") //只能输入数字和'.'
                .replace(/^\./g, "") //不能以'.'开头
                .replace(/([0-9]+\.[0-9]{1})[0-9]*/, "$1"); //只保留2位小数
        if (t == "-") {
          obj.value = "-" + obj.value;
        }
        if (obj.value == "" || obj.value > 1 || obj.value < 0) {
          this.$message.error("连败风控参数a1值在0-1之间");
        }
      },
      lba2(e) {
        var obj = e.target;
        var t = obj.value.charAt(0);
        obj.value = obj.value
                .replace(".", "$#$") //把第一个字符'.'替换成'$#$'
                .replace(/\./g, "") //把其余的字符'.'替换为空
                .replace("$#$", ".") //把字符'$#$'替换回原来的'.'
                .replace(/[^\d.]/g, "") //只能输入数字和'.'
                .replace(/^\./g, "") //不能以'.'开头
                .replace(/([0-9]+\.[0-9]{1})[0-9]*/, "$1"); //只保留2位小数
        if (t == "-") {
          obj.value = "-" + obj.value;
        }
        if (obj.value == "" || obj.value < 0 || obj.value > 5) {
          this.$message.error("连败风控参数a1值在0-5之间");
        }
      },
      lb_start_round(e) {
        var obj = e.target;
        var t = obj.value.charAt(0);
        obj.value = obj.value
                .replace(".", "$#$") //把第一个字符'.'替换成'$#$'
                .replace(/\./g, "") //把其余的字符'.'替换为空
                .replace("$#$", ".") //把字符'$#$'替换回原来的'.'
                .replace(/[^\d.]/g, "") //只能输入数字和'.'
                .replace(/^\./g, "") //不能以'.'开头
                .replace(/([0-9]+\.[0-9]{1})[0-9]*/, "$1"); //只保留2位小数
        if (t == "-") {
          obj.value = "-" + obj.value;
        }
        if (obj.value == "" || obj.value < 1 || obj.value > 5) {
          this.$message.error("连败风控启动有效局数值在1-5之间");
        }
      },
      ptw(e) {
        var val = e.target.value;
        if (val == "" || val < 0 || val > 10000) {
          this.$message.error("平台权重值在0-10000之间");
        }
      },
      yxw(e) {
        var val = e.target.value;
        if (val == "" || val < 0 || val > 10000) {
          this.$message.error("游戏房间权重值在0-10000之间");
        }
      },
      wjw(e) {
        var val = e.target.value;
        if (val == "" || val < 0 || val > 10000) {
          this.$message.error("玩家权重值在0-10000之间");
        }
      },
      lbw(e) {
        var val = e.target.value;
        if (val == "" || val < 0 || val > 10000) {
          this.$message.error("连败权重值在0-10000之间");
        }
      },
      dlrpr(e){
        var val = e.target.value;
        if (val == "" || val < 0 || val > 50) {
          this.$message.error("代理期望盈利率值在0-50之间");
        }
      },
      dla1(e){
        var val = e.target.value;
        if (val == "" || val < 0 || val > 100) {
          this.$message.error("代理风控参数a1值在0-100之间");
        }
      },
      dla2(e){
        var val = e.target.value;
        if (val == "" || val < 0 || val > 5) {
          this.$message.error("代理风控参数a2值在0-5之间");
        }
      },
      dlb1(e){
        var val = e.target.value;
        if (val == "" || val < 0 || val > 100) {
          this.$message.error("代理风控参数b1值在0-100之间");
        }
      },
      dlb2(e){
        var val = e.target.value;
        if (val == "" || val < 0 || val > 5) {
          this.$message.error("代理风控参数b2值在0-5之间");
        }
      },
      dl_start_round(e){
        var val = e.target.value;
        if (val == "" || val < 0 || val > 100) {
          this.$message.error("代理风控启动局数值在0-100之间");
        }
      },
      dl_start_bill(e){
        var val = e.target.value;
        if (val == "" || val < 0 || val > 10000000) {
          this.$message.error("代理风控启动流水值在0-10000000之间");
        }
      },
      dlw(e){
        var val = e.target.value;
        if (val == "" || val < 0 || val > 10000) {
          this.$message.error("代理风控权重值在0-10000之间");
        }
      },

      //   获取表单
      getconfigForm() {
        let that = this;
        this.$Http({
          url: "/api/variables/getConfig",
          successful(res, data) {
            that.loading = false;
            that.configForm = data.data;
            that.configForm.ptrpr *= 100;
            that.configForm.wjrpr *= 100;
            that.configForm.ptspr *= 100;
            that.configForm.dlrpr *= 100;
          }
        });
      },
      //   提交按钮
      submitForm() {
        this.$refs.configFormRef.validate(valid => {
          var obj = JSON.parse(JSON.stringify(this.configForm));
          obj.ptrpr /= 100;
          obj.wjrpr /= 100;
          obj.ptspr /= 100;
          obj.dlrpr /= 100;
          var params = {
            value: JSON.stringify(obj)
          };

          let that = this;
          try {
            if (!valid) {
              throw { message: "参数值为空", value: [] };
            }
            if (obj.period_days <= 0 || obj.period_days > 30) {
              throw {
                message: "风控周期值在{0}-{1}天之间",
                value: [1, 30]
              };
            }
            if (obj.ptrpr < 0 || obj.ptrpr > 0.5) {
              throw {
                message: "平台期望收益率值在{0}%-{1}%之间",
                value: [0, 50]
              };
            }
            if (obj.ptspr < -0.1 || obj.ptspr > 0.1) {
              throw {
                message: "平台保底收益率值在{0}%-{1}%之间",
                value: [-10, 10]
              };
            }
            if (obj.pta1 < 0 || obj.pta1 > 100) {
              throw {
                message: "平台风控参数a1值在{0}-{1}之间",
                value: [0, 100]
              };
            }
            if (obj.pta2 < 0 || obj.pta2 > 5) {
              throw {
                message: "平台风控参数a2值在{0}-{1}之间",
                value: [0, 5]
              };
            }
            if (obj.ptb1 < 0 || obj.ptb1 > 100) {
              throw {
                message: "平台风控参数b1值在{0}-{1}之间",
                value: [0, 100]
              };
            }
            if (obj.ptb2 < 0 || obj.ptb2 > 5) {
              throw {
                message: "平台风控参数b2值在{0}-{1}之间",
                value: [0, 5]
              };
            }
            if (obj.pt_start_round < 0 || obj.pt_start_round > 1000) {
              throw {
                message: "平台风控启动有效局数值在{0}-{1}之间",
                value: [0, 1000]
              };
            }
            if (obj.pt_start_bill < 0 || obj.pt_start_bill > 100000000) {
              throw {
                message: "平台风控启动有效流水值在{0}-{1}之间",
                value: [0, 100000000]
              };
            }
            if (obj.wjrpr < -0.5 || obj.wjrpr > 0) {
              throw {
                message: "玩家期望收益率值在{0}%-{1}%之间",
                value: [-50, 0]
              };
            }
            if (obj.wja1 < 0 || obj.wja1 > 100) {
              throw {
                message: "玩家风控参数a1值在{0}-{1}之间",
                value: [0, 100]
              };
            }
            if (obj.wja2 < 0 || obj.wja2 > 5) {
              throw {
                message: "玩家风控参数a2值在{0}-{1}之间",
                value: [0, 5]
              };
            }
            if (obj.wjb1 < 0 || obj.wjb1 > 100) {
              throw {
                message: "玩家风控参数b1值在{0}-{1}之间",
                value: [0, 100]
              };
            }
            if (obj.wjb2 < 0 || obj.wjb2 > 5) {
              throw {
                message: "玩家风控参数b2值在{0}-{1}之间",
                value: [0, 5]
              };
            }
            if (obj.wj_start_round < 0 || obj.wj_start_round > 100) {
              throw {
                message: "玩家风控启动有效局数值在{0}-{1}之间",
                value: [0, 100]
              };
            }
            if (obj.wj_start_bill < 0 || obj.wj_start_bill > 10000000) {
              throw {
                message: "玩家风控启动有效流水值在{0}-{1}之间",
                value: [0, 10000000]
              };
            }
            if (obj.lba1 < 0 || obj.lba1 > 1) {
              throw {
                message: "连败风控参数a1值在{0}-{1}之间",
                value: [0, 1]
              };
            }
            if (obj.lba2 < 0 || obj.lba2 > 5) {
              throw {
                message: "连败风控参数a2值在{0}-{1}之间",
                value: [0, 5]
              };
            }
            if (obj.lb_start_round < 1 || obj.lb_start_round > 5) {
              throw {
                message: "连败风控启动有效局数值在{0}-{1}之间",
                value: [1, 5]
              };
            }
            if (obj.ptw < 0 || obj.ptw > 10000) {
              throw {
                message: "平台权重值在{0}-{1}之间",
                value: [0, 10000]
              };
            }
            if (obj.yxw < 0 || obj.yxw > 10000) {
              throw {
                message: "游戏房间权重值在{0}-{1}之间",
                value: [0, 10000]
              };
            }
            if (obj.wjw < 0 || obj.wjw > 10000) {
              throw {
                message: "玩家权重值在{0}-{1}之间",
                value: [0, 10000]
              };
            }
            if (obj.lbw < 0 || obj.lbw > 10000) {
              throw {
                message: "连败权重值在{0}-{1}之间",
                value: [0, 10000]
              };
            }

            if (obj.dlrpr < 0 || obj.dlrpr > 0.5) {
              throw {
                message: "代理期望盈利率值在{0}-{1}之间",
                value: [0, 50]
              };
            }
            if (obj.dla1 < 0 || obj.dla1 > 100) {
              throw {
                message: "代理风控参数a1值在{0}-{1}之间",
                value: [0, 100]
              };
            }
            if (obj.dla2 < 0 || obj.dla2 > 5) {
              throw {
                message: "代理风控参数a2值在{0}-{1}之间",
                value: [0, 5]
              };
            }
            if (obj.dlb1 < 0 || obj.dlb1 > 100) {
              throw {
                message: "代理风控参数b1值在{0}-{1}之间",
                value: [0, 100]
              };
            }

            if (obj.dlb2 < 0 || obj.dlb2 > 5) {
              throw {
                message: "代理风控参数b2值在{0}-{1}之间",
                value: [0, 5]
              };
            }
            if (obj.dl_start_round  < 0 || obj.dl_start_round  > 100) {
              throw {
                message: "代理风控启动局数值在{0}-{1}之间",
                value: [0, 100]
              };
            }
            if (obj.dl_start_bill < 0 || obj.dl_start_bill > 10000000) {
              throw {
                message: "代理风控启动流水值在{0}-{1}之间",
                value: [0, 10000000]
              };
            }
            if (obj.dlw < 0 || obj.dlw > 10000) {
              throw {
                message: "代理风控权重值在{0}-{1}之间",
                value: [0, 10000]
              };
            }

            this.$Http({
              url: "/api/variables/saveConfig",
              params,
              successful(res, data) {
                if (data.code != 200) {
                  that.$message.error(data.message);
                  that.getconfigForm();
                } else {
                  that.$message.success(data.data.d.msg);
                  that.getconfigForm();
                }
              }
            });
          } catch (e) {
            that.$message.error(e.message.format(e.value));
          }
        });
      },
      // 取消按钮
      resetForm() {
        this.$refs.configFormRef.resetFields();
      }
    }
  };
</script>

<style lang="less" scoped>
  #configuration {

    height: 100%;
    overflow-y: auto;

    .el-card__body {
      padding: 0px !important;
    }
    .title {
      /*margin: 0 0 30px 0;*/
      /*border-bottom: 1px solid;*/
      padding-bottom: 20px;
    }
    /deep/.el-form-item__label {
      font-weight: 800 !important;
    }
    .el-row--flex {
      border-bottom: 1px solid;
      padding-top: 13px;
    }
    /deep/.el-input__inner {
      border: 1px solid #000000;
    }
    /deep/ .el-input__inner:hover {
      border-color: blueviolet;
    }
    /deep/ .el-input__inner:focus {
      border-color: blueviolet;
    }
    .el-col-12 {
      height: 50px;
    }
    /deep/ .el-form-item__error {
      color: red;
      font-size: 12px;
      font-weight: 600;
      line-height: 1;
      padding-top: 4px;
      position: absolute;
      top: 25% !important;
      right: -71px !important;
      left: initial;
    }
    /deep/ .el-input-group__append {
      position: absolute !important;
      background-color: unset;
      color: unset;
      vertical-align: middle;
      display: table-cell;
      position: relative;
      border: 0;
      right: 0;
      bottom: 25%;
      border-radius: 4px;
      padding: 0 20px;
      width: 1px;
      white-space: nowrap;
    }
  }
</style>